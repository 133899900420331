import React from 'react';

import { Grid, Typography } from '@mui/material';
import LinkButton from 'src/components/LinkButton';

import { Container } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        height="inherit"
        alignItems="center"
        justifyContent="center"
        direction="column"
        gap={2}
      >
        <Typography>Oportunidade não encontrada.</Typography>

        <LinkButton
          to="/atendimento/oportunidades"
          variant="outlined"
          color="info"
        >
          Voltar
        </LinkButton>
      </Grid>
    </Container>
  );
};

export default NotFound;
