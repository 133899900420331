import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { PropertyMedia } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getPropertyFiles(
  propertyId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<PropertyMedia>>(
    `/property/admin/properties/${propertyId}/files`,
    config,
  );

  return response;
}

function usePropertyFilesQuery(
  propertyId: string | number,
  data?: IApiUseQueryData<PropertyMedia>,
) {
  return useQuery<IApiUseQueryFnData<PropertyMedia>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_FILES,
      propertyId,
      data?.requestConfig?.params,
    ],
    () => getPropertyFiles(propertyId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePropertyFilesQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PROPERTY_FILES]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_FILES]);
}

async function storePropertyFile(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<PropertyMedia>(
    `/property/admin/properties/${propertyId}/files`,
    data,
    config,
  );

  invalidatePropertyFilesQueries();

  return response;
}

async function getPropertyFile(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PropertyMedia>(
    `/property/admin/properties/${propertyId}/files/${id}`,
    config,
  );

  return response;
}

async function updatePropertyFile(
  propertyId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PropertyMedia>(
    `/property/admin/properties/${propertyId}/files/${id}`,
    data,
    config,
  );

  invalidatePropertyFilesQueries();

  return response;
}

async function destroyPropertyFile(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${propertyId}/files/${id}`,
    config,
  );

  invalidatePropertyFilesQueries();

  return response;
}

async function restorePropertyFile(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/properties/${propertyId}/files/${id}/restore`,
    config,
  );

  invalidatePropertyFilesQueries();

  return response;
}

export const files = {
  getPropertyFiles,
  usePropertyFilesQuery,
  storePropertyFile,
  getPropertyFile,
  updatePropertyFile,
  destroyPropertyFile,
  restorePropertyFile,
};
