import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { EconomicIndicatorValue } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getValues(
  indicatorId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<
    IResponseAPIFilter<EconomicIndicatorValue>
  >(`/property/admin/economic-indicators/${indicatorId}/values`, config);

  return response;
}

function useValuesQuery(
  indicatorId: string | number,
  data?: IApiUseQueryData<EconomicIndicatorValue>,
) {
  return useQuery<IApiUseQueryFnData<EconomicIndicatorValue>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.ECONOMIC_INDICATORS,
      'values',
      indicatorId,
      data?.requestConfig?.params,
    ],
    () => getValues(indicatorId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateValuesQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.ECONOMIC_INDICATORS,
  ]);
  queryClient.invalidateQueries([
    PROPERTY_ADMIN_QUERY_KEYS.ECONOMIC_INDICATORS,
  ]);
}

async function storeValue(
  indicatorId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<EconomicIndicatorValue>(
    `/property/admin/economic-indicators/${indicatorId}/values`,
    data,
    config,
  );

  invalidateValuesQueries();

  return response;
}

async function getValue(
  indicatorId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<EconomicIndicatorValue>(
    `/property/admin/economic-indicators/${indicatorId}/values/${id}`,
    config,
  );

  return response;
}

async function updateValue(
  indicatorId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<EconomicIndicatorValue>(
    `/property/admin/economic-indicators/${indicatorId}/values/${id}`,
    data,
    config,
  );

  invalidateValuesQueries();

  return response;
}

async function destroyValue(
  indicatorId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/economic-indicators/${indicatorId}/values/${id}`,
    config,
  );

  invalidateValuesQueries();

  return response;
}

async function restoreValue(
  indicatorId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/economic-indicators/${indicatorId}/values/${id}/restore`,
    config,
  );

  invalidateValuesQueries();

  return response;
}

export const values = {
  getValues,
  useValuesQuery,
  storeValue,
  getValue,
  updateValue,
  destroyValue,
  restoreValue,
};
