import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Can from 'src/components/Can';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import AutocompleteFunnelAttendants from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelAttendants';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';

const isWaitingItems = [
  { key: 1, label: 'Sim', value: 'true' },
  { key: 2, label: 'Não', value: 'false' },
];

const statusItems = [
  { key: 1, label: 'Aberto', value: 'open' },
  { key: 2, label: 'Ganho', value: 'gain' },
  { key: 3, label: 'Perdido', value: 'lost' },
];

type OpportunityFiltersProps = {
  funnelId: number;
};

const OpportunityFilters: FC<OpportunityFiltersProps> = ({ funnelId }) => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="client_search"
          label="Lead"
          size="small"
          placeholder="Nome / E-mail / Telefones"
        />
      </Grid>

      <Can
        permissions={[ATTENDANCE_PERMISSIONS.OPPORTUNITIES.LIST_ALL]}
        behavior="hide"
      >
        <Grid item xs={12}>
          <AutocompleteFunnelAttendants
            name="attendants"
            funnelId={funnelId}
            requestConfig={{
              params: { user_has_access: true },
            }}
            label="Atendentes (Responsável / Extras)"
            multiple
            size="small"
          />
        </Grid>
      </Can>

      <Grid item xs={12}>
        <TextField
          name="attendances_description"
          label="Descrição de Atendimento"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="is_waiting"
          label="Em Espera"
          options={isWaitingItems}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
        />
      </Grid>
    </>
  );
};

export default OpportunityFilters;
