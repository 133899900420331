import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { CondominiumDetail } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getCondominiumDetails(
  condominiumId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<CondominiumDetail>>(
    `/property/admin/condominiums/${condominiumId}/details`,
    config,
  );

  return response;
}

function useCondominiumDetailsQuery(
  condominiumId: string | number,
  data?: IApiUseQueryData<CondominiumDetail>,
) {
  return useQuery<IApiUseQueryFnData<CondominiumDetail>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.CONDOMINIUM_DETAILS,
      condominiumId,
      data?.requestConfig?.params,
    ],
    () => getCondominiumDetails(condominiumId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateCondominiumDetailsQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.CONDOMINIUM_DETAILS,
  ]);
  queryClient.invalidateQueries([
    PROPERTY_ADMIN_QUERY_KEYS.CONDOMINIUM_DETAILS,
  ]);
}

async function storeCondominiumDetail(
  condominiumId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<CondominiumDetail>(
    `/property/admin/condominiums/${condominiumId}/details`,
    data,
    config,
  );

  invalidateCondominiumDetailsQueries();

  return response;
}

async function getCondominiumDetail(
  condominiumId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<CondominiumDetail>(
    `/property/admin/condominiums/${condominiumId}/details/${id}`,
    config,
  );

  return response;
}

async function updateCondominiumDetail(
  condominiumId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<CondominiumDetail>(
    `/property/admin/condominiums/${condominiumId}/details/${id}`,
    data,
    config,
  );

  invalidateCondominiumDetailsQueries();

  return response;
}

async function destroyCondominiumDetail(
  condominiumId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/condominiums/${condominiumId}/details/${id}`,
    config,
  );

  invalidateCondominiumDetailsQueries();

  return response;
}

async function restoreCondominiumDetail(
  condominiumId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/condominiums/${condominiumId}/details/${id}/restore`,
    config,
  );

  invalidateCondominiumDetailsQueries();

  return response;
}

export const details = {
  getCondominiumDetails,
  useCondominiumDetailsQuery,
  storeCondominiumDetail,
  getCondominiumDetail,
  updateCondominiumDetail,
  destroyCondominiumDetail,
  restoreCondominiumDetail,
};
