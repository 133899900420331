import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import { KEY_STATUSES_OPTIONS } from 'src/modules/property/constants';

const Filters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="description" label="Descrição" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={KEY_STATUSES_OPTIONS}
          size="small"
        />
      </Grid>
    </>
  );
};

export default Filters;
