import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useField } from '@unform/core';

import { InputFileProps } from './interfaces';
import Preview from './Preview';
import { Container, Input, InputLabel, NoImage } from './styles';

const InputFile: FC<InputFileProps> = ({
  name,
  onChange,
  label,
  description,
  canDelete,
  deleteLabel = 'Excluir Arquivo',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    registerField,
    error,
    defaultValue,
    clearError,
  } = useField(name);
  const [defaultPreview, setDefaultPreview] = useState(defaultValue);
  const [preview, setPreview] = useState(defaultValue);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    registerField<any>({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        if (isDeleting) return null;
        return ref.files?.length ? ref.files[0] : undefined;
      },
      setValue: (_: HTMLInputElement, value) => {
        if (!preview) {
          setDefaultPreview(value);
        }
        setPreview(value);
        clearError();
      },
      clearValue: (ref: HTMLInputElement, newValue) => {
        ref.value = newValue || '';
        setPreview(newValue || null);
        clearError();
      },
    });
  }, [fieldName, registerField, clearError, preview, isDeleting]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (file) {
        setPreview(file);
      } else {
        setPreview(defaultPreview || null);
      }

      setIsDeleting(false);
      clearError();

      if (onChange) onChange(e);
    },
    [onChange, defaultPreview, clearError],
  );

  function handleDelete() {
    if (inputRef.current) inputRef.current.value = '';
    setIsDeleting(true);
  }

  return (
    <Container>
      {preview && !isDeleting ? (
        <Preview src={preview} />
      ) : (
        <NoImage variant="outlined">
          <ImageSearchIcon color="action" />
        </NoImage>
      )}

      {description && <Typography variant="caption">{description}</Typography>}

      <Input
        id={`input-file-${fieldName}`}
        type="file"
        ref={inputRef}
        onChange={handleChange}
        {...rest}
      />

      <InputLabel htmlFor={`input-file-${fieldName}`}>
        <Button variant="contained" color="primary" component="span" fullWidth>
          {label || 'Selecionar'}
        </Button>
      </InputLabel>

      {canDelete && preview && !isDeleting && (
        <Button
          startIcon={<DeleteIcon />}
          variant="text"
          color="error"
          fullWidth
          onClick={handleDelete}
        >
          {deleteLabel}
        </Button>
      )}

      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </Container>
  );
};

export default InputFile;
