import React, { ChangeEvent } from 'react';
import { toast } from 'react-toastify';

import { Grid, Typography } from '@mui/material';
import IMedia from 'src/interfaces/models/IMedia';
import SourceExternalLink from 'src/modules/property/components/Form/InputPropertyMedias/SourceExternalLink';
import SourceLocal from 'src/modules/property/components/Form/InputPropertyMedias/SourceLocal';
import apiAdminPublic from 'src/services/api/adminPublic';
import { validateMediaFiles } from 'src/utils/medias';
import * as uuid from 'uuid';

export type InputPropertyMediasField = {
  id: string | number;
  file?: File;
  filePreview?: string;
  name?: string;
  value?: string;
  show_in_website?: boolean;
};

type InputPropertyMediasProps = {
  type: IMedia['type'];
  name: string;
  fields: InputPropertyMediasField[];
  setFields: React.Dispatch<React.SetStateAction<InputPropertyMediasField[]>>;
  multiple?: boolean;
};

const InputPropertyMedias: React.FC<InputPropertyMediasProps> = ({
  type,
  name,
  fields,
  setFields,
  multiple = false,
}) => {
  const { data: mediasResponse, isLoading } = apiAdminPublic.useMediasQuery({
    requestConfig: { params: { type: type } },
  });
  const media = mediasResponse?.data[0];

  function handleSelectFiles(e: ChangeEvent<HTMLInputElement>) {
    if (!media) return toast.error('Parâmetros da mídia não carregados!');

    const selectedFiles = Array.from(e.target.files || []);

    const { validFiles, invalidFiles } = validateMediaFiles(
      media,
      selectedFiles,
    );

    if (invalidFiles.length) {
      toast.warn(
        `${invalidFiles.length} arquivo(s) não respeitam o tamanho e/ou a extensão.`,
      );
    }

    setFields((state) => {
      const newFiles = validFiles.filter(
        (validFile) => !state.find((s) => s.file?.name == validFile.name),
      );

      const newFields: InputPropertyMediasField[] = newFiles.map((file) => ({
        id: uuid.v4(),
        file,
        filePreview: URL.createObjectURL(file),
        name: file.name,
        show_in_website: false,
      }));

      return [...state, ...newFields];
    });
  }

  function handleAddField() {
    setFields((state) => [
      ...state,
      {
        id: uuid.v4(),
        name: '',
        value: '',
        show_in_website: false,
      },
    ]);
  }

  function removeField(field: InputPropertyMediasField) {
    if (field.filePreview) URL.revokeObjectURL(field.filePreview);

    setFields((state) => state.filter((s) => s.id !== field.id));
  }

  if (isLoading) return null;

  if (!media)
    return (
      <Typography color="red">Parâmetros da mídia não encontrados.</Typography>
    );

  return (
    <Grid container spacing={2}>
      {media.source === 'local' ? (
        <SourceLocal
          name={name}
          media={media}
          onSelectFiles={handleSelectFiles}
          fields={fields}
          removeFile={removeField}
          multiple={multiple}
        />
      ) : (
        <SourceExternalLink
          name={name}
          onAdd={handleAddField}
          fields={fields}
          removeField={removeField}
        />
      )}
    </Grid>
  );
};

export default InputPropertyMedias;
