import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { PropertyPeculiarity } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getPropertyPeculiarities(
  propertyId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<PropertyPeculiarity>>(
    `/property/admin/properties/${propertyId}/peculiarities`,
    config,
  );

  return response;
}

function usePropertyPeculiaritiesQuery(
  propertyId: string | number,
  data?: IApiUseQueryData<PropertyPeculiarity>,
) {
  return useQuery<IApiUseQueryFnData<PropertyPeculiarity>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_PECULIARITIES,
      propertyId,
      data?.requestConfig?.params,
    ],
    () => getPropertyPeculiarities(propertyId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePropertyPeculiaritiesQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.PROPERTY_PECULIARITIES,
  ]);
  queryClient.invalidateQueries([
    PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_PECULIARITIES,
  ]);
}

async function storePropertyPeculiarity(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<PropertyPeculiarity>(
    `/property/admin/properties/${propertyId}/peculiarities`,
    data,
    config,
  );

  invalidatePropertyPeculiaritiesQueries();

  return response;
}

async function getPropertyPeculiarity(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PropertyPeculiarity>(
    `/property/admin/properties/${propertyId}/peculiarities/${id}`,
    config,
  );

  return response;
}

async function updatePropertyPeculiarity(
  propertyId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PropertyPeculiarity>(
    `/property/admin/properties/${propertyId}/peculiarities/${id}`,
    data,
    config,
  );

  invalidatePropertyPeculiaritiesQueries();

  return response;
}

async function destroyPropertyPeculiarity(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${propertyId}/peculiarities/${id}`,
    config,
  );

  invalidatePropertyPeculiaritiesQueries();

  return response;
}

async function restorePropertyPeculiarity(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/properties/${propertyId}/peculiarities/${id}/restore`,
    config,
  );

  invalidatePropertyPeculiaritiesQueries();

  return response;
}

export const peculiarities = {
  getPropertyPeculiarities,
  usePropertyPeculiaritiesQuery,
  storePropertyPeculiarity,
  getPropertyPeculiarity,
  updatePropertyPeculiarity,
  destroyPropertyPeculiarity,
  restorePropertyPeculiarity,
};
