import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BackButton from 'src/components/BackButton';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import propertyApi from 'src/modules/property/services/api';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors } from 'src/utils/errors';

import { IdentityUpdateContext, IdentityUpdateContextData } from './context';
import IdentityTab from './IdentityTab';
import OwnersTab from './OwnersTab';
import PropertiesTab from './PropertiesTab';
import { Container } from './styles';

const TypeUniqueTabs: NavigationTab[] = [
  {
    name: 'Proprietários',
    slug: 'proprietarios',
    Component: OwnersTab,
  },
];

const Update: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const [context, setContext] = useState<IdentityUpdateContextData>({});
  const params = useParams<ITabEditParams>();

  const IdentityTabs: NavigationTab[] = [
    { name: 'Identidade', slug: '', Component: IdentityTab },
    {
      name: 'Imóveis',
      slug: 'imoveis',
      Component: PropertiesTab,
    },
  ];

  if (context.identity?.type === 'unique') {
    IdentityTabs.splice(1, 0, ...TypeUniqueTabs);
  }

  function updateContext(data: IdentityUpdateContextData) {
    setContext((state) => ({
      ...state,
      ...data,
    }));
  }

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      const { data: identity } = await propertyApi.admin.identities.getIdentity(
        params.id,
      );

      updateContext({ identity });
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <IdentityUpdateContext.Provider
      value={{
        ...context,
        updateContext,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                Editar {context.identity?.full_address}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <NavigationTabs
              baseUrl={`/imovel/identidades${params.id ? `/${params.id}` : ''}`}
              tabs={IdentityTabs}
            />
          </Grid>

          <Grid item xs={12}>
            {IdentityTabs.map(({ name, slug, Component }) => (
              <TabPanel key={name} show={slug === (params.selectedTab || '')}>
                <Component />
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </Container>
    </IdentityUpdateContext.Provider>
  );
};

export default Update;
