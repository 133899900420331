import { useRef, useEffect, FC, useCallback, useState } from 'react';

import MuiCheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from '@unform/core';

import { CheckboxType, OnChangeType } from './interfaces';

const CheckBox: FC<CheckboxType> = ({
  name,
  label,
  onChange,
  labelProps,
  returnAs = 'boolean',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, setChecked] = useState(false);

  const {
    fieldName,
    registerField,
    error,
    clearError,
    defaultValue,
  } = useField(name);

  useEffect(() => {
    function setValueByType(value) {
      switch (typeof value) {
        case 'string':
          setChecked(value === 'true');
          break;
        case 'number':
          setChecked(value === 1);
          break;
        default:
          setChecked(!!value);
          break;
      }
    }

    registerField<boolean>({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (returnAs === 'string') return ref.current.checked.toString();
        if (returnAs === 'number') return ref.current.checked ? 1 : 0;
        return ref.current.checked;
      },
      setValue: (_, value) => {
        setValueByType(value);
      },
      clearValue: (_, newValue) => {
        setValueByType(newValue);
      },
    });
  }, [fieldName, registerField, returnAs]);

  useEffect(() => {
    setChecked(!!defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.checked = checked;
    }
  }, [checked]);

  const handleChange: OnChangeType = useCallback(
    (e, checked) => {
      setChecked(checked);

      if (onChange) onChange(e, checked);

      clearError();
    },
    [onChange, clearError],
  );

  return (
    <FormControl error={!!error}>
      <FormControlLabel
        {...labelProps}
        control={
          <MuiCheckbox
            inputRef={inputRef}
            name={fieldName}
            color="primary"
            onChange={handleChange}
            checked={checked}
            {...rest}
          />
        }
        label={label}
      />
      <FormHelperText hidden={!error}>{error}</FormHelperText>
    </FormControl>
  );
};

export default CheckBox;
