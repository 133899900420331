import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import EditButton from 'src/components/Table/EditButton';
import { INPUT_TYPE_LABEL } from 'src/constants';
import usePagination from 'src/hooks/usePagination';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import { IClientContact } from 'src/interfaces/models/IClient';
import { DetailProperty } from 'src/modules/property/@types/models';
import PropertyTypeDetailFilterSchema from 'src/validators/PropertyTypeDetail/filter.schema';

import Filters from './Filters';

const List: FC = () => {
  const params = useParams<ITabEditParams>();
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
  } = usePagination<IClientContact>(
    `admin/property-types/${params.id}/details`,
  );

  const columns: GridColDef<DetailProperty>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'input_type',
      headerName: 'Tipo do Valor',
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      valueGetter({ value }) {
        return INPUT_TYPE_LABEL[value];
      },
    },
    {
      field: 'is_required',
      headerName: 'Obrigatório',
      headerAlign: 'center',
      align: 'center',
      valueGetter({ value }) {
        return value ? 'Sim' : 'Não';
      },
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell(params) {
        return (
          <Can permissions={['update-property-types']}>
            <EditButton to={`${url}/${params.id}`} />
          </Can>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Detalhes</Typography>

            <Can permissions={['store-property-types']}>
              <LinkButton
                to={`${url}/novo`}
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                showFilters
                filterProps={{
                  yupSchema: PropertyTypeDetailFilterSchema,
                  children: <Filters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default List;
