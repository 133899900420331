import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const RoleFilters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          name="name"
          label="Nome"
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="group"
          label="Grupo"
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select
          name="status"
          label="Status"
          items={statusItems}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
    </>
  );
};

export default RoleFilters;
