import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Reason } from 'src/modules/attendance/@types/models';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import {
  ReasonStoreSchema,
  ReasonUpdateSchema,
} from 'src/modules/attendance/validators/Reason/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [reasonStatus, setReasonStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const reasonSchema = params.id ? ReasonUpdateSchema : ReasonStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data: reason } = await attendanceApi.admin.reasons.getReason(
          params.id,
        );
        setReasonStatus(reason.status);

        setTimeout(() => {
          formRef.current?.setData(reason);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<Reason> = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        reasonSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newReason: Reason;
      if (params.id) {
        const response = await attendanceApi.admin.reasons.updateReason(
          params.id,
          data,
        );
        newReason = response.data;
      } else {
        const response = await attendanceApi.admin.reasons.storeReason(data);
        newReason = response.data;
      }

      toast.success('Motivo salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/atendimento/motivos/${newReason.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', reasonSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.reasons.destroyReason(params.id);

      toast.success('Motivo desativado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Motivo');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.reasons.restoreReason(params.id);

      toast.success('Motivo restaurado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Motivo.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [ATTENDANCE_PERMISSIONS.REASONS.STORE],
          update: [ATTENDANCE_PERMISSIONS.REASONS.UPDATE],
          destroy: [ATTENDANCE_PERMISSIONS.REASONS.DESTROY],
          restore: [ATTENDANCE_PERMISSIONS.REASONS.RESTORE],
        }}
        resourceName="Motivo"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={reasonStatus === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Motivo
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
