import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { Realtor } from 'src/modules/property/@types/models';
import { REALTOR_TYPES_LABEL } from 'src/modules/property/constants';
import propertyApi from 'src/modules/property/services/api';

type AutocompleteRealtorsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<Realtor>, 'options'>;

const AutocompleteRealtors: React.FC<AutocompleteRealtorsProps> = ({
  requestConfig,
  ...rest
}) => {
  const {
    isLoading,
    data: response,
  } = propertyApi.admin.common.useRealtorsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((realtor) => ({
      key: realtor.id,
      label: `${realtor.name} (${REALTOR_TYPES_LABEL[realtor.type]})`,
      value: realtor.id,
      data: realtor,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteRealtors;
