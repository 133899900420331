import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Dialog,
  DialogProps,
  DialogTitle,
  Step,
  StepButton,
  Stepper,
} from '@mui/material';
import {
  Opportunity,
  OpportunityAttendance,
} from 'src/modules/attendance/@types/models';
import AttendanceStep from 'src/modules/attendance/components/StoreOpportunityAttendanceDialog/AttendanceStep';
import InterestStep from 'src/modules/attendance/components/StoreOpportunityAttendanceDialog/InterestStep';
import attendanceApi from 'src/modules/attendance/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

import {
  AttendanceData,
  ContextData,
  StoreOpportunityAttendanceDialogContext,
} from './context';

export type StoreOpportunityAttendanceDialogProps = {
  opportunity: Opportunity;
  funnelId: number;
  onClose: () => void;
  onSuccess?: (attendance: OpportunityAttendance) => void;
} & DialogProps;

const STEPS = [
  { label: 'Interesses', Component: InterestStep },
  { label: 'Atendimento', Component: AttendanceStep },
];

const StoreOpportunityAttendanceDialog: React.FC<StoreOpportunityAttendanceDialogProps> = ({
  opportunity,
  onSuccess,
  onClose,
  funnelId,
  ...rest
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ContextData>({
    interests: [],
    attendance: {
      lead_name: opportunity.client?.username,
      amount: opportunity.amount.toString(),
      funnel_step_id: opportunity.funnel_step_id,
    },
  });

  function renderActiveStep() {
    const { Component } = STEPS[activeStep];

    return <Component />;
  }

  const reset = useCallback(() => {
    setActiveStep(0);
    setData({
      interests: [],
      attendance: {
        lead_name: opportunity.client?.username,
        amount: opportunity.amount.toString(),
        funnel_step_id: opportunity.funnel_step_id,
      },
    });
  }, [opportunity]);

  async function submitAttendance(formData: AttendanceData) {
    try {
      setLoading(true);

      const response = await attendanceApi.admin.funnels.opportunities.storeFunnelOpportunityAttendance(
        funnelId,
        opportunity.id,
        {
          ...formData,
          interests: data.interests,
        },
      );

      toast.success('Atendimento salvo com sucesso!');

      if (onSuccess) onSuccess(response.data);
      reset();
      onClose();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao cadastrar atendimento.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <StoreOpportunityAttendanceDialogContext.Provider
      value={{
        funnelId,
        activeStep,
        setActiveStep,
        loading,
        setLoading,
        data,
        setData,
        onClose,
        submitAttendance,
      }}
    >
      <Dialog fullWidth maxWidth="lg" {...rest}>
        <DialogTitle align="center">
          Novo Atendimento
          <Stepper activeStep={activeStep}>
            {STEPS.map((step) => (
              <Step key={step.label}>
                <StepButton color="inherit">{step.label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </DialogTitle>

        {renderActiveStep()}
      </Dialog>
    </StoreOpportunityAttendanceDialogContext.Provider>
  );
};

export default StoreOpportunityAttendanceDialog;
