import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Chip, Paper, Tooltip } from '@mui/material';
import { CHAT_STATUS_LABEL } from 'src/constants';
import { Opportunity } from 'src/modules/attendance/@types/models';
import ChatContent from 'src/pages/Private/Omnichannel/Chat/ChatContent';
import OmnichannelContext, {
  Upload,
} from 'src/pages/Private/Omnichannel/context';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import {
  Chat,
  ChatMessage,
} from 'src/services/websocket/omnichannel/interfaces';
import { formatDate } from 'src/utils/helpers';

type OpportunityChatProps = {
  opportunity: Opportunity;
  chats: Chat[];
};

const OpportunityChat: React.FC<OpportunityChatProps> = ({
  opportunity,
  chats,
}) => {
  const [selectedChat, setSelectedChat] = useState<Chat>();
  const [selectedChatMessages, setSelectedChatMessages] = useState<
    ChatMessage[]
  >([]);
  const [answerMessage, setAnswerMessage] = useState<ChatMessage>();
  const [upload, setUpload] = useState<Upload | null>(null);
  const [omnichannelConnected, setOmnichannelConnected] = useState(false);

  function getChipLabel(chat: Chat) {
    const statusLabel = CHAT_STATUS_LABEL[chat.status];
    if (chat.status === 'active') return statusLabel;

    return `${statusLabel} ${formatDate(chat.updated_at || '')}`;
  }

  useEffect(() => {
    if (!opportunity.step?.funnel?.department_id) return;
    WSOmnichannel.connect(opportunity.step?.funnel?.department_id);

    WSOmnichannel.socket?.on('connect', () => {
      setOmnichannelConnected(true);
    });

    WSOmnichannel.socket?.on('disconnect', () => {
      setOmnichannelConnected(false);
    });

    WSOmnichannel.socket?.on('connect_error', () => {
      toast.error('Não foi possível conectar ao WebSocket do Chat');
    });

    return () => {
      WSOmnichannel.disconnect();
    };
  }, [opportunity]);

  useEffect(() => {
    if (selectedChat) return;

    const activeChat = chats.find((chat) => chat.status === 'active');
    if (activeChat) {
      setSelectedChat(activeChat);
    } else if (chats.length > 0) {
      setSelectedChat(chats[0]);
    }
  }, [selectedChat, chats]);

  if (!omnichannelConnected || !opportunity.step?.funnel?.department)
    return null;

  return (
    <OmnichannelContext.Provider
      value={{
        department: opportunity.step.funnel.department,
        setDepartment: () => null,
        chats: [],
        selectedChat,
        setSelectedChat,
        selectedChatMessages,
        setSelectedChatMessages,
        answerMessage,
        setAnswerMessage,
        upload,
        setUpload,
        adminView: false,
        setAdminView: () => null,
      }}
    >
      <Paper
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box sx={{ p: 0.5 }}>
          {chats.map((chat) => (
            <Tooltip key={chat._id} title={chat.client?.phone}>
              <Chip
                label={getChipLabel(chat)}
                variant={selectedChat?._id === chat._id ? 'filled' : 'outlined'}
                icon={<WhatsAppIcon fontSize="inherit" />}
                onClick={() => setSelectedChat(chat)}
              />
            </Tooltip>
          ))}
        </Box>

        {selectedChat && (
          <Box sx={{ flexGrow: 1, display: 'flex', overflowY: 'auto' }}>
            <ChatContent hideBack />
          </Box>
        )}
      </Paper>
    </OmnichannelContext.Provider>
  );
};

export default OpportunityChat;
