import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MuiIconButton from '@mui/material/IconButton';

import { LinkIconButtonProps } from './interfaces';

const LinkIconButton: FC<LinkIconButtonProps> = ({ children, ...rest }) => {
  return (
    <MuiIconButton component={rest.to ? RouterLink : undefined} {...rest}>
      {children}
    </MuiIconButton>
  );
};

export default LinkIconButton;
