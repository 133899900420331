import yup from 'src/libs/yup';

export const AddressStoreSchema = yup.object().shape({
  zip_code: yup.string().required().label('CEP'),
  city_id: yup.string().required().label('Cidade'),
  neighborhood_id: yup.string().required().label('Bairro'),
  street_id: yup.string().required().label('Logradouro'),
  latitude: yup.string().label('Latitude'),
  longitude: yup.string().label('Longitude'),
});

export const AddressUpdateSchema = yup.object().shape({
  zip_code: yup.string().required().label('CEP'),
  city_id: yup.string().required().label('Cidade'),
  neighborhood_id: yup.string().required().label('Bairro'),
  street_id: yup.string().required().label('Logradouro'),
  latitude: yup.string().label('Latitude'),
  longitude: yup.string().label('Longitude'),
});
