import yup from 'src/libs/yup';

export default yup.object().shape({
  password: yup.string().required().label('Senha'),
  password_confirmation: yup
    .string()
    .equalTo(yup.ref('password'), 'senha')
    .required()
    .label('Confirmação'),
});
