import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { PropertyPeculiarity } from 'src/modules/property/@types/models';
import { SaveIdentityPropertyPeculiarityParams } from 'src/modules/property/@types/params';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  PropertyPeculiarityStoreSchema,
  PropertyPeculiarityUpdateSchema,
} from 'src/modules/property/validators/PropertyPeculiarity/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [peculiarityStatus, setPeculiarityStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<SaveIdentityPropertyPeculiarityParams>();
  const { url } = useRouteMatch();

  const peculiaritySchema = params.peculiarityId
    ? PropertyPeculiarityUpdateSchema
    : PropertyPeculiarityStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.peculiarityId) {
        const {
          data: peculiarity,
        } = await propertyApi.admin.properties.peculiarities.getPropertyPeculiarity(
          params.propertyId,
          params.peculiarityId,
        );
        setPeculiarityStatus(peculiarity.status);

        setTimeout(() => {
          formRef.current?.setData(peculiarity);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [
    params.propertyId,
    params.peculiarityId,
    startLayoutLoading,
    stopLayoutLoading,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<PropertyPeculiarity> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        peculiaritySchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newPeculiarity: PropertyPeculiarity;
      if (params.peculiarityId) {
        const response = await propertyApi.admin.properties.peculiarities.updatePropertyPeculiarity(
          params.propertyId,
          params.peculiarityId,
          data,
        );
        newPeculiarity = response.data;
      } else {
        const response = await propertyApi.admin.properties.peculiarities.storePropertyPeculiarity(
          params.propertyId,
          data,
        );
        newPeculiarity = response.data;
      }

      toast.success('Peculiaridade salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.peculiarityId) {
        history.replace(`${url}/${newPeculiarity.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        peculiaritySchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async (isDeleting) => {
    try {
      setLoading(true);

      if (params.peculiarityId) {
        let deleteConfig: AxiosRequestConfig = {};
        if (isDeleting) {
          deleteConfig = { data: { delete: true } };
        }

        await propertyApi.admin.properties.peculiarities.destroyPropertyPeculiarity(
          params.propertyId,
          params.peculiarityId,
          deleteConfig,
        );

        toast.success('Peculiaridade desativada!');

        if (isDeleting) return history.goBack();

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao desativar Peculiaridade',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      if (params.peculiarityId) {
        await propertyApi.admin.properties.peculiarities.restorePropertyPeculiarity(
          params.propertyId,
          params.peculiarityId,
        );

        toast.success('Peculiaridade restaurada!');

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar Peculiaridade.',
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [PROPERTY_PERMISSIONS.PROPERTIES.STORE],
        update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
        destroy: [PROPERTY_PERMISSIONS.PROPERTIES.DESTROY],
        restore: [PROPERTY_PERMISSIONS.PROPERTIES.RESTORE],
      }}
      resourceName="Peculiaridade"
      onSave={handleSave}
      onDestroy={handleDestroy}
      canDelete
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.peculiarityId}
      isActive={peculiarityStatus === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.peculiarityId ? 'Editar' : 'Nova'} Peculiaridade
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField name="name" label="Nome" required />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField name="value" label="Valor" required />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
