import { FC } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import LinkIconButton from 'src/components/LinkIconButton';
import { LinkIconButtonProps } from 'src/components/LinkIconButton/interfaces';

const DeleteButton: FC<LinkIconButtonProps> = (props) => {
  return (
    <LinkIconButton size="small" color="error" {...props}>
      <DeleteIcon fontSize="inherit" />
    </LinkIconButton>
  );
};

export default DeleteButton;
