import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import AutocompleteDepartments from 'src/components/Form/_Common/AutocompleteDepartments';
import AutocompleteUsers from 'src/components/Form/_Common/AutocompleteUsers';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Funnel } from 'src/modules/attendance/@types/models';
import AutocompleteReasons from 'src/modules/attendance/components/Form/_common/AutocompleteReasons';
import { ATTENDANCE_PERMISSIONS } from 'src/modules/attendance/constants/permissions';
import attendanceApi from 'src/modules/attendance/services/api';
import {
  FunnelStoreSchema,
  FunnelUpdateSchema,
} from 'src/modules/attendance/validators/Funnel/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const FunnelTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [funnelStatus, setFunnelStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const funnelSchema = params.id ? FunnelUpdateSchema : FunnelStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data: funnel } = await attendanceApi.admin.funnels.getFunnel(
          params.id,
        );
        setFunnelStatus(funnel.status);

        setTimeout(() => {
          formRef.current?.setData({
            ...funnel,
            reasons: funnel.reasons?.map((reason) => reason.id),
            attendants: funnel.attendants?.map((attendant) => attendant.id),
          });
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<Funnel> = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        funnelSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newFunnel: Funnel;
      if (params.id) {
        const response = await attendanceApi.admin.funnels.updateFunnel(
          params.id,
          data,
        );
        newFunnel = response.data;
      } else {
        const response = await attendanceApi.admin.funnels.storeFunnel(data);
        newFunnel = response.data;
      }

      toast.success('Funil salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/atendimento/funis/${newFunnel.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', funnelSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.destroyFunnel(params.id);

      toast.success('Funil desativado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Funil');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.restoreFunnel(params.id);

      toast.success('Funil restaurado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Funil.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [ATTENDANCE_PERMISSIONS.FUNNELS.STORE],
        update: [ATTENDANCE_PERMISSIONS.FUNNELS.UPDATE],
        destroy: [ATTENDANCE_PERMISSIONS.FUNNELS.DESTROY],
        restore: [ATTENDANCE_PERMISSIONS.FUNNELS.RESTORE],
      }}
      resourceName="Funil"
      onSave={handleSave}
      onDestroy={handleDestroy}
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.id}
      isActive={funnelStatus === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField name="name" label="Nome" required />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Descrição"
                    multiline
                    rows={3}
                  />
                </Grid>

                <Tooltip
                  title="O departamento irá definir qual instancia do whatsapp usar."
                  placement="top-start"
                >
                  <Grid item xs={12}>
                    <AutocompleteDepartments
                      name="department_id"
                      label="Departamento"
                    />
                  </Grid>
                </Tooltip>

                <Grid item xs={12}>
                  <AutocompleteReasons
                    name="reasons"
                    label="Motivos Disponíveis"
                    multiple
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompleteUsers
                    name="attendants"
                    label="Atendentes que terão acesso ao Funil"
                    multiple
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default FunnelTab;
