import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { PublicityType } from 'src/modules/property/@types/models';
import propertyApi from 'src/modules/property/services/api';

type AutocompletePublicitiesTypesProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<PublicityType>, 'options'>;

const AutocompletePublicitiesTypes: React.FC<AutocompletePublicitiesTypesProps> = ({
  requestConfig,
  ...rest
}) => {
  const {
    isLoading,
    data: response,
  } = propertyApi.admin.common.usePublicitiesTypesQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((type) => ({
      key: type.id,
      label: type.name,
      value: type.id,
      data: type,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompletePublicitiesTypes;
