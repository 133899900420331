import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { ISaveRole } from 'src/interfaces/forms/ISaveRole';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IPermission, IRole } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  RoleStoreSchema,
  RoleUpdateSchema,
} from 'src/validators/Role/save.schema';

import { Container, PermissionGroupDivider } from './styles';

const Save: FC = () => {
  // const loggedUser = useSelector((state) => state.auth.user);
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [permissionGroups, setPermissionGroups] = useState<
    Record<string, IPermission[]>
  >({});
  const [roleStatus, setRoleStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const roleSchema = params.id ? RoleUpdateSchema : RoleStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const permissionsResponse = await apiAdminPublic.getPermissions();

        const groups: Record<string, IPermission[]> = {};
        permissionsResponse.data.forEach((permission) => {
          const currentGroup = permission.group || 'Outros';
          if (!groups[currentGroup]) {
            groups[currentGroup] = [permission];
          } else {
            groups[currentGroup].push(permission);
          }
        });
        setPermissionGroups(groups);

        if (params.id) {
          const roleResponse = await api.get(`admin/roles/${params.id}`);
          const { permissions, ...role } = roleResponse.data as IRole;
          const rolePermissions: boolean[] = [];
          permissions?.forEach((perm) => {
            rolePermissions[perm.id] = true;
          });

          setRoleStatus(role.status);

          formRef.current?.setData({
            ...role,
            permissions: rolePermissions,
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler<ISaveRole> = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(roleSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const permissions: number[] = [];
      formData.permissions.forEach((selected, index) => {
        if (selected) permissions.push(index);
      });

      data.permissions = permissions;

      if (params.id) {
        await api.put(`/admin/roles/${params.id}`, data);
      } else {
        await api.post('/admin/roles', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', roleSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (roleStatus === 'active') {
        await api.delete(`/admin/roles/${params.id}`);
        toast.success('Função desativada!');
      } else {
        await api.post(`/admin/roles/${params.id}/restore`);
        toast.success('Função restaurada!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar função.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPermissions = useCallback(
    (checked: boolean, groupKey?: string) => {
      if (groupKey) {
        permissionGroups[groupKey].forEach((permission) => {
          formRef.current?.setFieldValue(
            `permissions[${permission.id}]`,
            checked,
          );
        });
      } else {
        Object.keys(permissionGroups).forEach((permissionGroup) => {
          permissionGroups[permissionGroup].forEach((permission) => {
            formRef.current?.setFieldValue(
              `permissions[${permission.id}]`,
              checked,
            );
          });
        });
      }
    },
    [permissionGroups],
  );

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Função
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name="name" label="Nome" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="group" label="Grupo" />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Permissões" />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CheckBox
                      name="all-permissions"
                      label="Selecionar Tudo"
                      color="default"
                      onChange={(e) =>
                        handleSelectPermissions(e.target.checked)
                      }
                    />
                  </Grid>

                  {Object.keys(permissionGroups)
                    .sort()
                    .map((groupKey) => (
                      <Grid item key={groupKey} xs={12} sm={6}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CheckBox
                            name={`${groupKey}-all`}
                            size="small"
                            color="default"
                            onChange={(e) =>
                              handleSelectPermissions(
                                e.target.checked,
                                groupKey,
                              )
                            }
                          />
                          <Typography variant="h6">{groupKey}</Typography>
                          <PermissionGroupDivider variant="middle" />
                        </Grid>
                        {permissionGroups[groupKey]
                          .sort((a, b) => a.id - b.id)
                          .map((permission) => (
                            <CheckBox
                              key={permission.id}
                              name={`permissions[${permission.id}]`}
                              label={permission.name}
                              size="small"
                            />
                          ))}
                      </Grid>
                    ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can behavior="disable" permissions={['destroy-roles']}>
                {roleStatus === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${roleStatus === 'active' ? 'Desativar' : 'Restaurar'} função`}
        description="Confirma esta ação?"
        confirmColor={roleStatus === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
