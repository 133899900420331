import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Grid } from '@mui/material';
import { Scope } from '@unform/core';
import SearchClientDialog from 'src/components/_functions/SearchClientDialog';
import { SearchClientDialogRef } from 'src/components/_functions/SearchClientDialog/interfaces';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
import IconButton from 'src/components/IconButton';
import { IClient } from 'src/interfaces/models/IClient';

type OwnerField = {
  owner_id: string | number;
  percentage: string;
  owner_name: string;
};

export type CurrentOwnersFieldsData = OwnerField[];

type PropertyOwnersProps = {
  currentValues?: CurrentOwnersFieldsData;
};

const PropertyOwners: React.FC<PropertyOwnersProps> = ({ currentValues }) => {
  const [ownersFields, setOwnersFields] = useState<OwnerField[]>([]);
  const searchClientRef = useRef<SearchClientDialogRef>(null);

  function handleSelectOwner(client: IClient) {
    if (ownersFields.find((ownerField) => ownerField.owner_id == client.id)) {
      return toast.warn('Cliente já definido como proprietário');
    }

    setOwnersFields((state) => [
      ...state,
      { owner_id: client.id, percentage: '', owner_name: client.username },
    ]);

    toast.success('Proprietário adicionado.');
  }

  function handleDeleteOwner(owner: OwnerField) {
    setOwnersFields((state) =>
      state.filter((s) => s.owner_id !== owner.owner_id),
    );
  }

  useEffect(() => {
    setOwnersFields(currentValues || []);
  }, [currentValues]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Divider textAlign="left">Proprietários</Divider>
        </Grid>

        <Grid item xs={12}>
          <Button
            startIcon={<SearchIcon />}
            variant="outlined"
            color="success"
            onClick={() => searchClientRef.current?.show()}
          >
            Proprietário
          </Button>
        </Grid>

        {ownersFields.map((ownerField, index) => (
          <Grid item xs={12} key={ownerField.owner_id}>
            <Grid container spacing={1} alignItems="center">
              <Scope path={`owners[${index}]`}>
                <TextField hidden name="owner_id" value={ownerField.owner_id} />
                <TextField
                  hidden
                  name="owner_name"
                  value={ownerField.owner_name}
                />

                <Grid item xs={12} sm={4} md={2}>
                  <TextField
                    name="percentage"
                    label="Participação (%)"
                    mask="percent"
                    returnUnmasked
                  />
                </Grid>
              </Scope>

              <Grid item xs={12} sm>
                <IconButton
                  onClick={() => handleDeleteOwner(ownerField)}
                  tooltip="Remover"
                  color="error"
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {ownerField.owner_name}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <SearchClientDialog
        ref={searchClientRef}
        onSelectClient={handleSelectOwner}
        defaultQueryParams={{ types: ['F', 'J', 'L'] }}
      />
    </>
  );
};

export default PropertyOwners;
