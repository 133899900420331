import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import EditButton from 'src/components/Table/EditButton';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { IBank } from 'src/interfaces/models/IBank';
import BankFilterSchema from 'src/validators/Bank/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  { field: 'number', headerName: 'Número' },
  { field: 'name', headerName: 'Nome', flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    renderCell(params) {
      return <StatusIcon status={params.row.status} />;
    },
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    renderCell(params) {
      return (
        <Can permissions={['update-banks']}>
          <EditButton to={`/bancos/${params.id}`} />
        </Can>
      );
    },
  },
];

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
  } = usePagination<IBank>('admin/banks');

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Bancos</Typography>

            <Can permissions={['store-banks']}>
              <LinkButton
                to="/bancos/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                showFilters
                filterProps={{
                  yupSchema: BankFilterSchema,
                  children: <Filters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
