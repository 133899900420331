import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import apiAdminPublic from 'src/services/api/adminPublic';

type AutocompleteDepartmentUsersProps = {
  deparmentId?: number;
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps, 'options'>;

const AutocompleteDepartmentUsers: React.FC<AutocompleteDepartmentUsersProps> = ({
  deparmentId,
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } = apiAdminPublic.useUsersQuery({
    queryOptions: {
      enabled: !!deparmentId,
    },
    requestConfig: {
      ...requestConfig,
      params: {
        ...requestConfig?.params,
        deparment_id: deparmentId,
      },
    },
  });

  const options =
    response?.data?.map((user) => ({
      key: user.id,
      label: user.name,
      value: user.id,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteDepartmentUsers;
