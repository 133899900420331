import yup from 'src/libs/yup';

export const DetailCondominiumStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  is_required: yup.boolean().label('Obrigátorio'),
  input_type: yup.string().label('Tipo do Valor'),
});

export const DetailCondominiumUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  is_required: yup.boolean().label('Obrigátorio'),
  input_type: yup.string().label('Tipo do Valor'),
});
