import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AutocompletePropertyTypes from 'src/components/Form/_Common/AutocompletePropertyTypes';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const PropertyFilters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <AutocompletePropertyTypes
          name="property_type_id"
          label="Tipo"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="search_address" label="Endereço" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
        />
      </Grid>
    </>
  );
};

export default PropertyFilters;
