import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { Reason } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';

type AutocompleteReasonsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<Reason>, 'options'>;

const AutocompleteReasons: React.FC<AutocompleteReasonsProps> = ({
  requestConfig,
  ...rest
}) => {
  const {
    isLoading,
    data: response,
  } = attendanceApi.admin.common.useReasonsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((reason) => ({
      key: reason.id,
      label: reason.name,
      value: reason.id,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteReasons;
