import yup from 'src/libs/yup';

export const BankStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  number: yup.string().label('Número'),
});

export const BankUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  number: yup.string().label('Número'),
});
