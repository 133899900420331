import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BackButton from 'src/components/BackButton';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { SaveIdentityPropertyParams } from 'src/modules/property/@types/params';
import { IdentityUpdateContext } from 'src/modules/property/pages/Private/Identities/Update/context';

import FilesTab from './FilesTab';
import FinancingTab from './FinancingTab';
import KeysTab from './KeysTab';
import PeculiaritiesTab from './PeculiaritiesTab';
import PhotosTab from './PhotosTab';
import PropertyTab from './PropertyTab';
import { Container } from './styles';
import VideosTab from './VideosTab';

const IdentityMultipleTabs: NavigationTab[] = [];

const Save: FC = () => {
  const params = useParams<SaveIdentityPropertyParams>();
  const { identity } = useContext(IdentityUpdateContext);

  const SavePropertyTabs: NavigationTab[] = [
    { name: 'Imóvel', slug: '', Component: PropertyTab },
    {
      name: 'Peculiariadades',
      slug: 'peculiaridades',
      Component: PeculiaritiesTab,
      disabled: !params.propertyId,
    },
    {
      name: 'Fotos',
      slug: 'fotos',
      Component: PhotosTab,
      disabled: !params.propertyId,
    },
    {
      name: 'Vídeos',
      slug: 'videos',
      Component: VideosTab,
      disabled: !params.propertyId,
    },
    {
      name: 'Arquivos',
      slug: 'files',
      Component: FilesTab,
      disabled: !params.propertyId,
    },
    {
      name: 'Chaves',
      slug: 'chaves',
      Component: KeysTab,
      disabled: !params.propertyId,
    },
    {
      name: 'Financiamento',
      slug: 'financiamento',
      Component: FinancingTab,
      disabled: !params.propertyId,
    },
  ];

  if (identity?.type === 'multiple') {
    SavePropertyTabs.splice(1, 0, ...IdentityMultipleTabs);
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.propertyId
                ? `Editar Imóvel #${params.propertyId}`
                : `Novo Imóvel`}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <NavigationTabs
            selectedTabParam="propertyTab"
            baseUrl={`/imovel/identidades/${params.id}/imoveis${
              params.propertyId ? `/${params.propertyId}` : ''
            }`}
            tabs={SavePropertyTabs}
          />
        </Grid>

        <Grid item xs={12}>
          {SavePropertyTabs.map(({ name, slug, Component }) => (
            <TabPanel key={name} show={slug === (params.propertyTab || '')}>
              <Component />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
