import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import BackButton from 'src/components/BackButton';
import DatePicker from 'src/components/Form/DatePicker';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { EconomicIndicatorValue } from 'src/modules/property/@types/models';
import { SaveEconomicIndicatorValueParams } from 'src/modules/property/@types/params';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  EconomicIndicatorValueStoreSchema,
  EconomicIndicatorValueUpdateSchema,
} from 'src/modules/property/validators/EconomicIndicator/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<SaveEconomicIndicatorValueParams>();
  const { url } = useRouteMatch();

  const schema = params.valueId
    ? EconomicIndicatorValueUpdateSchema
    : EconomicIndicatorValueStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.valueId) {
        const {
          data: indicatorValue,
        } = await propertyApi.admin.economicIndicators.values.getValue(
          params.id,
          params.valueId,
        );
        setStatus(indicatorValue.status);

        setTimeout(() => {
          formRef.current?.setData(indicatorValue);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, params.valueId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<EconomicIndicatorValue> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(schema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newIndicatorValue: EconomicIndicatorValue;
      if (params.valueId) {
        const response = await propertyApi.admin.economicIndicators.values.updateValue(
          params.id,
          params.valueId,
          data,
        );
        newIndicatorValue = response.data;
      } else {
        const response = await propertyApi.admin.economicIndicators.values.storeValue(
          params.id,
          data,
        );
        newIndicatorValue = response.data;
      }

      toast.success('Valor do Indicador salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.valueId) {
        history.replace(`${url}/${newIndicatorValue.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', schema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async (isDeleting) => {
    try {
      setLoading(true);

      if (params.valueId) {
        let deleteConfig: AxiosRequestConfig = {};
        if (isDeleting) {
          deleteConfig = { data: { delete: true } };
        }

        await propertyApi.admin.economicIndicators.values.destroyValue(
          params.id,
          params.valueId,
          deleteConfig,
        );

        toast.success('Valor do Indicador desativado!');

        if (isDeleting) return history.goBack();

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao desativar Valor do Indicador',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      if (params.valueId) {
        await propertyApi.admin.economicIndicators.values.restoreValue(
          params.id,
          params.valueId,
        );

        toast.success('Valor do Indicador restaurado!');

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar Valor do Indicador.',
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.STORE],
        update: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.UPDATE],
        destroy: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.DESTROY],
        restore: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.RESTORE],
      }}
      resourceName="Valor do Indicador"
      onSave={handleSave}
      onDestroy={handleDestroy}
      canDelete
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.valueId}
      isActive={status === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.valueId ? 'Editar' : 'Novo'} Valor do Indicador
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="indicator_value"
                    label="Valor (R$ ou %)"
                    type="numer"
                    mask="currency"
                    returnUnmasked
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePicker
                    name="indicator_date"
                    label="Referência"
                    InputProps={{ required: true }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
