import yup from 'src/libs/yup';

export const ZApiInstanceStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  instance_id: yup.string().required().label('ID da Instância'),
  instance_token: yup.string().required().label('Token da Instância'),
  instance_client_token: yup.string().emptyAsNull().label('Token de Segurança'),
  update_webhooks: yup.boolean().label('Atualizar Webhooks'),
});

export const ZApiInstanceUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().emptyAsNull().label('Descrição'),
  instance_id: yup.string().required().label('ID da Instância'),
  instance_token: yup.string().required().label('Token da Instância'),
  instance_client_token: yup.string().emptyAsNull().label('Token de Segurança'),
  update_webhooks: yup.boolean().label('Atualizar Webhooks'),
});
