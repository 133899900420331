import styled, { css } from 'styled-components';

import MuiContainer from '@mui/material/Container';

export const Container = styled(MuiContainer)`
  padding: ${({ theme }) => theme.spacing(1)};
  height: calc(100vh - 56px);

  display: flex;
  flex-direction: column;
  gap: 4px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      height: calc(100vh - 64px);
    }
  `}
`;
