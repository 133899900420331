import axios from 'axios';
import { store } from 'src/store';

import * as clients from './clients';
import * as departments from './departments';
import * as medias from './medias';
import * as zApiInstances from './zApiInstances';

export const adminAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin`,
  headers: {
    Accept: 'application/json',
  },
});

adminAxios.interceptors.request.use((config) => {
  const token = store.getState().auth.token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const apiAdmin = {
  ...clients,
  ...medias,
  ...zApiInstances,
  ...departments,
};

export default apiAdmin;
