import yup from 'src/libs/yup';
import { OFFER_TYPES } from 'src/modules/property/constants';

export const OfferStoreSchema = yup.object().shape({
  type: yup.string().required().label('Propósito'),
  observation: yup.string().label('Observação'),
  offer_brought_via_id: yup.number().nullable().required().label('Motivo'),
  captivator_id: yup.number().nullable().label('Captador'),
  price: yup.number().label('Preço'),
  price_on_request: yup.boolean().label('Preço sob Consulta'),
  properties: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      main: yup.boolean(),
    }),
  ),
  concurrents: yup.array().of(yup.number()),
  rent: yup
    .object()
    .nullable()
    .shape({
      iptu_percent_owner: yup.number(),
      iptu_percent_renter: yup.number(),
      contract_months: yup.number(),
      readjustment_months: yup.number(),
      warranty: yup.boolean(),
      fee: yup.number(),
      fee_secondary: yup.number(),
      initial_fee: yup.number(),
      readjustment_indicator_id: yup.number(),
      last_rent_id: yup.number(),
    })
    .when('type', {
      is: OFFER_TYPES.RENT,
      then: (schema) => schema.required(),
    }),
  sale: yup
    .object()
    .nullable()
    .shape({
      conditions: yup.string(),
      accept_interchange: yup.boolean(),
      accept_property: yup.boolean(),
      accept_vehicle: yup.boolean(),
      accept_financing: yup.boolean(),
    })
    .when('type', {
      is: OFFER_TYPES.SALE,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.transform(() => ({})),
    }),
});

export const OfferUpdateSchema = yup.object().shape({
  type: yup.string().required().label('Propósito'),
  observation: yup.string().label('Observação'),
  offer_brought_via_id: yup.number().nullable().required().label('Motivo'),
  captivator_id: yup.number().nullable().label('Captador'),
  price: yup.number().label('Preço'),
  price_on_request: yup.boolean().label('Preço sob Consulta'),
  properties: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      main: yup.boolean(),
    }),
  ),
  concurrents: yup.array().of(yup.number()),
  rent: yup
    .object()
    .nullable()
    .shape({
      iptu_percent_owner: yup.number(),
      iptu_percent_renter: yup.number(),
      contract_months: yup.number(),
      readjustment_months: yup.number(),
      warranty: yup.boolean(),
      fee: yup.number(),
      fee_secondary: yup.number(),
      initial_fee: yup.number(),
      readjustment_indicator_id: yup.number(),
      last_rent_id: yup.number(),
    })
    .when('type', {
      is: OFFER_TYPES.RENT,
      then: (schema) => schema.required(),
    }),
  sale: yup
    .object()
    .nullable()
    .shape({
      conditions: yup.string(),
      accept_interchange: yup.boolean(),
      accept_property: yup.boolean(),
      accept_vehicle: yup.boolean(),
      accept_financing: yup.boolean(),
    })
    .when('type', {
      is: OFFER_TYPES.SALE,
      then: (schema) => schema.required(),
    }),
});
