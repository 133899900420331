import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePickerOperator from 'src/components/Form/DatePickerOperator';
import NumberOperator from 'src/components/Form/NumberOperator';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <DatePickerOperator
          name="indicator_date"
          label="Data de Refeência"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <NumberOperator name="indicator_value" label="Valor" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
        />
      </Grid>
    </>
  );
};

export default Filters;
