import React, { useCallback, useEffect, useRef, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import { FormHandles } from '@unform/core';
import Button from 'src/components/Button';
import AutocompleteNeighborhoods from 'src/components/Form/_Common/AutocompleteNeighborhoods';
import AutocompleteStreets from 'src/components/Form/_Common/AutocompleteStreets';
import TextField from 'src/components/Form/TextField';
import SearchAddressDialog from 'src/components/SearchAddressDialog';
import { SearchAddressDialogRef } from 'src/components/SearchAddressDialog/interfaces';
import { IAddress } from 'src/interfaces/models/IAddress';

type AddressFieldsProps = {
  formRef?: React.RefObject<FormHandles>;
  currentAddress?: IAddress;
  noContainer?: boolean;
  withComplement?: boolean;
  withLatLng?: boolean;
  scopePath?: string;
};

const AddressFields: React.FC<AddressFieldsProps> = ({
  formRef,
  currentAddress,
  noContainer,
  withComplement,
  withLatLng,
}) => {
  const searchAddressDialogRef = useRef<SearchAddressDialogRef>(null);
  const [neighborhoodDisabled, setNeighborhoodDisabled] = useState(true);
  const [streetDisabled, setStreetDisabled] = useState(true);

  const handleSelectAddress = useCallback(
    (address: IAddress) => {
      searchAddressDialogRef.current?.hide();

      formRef?.current?.setFieldValue('zip_code', address.zip_code);
      formRef?.current?.setFieldValue(
        'city_name',
        `${address?.city?.name}/${address?.city?.state?.initials}` || '',
      );
      formRef?.current?.setFieldValue('city_id', address?.city?.id || null);
      formRef?.current?.setFieldValue(
        'neighborhood_id',
        address?.neighborhood?.id || null,
      );
      setNeighborhoodDisabled(!!address?.neighborhood);
      formRef?.current?.setFieldValue('street_id', address?.street?.id || null);
      setStreetDisabled(!!address?.street);

      if (withLatLng) {
        const lat = formRef?.current?.getFieldValue('latitude');
        const lng = formRef?.current?.getFieldValue('longitude');
        if (!lat && !lng) {
          formRef?.current?.setFieldValue('latitude', address?.latitude || '');
          formRef?.current?.setFieldValue(
            'longitude',
            address?.longitude || '',
          );
        }
      }
    },
    [formRef, withLatLng],
  );

  useEffect(() => {
    if (currentAddress) {
      setTimeout(() => {
        handleSelectAddress(currentAddress);
      }, 50);
    }
  }, [currentAddress, handleSelectAddress]);

  const content = (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={() => searchAddressDialogRef.current?.show()}
        >
          Endereço
        </Button>
      </Grid>

      <TextField name="city_id" disabled hidden />

      <Grid item xs={12} sm={4}>
        <TextField
          name="zip_code"
          label="CEP"
          mask="zip_code"
          returnUnmasked
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField name="city_name" label="Cidade" disabled required />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteNeighborhoods
          name="neighborhood_id"
          label="Bairro"
          disabled={neighborhoodDisabled}
          textFieldProps={{ required: true }}
          formRef={formRef}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <AutocompleteStreets
          name="street_id"
          label="Rua"
          disabled={streetDisabled}
          textFieldProps={{ required: true }}
          formRef={formRef}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField name="number" label="Nº" required />
      </Grid>

      {withComplement && (
        <Grid item xs={12}>
          <TextField name="complement" label="Complemento" />
        </Grid>
      )}

      {withLatLng && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField name="latitude" label="Latitude" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField name="longitude" label="Longitude" />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <TextField name="map_link" label="Link do Mapa" />
      </Grid>

      <SearchAddressDialog
        ref={searchAddressDialogRef}
        onSelectAddress={(address) => handleSelectAddress(address)}
      />
    </>
  );

  if (noContainer) return content;

  return (
    <Grid container spacing={1}>
      {content}
    </Grid>
  );
};

export default AddressFields;
