import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { CondominiumPeculiarity } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getCondominiumPeculiarities(
  condominiumId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<
    IResponseAPIFilter<CondominiumPeculiarity>
  >(`/property/admin/condominiums/${condominiumId}/peculiarities`, config);

  return response;
}

function useCondominiumPeculiaritiesQuery(
  condominiumId: string | number,
  data?: IApiUseQueryData<CondominiumPeculiarity>,
) {
  return useQuery<IApiUseQueryFnData<CondominiumPeculiarity>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.CONDOMINIUM_PECULIARITIES,
      condominiumId,
      data?.requestConfig?.params,
    ],
    () => getCondominiumPeculiarities(condominiumId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateCondominiumPeculiaritiesQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.CONDOMINIUM_PECULIARITIES,
  ]);
  queryClient.invalidateQueries([
    PROPERTY_ADMIN_QUERY_KEYS.CONDOMINIUM_PECULIARITIES,
  ]);
}

async function storeCondominiumPeculiarity(
  condominiumId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<CondominiumPeculiarity>(
    `/property/admin/condominiums/${condominiumId}/peculiarities`,
    data,
    config,
  );

  invalidateCondominiumPeculiaritiesQueries();

  return response;
}

async function getCondominiumPeculiarity(
  condominiumId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<CondominiumPeculiarity>(
    `/property/admin/condominiums/${condominiumId}/peculiarities/${id}`,
    config,
  );

  return response;
}

async function updateCondominiumPeculiarity(
  condominiumId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<CondominiumPeculiarity>(
    `/property/admin/condominiums/${condominiumId}/peculiarities/${id}`,
    data,
    config,
  );

  invalidateCondominiumPeculiaritiesQueries();

  return response;
}

async function destroyCondominiumPeculiarity(
  condominiumId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/condominiums/${condominiumId}/peculiarities/${id}`,
    config,
  );

  invalidateCondominiumPeculiaritiesQueries();

  return response;
}

async function restoreCondominiumPeculiarity(
  condominiumId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/condominiums/${condominiumId}/peculiarities/${id}/restore`,
    config,
  );

  invalidateCondominiumPeculiaritiesQueries();

  return response;
}

export const peculiarities = {
  getCondominiumPeculiarities,
  useCondominiumPeculiaritiesQuery,
  storeCondominiumPeculiarity,
  getCondominiumPeculiarity,
  updateCondominiumPeculiarity,
  destroyCondominiumPeculiarity,
  restoreCondominiumPeculiarity,
};
