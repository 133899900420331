import yup from 'src/libs/yup';

export const StateStoreSchema = yup.object().shape({
  country_id: yup.number().required().label('País'),
  name: yup.string().required().label('Nome'),
  initials: yup.string().required().label('Iniciais'),
});

export const StateUpdateSchema = yup.object().shape({
  country_id: yup.number().required().label('País'),
  name: yup.string().required().label('Nome'),
  initials: yup.string().required().label('Iniciais'),
});
