import yup from 'src/libs/yup';
import { defaultNumberOperator } from 'src/validators/DefaultFilter.schema';

const MediaFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  type: yup.string().nullable().label('Tipo'),
  source: yup.string().nullable().label('Origem'),
  max_size: defaultNumberOperator,
  status: yup.string().nullable().label('Status'),
});

export default MediaFilterSchema;
