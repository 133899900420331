import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import RemoveRedEyeIcon from '@mui/icons-material/MarkUnreadChatAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import IconButton from 'src/components/IconButton';
import ChatAttendantsDialog from 'src/pages/Private/Omnichannel/Chat/ChatAttendantsDialog';
import ChatClientOpportunitiesDialog from 'src/pages/Private/Omnichannel/Chat/ChatClientOpportunitiesDialog';
import CloseChatDialog from 'src/pages/Private/Omnichannel/Chat/CloseChatDialog';
import OmnichannelContext from 'src/pages/Private/Omnichannel/context';
import WSOmnichannel from 'src/services/websocket/omnichannel';

const ChatMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [openAttendantsDialog, setOpenAttendantsDialog] = useState(false);
  const [openOpportunitiesDialog, setOpenOpportunitiesDialog] = useState(false);
  const [showCloseChatDialog, setShowCloseChatDialog] = useState(false);
  const { selectedChat, setSelectedChat } = useContext(OmnichannelContext);
  const user = useSelector((state) => state.auth.user);

  const isChatActive = selectedChat?.status === 'active';

  function handleClose() {
    setAnchorEl(undefined);
  }

  function handleUnreadChat() {
    if (selectedChat) {
      setSelectedChat(undefined);
      WSOmnichannel.socket?.emit(
        'unread_chat',
        {
          chat_id: selectedChat._id,
        },
        (result) => {
          if (result.error) {
            toast.error(result.error.message);
          } else {
            toast.success('Chat marcado como não lido!');
          }
        },
      );
    }
  }

  async function handleAssignToMe() {
    if (selectedChat && user) {
      WSOmnichannel.socket?.emit(
        'transfer_chat',
        {
          chat_id: selectedChat._id,
          attendant_id: user.id,
        },
        (result) => {
          if (result.error) {
            toast.error(result.error.message);
          } else {
            toast.success('Chat marcado como não lido!');
          }
        },
      );
    }
  }

  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => setOpenOpportunitiesDialog(true)}>
          <ThreePIcon fontSize="inherit" />
          <Typography variant="body2" marginLeft={1}>
            Oportunidades
          </Typography>
        </MenuItem>

        <MenuItem onClick={handleAssignToMe}>
          <PersonIcon fontSize="inherit" />
          <Typography variant="body2" marginLeft={1}>
            Atribuir Chat a mim
          </Typography>
        </MenuItem>

        <MenuItem onClick={() => setOpenAttendantsDialog(true)}>
          <GroupIcon fontSize="inherit" />
          <Typography variant="body2" marginLeft={1}>
            Atendentes
          </Typography>
        </MenuItem>

        <MenuItem onClick={() => handleUnreadChat()}>
          <RemoveRedEyeIcon fontSize="inherit" />
          <Typography variant="body2" marginLeft={1}>
            Marcar como Não Lido
          </Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => setShowCloseChatDialog(true)}
          disabled={!isChatActive}
        >
          <CloseIcon fontSize="inherit" color="error" />
          <Typography variant="body2" color="error" marginLeft={1}>
            Encerrar Chat
          </Typography>
        </MenuItem>
      </Menu>

      <ChatAttendantsDialog
        open={openAttendantsDialog}
        onClose={() => setOpenAttendantsDialog(false)}
      />

      {selectedChat && (
        <ChatClientOpportunitiesDialog
          chat={selectedChat}
          open={openOpportunitiesDialog}
          onClose={() => setOpenOpportunitiesDialog(false)}
        />
      )}

      <CloseChatDialog
        open={showCloseChatDialog}
        onClose={() => setShowCloseChatDialog(false)}
      />
    </>
  );
};

export default ChatMenu;
