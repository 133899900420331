import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Identity } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getIdentities(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Identity>>(
    '/property/admin/identities',
    config,
  );

  return response;
}

export function useIdentitiesQuery(data?: IApiUseQueryData<Identity>) {
  return useQuery<IApiUseQueryFnData<Identity>>(
    [PROPERTY_ADMIN_QUERY_KEYS.IDENTITIES, data?.requestConfig?.params],
    () => getIdentities(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateIdentitiesQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.IDENTITIES]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.IDENTITIES]);
}

export async function getIdentity(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Identity>(
    `/property/admin/identities/${id}`,
    config,
  );

  return response;
}

export async function storeIdentity(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Identity>(
    `/property/admin/identities`,
    data,
    config,
  );

  invalidateIdentitiesQueries();

  return response;
}

export async function updateIdentity(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Identity>(
    `/property/admin/identities/${id}`,
    data,
    config,
  );

  invalidateIdentitiesQueries();

  return response;
}

export async function destroyIdentity(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<Identity>(
    `/property/admin/identities/${id}`,
    config,
  );

  invalidateIdentitiesQueries();

  return response;
}

export async function restoreIdentity(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Identity>(
    `/property/admin/identities/${id}/restore`,
    config,
  );

  invalidateIdentitiesQueries();

  return response;
}

export { owners } from './owners';
