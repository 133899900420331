import { AxiosRequestConfig } from 'axios';
import { PropertyFinancing } from 'src/modules/property/@types/models';
import apiAxios from 'src/services/api';

async function getPropertyFinancing(
  propertyId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PropertyFinancing | undefined>(
    `/property/admin/properties/${propertyId}/financing`,
    config,
  );

  return response;
}

async function updatePropertyFinancing(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PropertyFinancing | undefined>(
    `/property/admin/properties/${propertyId}/financing`,
    data,
    config,
  );

  return response;
}

export const financing = {
  getPropertyFinancing,
  updatePropertyFinancing,
};
