import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import PhotoIcon from '@mui/icons-material/Photo';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { PropertyMedia } from 'src/modules/property/@types/models';
import { SaveIdentityPropertyMediaParams } from 'src/modules/property/@types/params';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import PropertyMediaFilterSchema from 'src/modules/property/validators/PropertyMedia/filter.schema';
import { handleApiResponseErrors } from 'src/utils/errors';
import { propertyMediaIsImage } from 'src/utils/medias';

import Filters from './Filters';

const List: FC = () => {
  const { url } = useRouteMatch();
  const params = useParams<SaveIdentityPropertyMediaParams>();
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination<PropertyMedia>((queryConfig) =>
    propertyApi.admin.properties.photos.usePropertyPhotosQuery(
      params.propertyId,
      queryConfig,
    ),
  );

  async function updateCover(propertyMedia: PropertyMedia) {
    try {
      await propertyApi.admin.properties.cover.storePropertyCover(
        params.propertyId,
        { cover_id: propertyMedia.id },
      );

      toast.success('Imagem definida como capa!');
      refetch();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Não foi possível definir a capa',
      );
    }
  }

  async function destroyCover(propertyMedia: PropertyMedia) {
    try {
      await propertyApi.admin.properties.cover.destroyPropertyCover(
        params.propertyId,
        propertyMedia.id,
      );

      toast.success('Capa removida!');
      refetch();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Não foi possível remover a capa',
      );
    }
  }

  const columns: GridColDef<PropertyMedia>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'url',
      headerName: 'Foto',
      renderCell({ row }) {
        const isImage = propertyMediaIsImage(row);
        return isImage ? (
          <a href={row.url} target="_blank" rel="noreferrer">
            <img src={row.url} width="100%" alt={row.name} />
          </a>
        ) : (
          <LinkButton
            href={row.url}
            target="_blank"
            size="small"
            startIcon={<RemoveRedEyeIcon fontSize="inherit" />}
          >
            Abrir
          </LinkButton>
        );
      },
    },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'is_cover',
      headerName: 'Capa',
      headerAlign: 'center',
      align: 'center',
      valueGetter({ value }) {
        return value ? 'Sim' : 'Não';
      },
    },
    {
      field: 'show_in_website',
      align: 'center',
      headerName: 'Site',
      headerAlign: 'center',
      valueGetter({ row }) {
        return row.show_in_website ? 'Sim' : 'Não';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ row }) {
        return (
          <TableActions
            resourceName="Foto"
            resourceId={row.id}
            resourceStatus={row.status}
            editLink={`${url}/${row.id}`}
            editPermissions={[PROPERTY_PERMISSIONS.PROPERTIES.UPDATE]}
            canDelete
            destroyApiEndpoint={(resourceId, config) =>
              propertyApi.admin.properties.photos.destroyPropertyPhoto(
                row.property_id,
                resourceId,
                config,
              )
            }
            destroyPermissions={[PROPERTY_PERMISSIONS.PROPERTIES.DESTROY]}
            restoreApiEndpoint={(resourceId, config) =>
              propertyApi.admin.properties.photos.restorePropertyPhoto(
                row.property_id,
                resourceId,
                config,
              )
            }
            restorePermissions={[PROPERTY_PERMISSIONS.PROPERTIES.RESTORE]}
            descriptionPrefix={`A Foto ${row.name}`}
            onFinish={refetch}
            startItems={
              <>
                <Can permissions={[PROPERTY_PERMISSIONS.PROPERTIES.UPDATE]}>
                  {row.is_cover ? (
                    <CustomMenuItem
                      button
                      text="Remover Capa"
                      Icon={PhotoIcon}
                      iconProps={{ color: 'error' }}
                      onClick={() => destroyCover(row)}
                    />
                  ) : (
                    <CustomMenuItem
                      button
                      text="Definir como Capa"
                      Icon={PhotoIcon}
                      iconProps={{ color: 'primary' }}
                      onClick={() => updateCover(row)}
                    />
                  )}
                </Can>
              </>
            }
          />
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="end" alignItems="center">
          <Can
            behavior="disable"
            permissions={[PROPERTY_PERMISSIONS.PROPERTIES.STORE]}
          >
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Nova
            </LinkButton>
          </Can>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Table
              autoHeight
              rows={response?.data.data || []}
              pageSize={response?.data.meta.per_page}
              rowCount={response?.data.meta.total}
              columns={columns}
              loading={isLoading}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              canSearch
              showFilters
              filterProps={{
                yupSchema: PropertyMediaFilterSchema,
                children: <Filters />,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default List;
