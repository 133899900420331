import { FC, useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import CheckBox from 'src/components/Form/Checkbox';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IRole } from 'src/interfaces/models';
import api from 'src/services/api';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const UserFilters: FC = () => {
  const [rolesItems, setRolesItems] = useState<Item[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.get('/admin/roles');

        const roles = response.data as IRole[];

        setRolesItems(
          roles.map((role) => ({
            key: role.id,
            label: role.name,
            value: role.id,
          })),
        );
      } catch (error) {}
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={6}>
        <Select
          name="role_id"
          label="Função"
          items={rolesItems}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <CheckBox
          name="is_admin"
          label="Administrador"
          size="small"
          color="primary"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="name"
          label="Nome"
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="email"
          label="E-mail"
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="login"
          label="Login"
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          name="status"
          label="Status"
          items={statusItems}
          fullWidth
          variant="outlined"
          size="small"
        />
      </Grid>
    </>
  );
};

export default UserFilters;
