import yup from 'src/libs/yup';

export const CondominiumPeculiarityStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  value: yup.string().required().label('Valor'),
});

export const CondominiumPeculiarityUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  value: yup.string().required().label('Valor'),
});
