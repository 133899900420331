import { createGlobalStyle } from 'styled-components';
import 'react-chat-elements/dist/main.css';

const GlobalStyle = createGlobalStyle`

 * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;


    input,
    textarea,
    button {
      font-family: 'Poppins', sans-serif;
    }

    button {
      cursor: pointer;
    }

    .root-link-button {
      text-decoration: none;
    }
  }
`;

export default GlobalStyle;
