import yup from 'src/libs/yup';

export const UserStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().label('Nome'),
  login: yup.string().required().label('Login'),
  password: yup.string().required().label('Senha'),
  password_confirmation: yup
    .string()
    .required()
    .equalTo(yup.ref('password'), 'senha')
    .label('Confirmação'),
  change_password: yup.boolean().label('Mudar Senha'),
  role_id: yup.string().nullable().label('Função'),
  is_admin: yup.bool().nullable().label('Administrador'),
  permissions: yup.array().of(yup.number()),
  photo: yup.mixed().isFile().label('Foto'),
});

export const UserUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  email: yup.string().email().label('Nome'),
  login: yup.string().required().label('Login'),
  password: yup.string().label('Senha'),
  password_confirmation: yup
    .string()
    .equalTo(yup.ref('password'), 'senha')
    .label('Confirmação'),
  change_password: yup.boolean().label('Mudar Senha'),
  role_id: yup.string().nullable().label('Função'),
  is_admin: yup.bool().nullable().label('Administrador'),
  permissions: yup.array().of(yup.number()),
  photo: yup.mixed().isFile().label('Foto'),
});
