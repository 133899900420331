import styled from 'styled-components';

import MuiContainer from '@mui/material/Container';
import MuiPaper from '@mui/material/Paper';

export const Container = styled(MuiContainer)`
  padding: 0;
  height: 100%;
  max-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
`;

export const Header = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const HeaderTitle = styled(Header)`
  display: flex;
  gap: 16px;
`;

export const Body = styled.div`
  flex-grow: 1;
  height: 30vh;

  display: flex;
`;
