import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import BackButton from 'src/components/BackButton';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import { IClient } from 'src/interfaces/models/IClient';
import api from 'src/services/api';
import { hasPermission } from 'src/utils/helpers';

import AddressTab from './AddressTab';
import BondTab from './BondTab';
import ClientTab from './ClientTab';
import ContactsTab from './ContactsTab';
import DocumentsTab from './DocumentsTab';
import RepresentantTab from './RepresentantTab';
import SposesTab from './SposesTab';
import { Container } from './styles';

const clientsTabs = [
  {
    name: 'Cliente',
    slug: '',
    component: <ClientTab />,
    permissions: ['store-clients', 'update-clients'],
  },
  {
    name: 'Endereços',
    slug: 'enderecos',
    component: <AddressTab />,
    permissions: ['list-client-addresses'],
  },
  {
    name: 'Documentos',
    slug: 'documentos',
    component: <DocumentsTab />,
    permissions: ['list-client-documents'],
  },
  {
    name: 'Contatos',
    slug: 'contatos',
    component: <ContactsTab />,
    permissions: ['list-client-contacts'],
  },
  {
    name: 'Cônjuges',
    slug: 'conjuges',
    component: <SposesTab />,
    permissions: ['list-client-spouses'],
    type: 'F',
  },
  {
    name: 'Representantes',
    slug: 'representantes',
    component: <RepresentantTab />,
    permissions: ['list-client-representants'],
    type: 'J',
  },
  {
    name: 'Clientes Vinculados',
    slug: 'vinculos',
    component: <BondTab />,
  },
];

const Save: FC = () => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const [clientType, setClientType] = useState('');
  const params = useParams<ITabEditParams>();
  const history = useHistory();

  const handleTabChange = (e: ChangeEvent<any>, newValue: number) => {
    let goToUrl = `/clientes/${params.id}`;
    if (newValue > 0) {
      goToUrl = goToUrl.concat(`/${clientsTabs[newValue].slug}?status=active`);
    }

    history.replace(goToUrl);
  };

  useEffect(() => {
    const loadData = async () => {
      if (params.id) {
        const response = await api.get<IClient>(`admin/clients/${params.id}`);

        setClientType(response.data.type);
      }
    };

    loadData();
  }, [params.id]);

  useEffect(() => {
    if (params.selectedTab) {
      setCurrentTab(
        clientsTabs.findIndex((tab) => tab.slug === params.selectedTab),
      );
    } else {
      setCurrentTab(0);
    }
  }, [params.selectedTab]);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Novo'} Cliente
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AppBar color="transparent" position="relative">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {clientsTabs.map(({ name, permissions, type }, index) => (
                <Tab
                  key={name}
                  label={name}
                  disabled={
                    !hasPermission(permissions) ||
                    (index > 0 && !params.id) ||
                    (!!type && type !== clientType)
                  }
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12}>
          {clientsTabs.map(({ name, slug, component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              {component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
