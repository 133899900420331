import yup from 'src/libs/yup';

export const RealtorStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  type: yup.string().required().label('Tipo'),
});

export const RealtorUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  type: yup.string().required().label('Tipo'),
});
