import { createContext } from 'react';

import { Identity } from 'src/modules/property/@types/models';

export type IdentityUpdateContextData = {
  identity?: Identity;
};

type IdentityUpdateContextHandles = {
  updateContext?: (data: IdentityUpdateContextData) => void;
};

export const IdentityUpdateContext = createContext<
  IdentityUpdateContextData & IdentityUpdateContextHandles
>({});
