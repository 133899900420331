import yup from 'src/libs/yup';

export const CivilStatusTypeStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  need_spouse: yup.boolean().required().label('Cônjuge'),
});

export const CivilStatusTypeUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  need_spouse: yup.boolean().required().label('Cônjuge'),
});
