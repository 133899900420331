import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import { INPUT_TYPE_OPTIONS } from 'src/constants';

const isRequiredItems = [
  { key: 1, label: 'Sim', value: 1 },
  { key: 2, label: 'Não', value: 0 },
];
const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const DetailCondominiumFilters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="slug" label="Slug" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="input_type"
          label="Tipo do Valor"
          options={INPUT_TYPE_OPTIONS}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="is_required"
          label="Obrigatório"
          options={isRequiredItems}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
        />
      </Grid>
    </>
  );
};

export default DetailCondominiumFilters;
