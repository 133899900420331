import yup from 'src/libs/yup';
import { operators } from 'src/utils/helpers';

const oneOfOpearators = operators
  .filter((op) => !!op.value)
  .map((op) => op.value);

const ClientFilterSchema = yup.object().shape({
  username: yup.string().label('Usuário'),
  email: yup.string().label('E-mail'),
  phone: yup.string().label('Telefone'),
  status: yup.string().nullable().label('Status'),
  type: yup.string().nullable().label('Tipo'),
  person_name: yup.string().label('Nome'),
  person_document: yup.string().label('CPF'),
  person_register_code: yup.string().label('RG'),
  birth_date: yup
    .object()
    .optional()
    .shape({
      operator: yup
        .string()
        .requiredWhen(yup.ref('value'), 'Data')
        .transform((value) => (value !== '' ? value : null))
        .nullable()
        .oneOf([null, ...oneOfOpearators])
        .label('Operador'),
      value: yup
        .date()
        .nullable()
        .requiredWhen(yup.ref('operator'), 'Operador')
        .when('operator', {
          is: 'between',
          then: (schema) => schema.label('De'),
          otherwise: (schema) => schema.label('Data'),
        }),
      value_end: yup
        .date()
        .nullable()
        .when('operator', {
          is: 'between',
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.optional(),
        })
        .label('Até'),
    })
    .label('Data de Nascimento'),
  enterprise_name: yup.string().label('Razão'),
  enterprise_document: yup.string().label('CNPJ'),
});

export default ClientFilterSchema;
