import React from 'react';
import { MessageBox } from 'react-chat-elements';

import { formatDistanceToNow } from 'date-fns';
import { MessageProps } from 'src/pages/Private/Omnichannel/Chat/ChatContent/Message';
import OminichannelUtils from 'src/utils/omnichannel';

const Document: React.FC<MessageProps> = ({ message, onReply, reply }) => {
  if (!message.whatsapp) return null;

  function downloadDocument() {
    if (!message.whatsapp?.document?.documentUrl) {
      return;
    }
    const link = document.createElement('a');
    link.download = message.whatsapp.document.title || 'Sem Nome';
    link.href = message.whatsapp.document.documentUrl;
    link.click();
  }

  return (
    <MessageBox
      id={message.whatsapp.messageId}
      notch
      removeButton={false}
      reply={reply}
      replyButton={!!onReply}
      onReplyClick={onReply}
      onDownload={downloadDocument}
      retracted={false}
      status={OminichannelUtils.getChatMessageStatus(message)}
      position={message.direction === 'outgoing' ? 'right' : 'left'}
      type="file"
      data={{
        uri: message.whatsapp.document?.documentUrl,
        name: message.whatsapp.document?.fileName,
        status: {
          loading: 0,
          click: false,
        },
      }}
      title={''}
      titleColor=""
      text={message.whatsapp.document?.title || ''}
      date={new Date(message.sended_at)}
      dateString={formatDistanceToNow(new Date(message.sended_at), {
        addSuffix: true,
        includeSeconds: true,
      })}
      focus={false}
      forwarded={message.whatsapp.forwarded}
    />
  );
};

export default Document;
