import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import { CardHeader } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import InputFile from 'src/components/Form/InputFile';
import Select from 'src/components/Form/Select';
import TextField from 'src/components/Form/TextField';
import { ISaveUser } from 'src/interfaces/forms/ISaveUser';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IPermission, IRole, IUser } from 'src/interfaces/models';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  UserStoreSchema,
  UserUpdateSchema,
} from 'src/validators/User/save.schema';

import { Container, PermissionGroupDivider } from './styles';

const Save: FC = () => {
  const loggedUser = useSelector((state) => state.auth.user);
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [permissionGroups, setPermissionGroups] = useState<
    Record<string, IPermission[]>
  >({});
  const [roles, setRoles] = useState<IRole[]>([]);
  const [userStatus, setUserStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const userSchema = params.id ? UserUpdateSchema : UserStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const permissionsResponse = await apiAdminPublic.getPermissions();

        const groups: Record<string, IPermission[]> = {};
        permissionsResponse.data.forEach((permission) => {
          const currentGroup = permission.group || 'Outros';
          if (!groups[currentGroup]) {
            groups[currentGroup] = [permission];
          } else {
            groups[currentGroup].push(permission);
          }
        });
        setPermissionGroups(groups);

        const rolesResponse = await apiAdminPublic.getRoles();
        setRoles(rolesResponse.data);

        if (params.id) {
          const userResponse = await api.get<IUser>(`admin/users/${params.id}`);
          const { permissions, ...user } = userResponse.data;
          const userPermissions: boolean[] = [];
          permissions?.forEach((perm) => {
            userPermissions[perm.id] = true;
          });

          setUserStatus(user.status);

          formRef.current?.setData({
            ...user,
            permissions: userPermissions,
          });
        } else {
          formRef.current?.setData({
            change_password: true,
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler<ISaveUser> = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(userSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const permissions: number[] = [];
      formData.permissions.forEach((selected, index) => {
        if (selected) permissions.push(index);
      });

      data.permissions = permissions;

      if (params.id) {
        await api.put(`/admin/users/${params.id}`, objectToFormData(data));
      } else {
        await api.post('/admin/users', objectToFormData(data));
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', userSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (userStatus === 'active') {
        await api.delete(`/admin/users/${params.id}`);
        toast.success('Usuário desativado!');
      } else {
        await api.post(`/admin/users/${params.id}/restore`);
        toast.success('Usuário restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar usuário.');
    } finally {
      setLoading(false);
    }
  };

  const handleOnChangeRole = useCallback(
    (value: any) => {
      const selectedRole = roles.find((role) => role.id === value);
      const currentData = formRef.current?.getData();
      formRef.current?.setData({
        ...currentData,
        permissions: [],
      });

      if (selectedRole) {
        selectedRole.permissions?.forEach((permission) => {
          formRef.current?.setFieldValue(`permissions[${permission.id}]`, true);
        });
      }
    },
    [roles],
  );

  const handleSelectPermissions = useCallback(
    (checked: boolean, groupKey?: string) => {
      if (groupKey) {
        permissionGroups[groupKey].forEach((permission) => {
          formRef.current?.setFieldValue(
            `permissions[${permission.id}]`,
            checked,
          );
        });
      } else {
        Object.keys(permissionGroups).forEach((permissionGroup) => {
          permissionGroups[permissionGroup].forEach((permission) => {
            formRef.current?.setFieldValue(
              `permissions[${permission.id}]`,
              checked,
            );
          });
        });
      }
    },
    [permissionGroups],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Usuário
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <InputFile
                      name="photo"
                      label="Foto"
                      accept=".jpg,.jpeg,.png,.webp"
                      canDelete
                    />
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="name"
                          label="Nome Completo"
                          required
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="email"
                          label="E-mail"
                          type="email"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          name="login"
                          label="Login"
                          required
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          name="password"
                          label="Senha"
                          required
                          variant="outlined"
                          fullWidth
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          name="password_confirmation"
                          label="Confirmar Senha"
                          required
                          variant="outlined"
                          fullWidth
                          type="password"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Grid
                          container
                          style={{ height: '100%' }}
                          alignItems="center"
                        >
                          <CheckBox
                            name="change_password"
                            label="Mudar Senha"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Select
                          name="role_id"
                          label="Função"
                          items={roles.map((role) => ({
                            key: role.id,
                            label: role.name,
                            value: role.id,
                          }))}
                          variant="outlined"
                          fullWidth
                          onChange={handleOnChangeRole}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid
                          container
                          style={{ height: '100%' }}
                          alignItems="center"
                        >
                          <CheckBox
                            name="is_admin"
                            label="Administrador"
                            disabled={!loggedUser?.is_admin}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader="Permissões" />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <CheckBox
                      name="all-permissions"
                      label="Selecionar Tudo"
                      color="default"
                      onChange={(e) =>
                        handleSelectPermissions(e.target.checked)
                      }
                    />
                  </Grid>

                  {Object.keys(permissionGroups)
                    .sort()
                    .map((groupKey) => (
                      <Grid item key={groupKey} xs={12} sm={6}>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <CheckBox
                            name={`${groupKey}-all`}
                            size="small"
                            color="default"
                            onChange={(e) =>
                              handleSelectPermissions(
                                e.target.checked,
                                groupKey,
                              )
                            }
                          />
                          <Typography variant="h6">{groupKey}</Typography>
                          <PermissionGroupDivider variant="middle" />
                        </Grid>
                        {permissionGroups[groupKey]
                          .sort((a, b) => a.id - b.id)
                          .map((permission) => (
                            <CheckBox
                              key={permission.id}
                              name={`permissions[${permission.id}]`}
                              label={permission.name}
                              size="small"
                              returnAs="number"
                            />
                          ))}
                      </Grid>
                    ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can behavior="disable" permissions={['destroy-users']}>
                {userStatus === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${userStatus === 'active' ? 'Desativar' : 'Restaurar'} usuário`}
        description="Confirma esta ação?"
        confirmColor={userStatus === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
