import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, DataGridProps, GridColumns } from '@mui/x-data-grid';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
// import Table from 'src/components/Table';
// import { TableProps } from 'src/components/Table/interfaces';
import Link from 'src/components/Link';
import LinkButton from 'src/components/LinkButton';
import { INITIAL_INFO } from 'src/hooks/usePagination/interfaces';
import { Property } from 'src/modules/property/@types/models';
import propertyApi from 'src/modules/property/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

import {
  SearchPropertyDialogProps,
  SearchPropertyDialogRef,
} from './interfaces';

const SearchPropertyDialog: ForwardRefRenderFunction<
  SearchPropertyDialogRef,
  SearchPropertyDialogProps
> = (
  { onClose, onSelectProperty, defaultQueryParams, loading, ...rest },
  ref,
) => {
  const searchFormRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Property[]>([]);
  const [info, setInfo] = useState(INITIAL_INFO);
  const [filters, setFilters] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }));

  const columns: GridColumns<Property> = [
    { field: 'id', headerName: 'ID', sortable: false },
    {
      field: 'propertyType.name',
      headerName: 'Tipo',
      valueGetter({ row }) {
        return row.propertyType?.name;
      },
    },
    {
      field: 'title',
      headerName: 'Título',
      flex: 1,
    },
    {
      field: 'full_address',
      headerName: 'Endereço',
      flex: 1,
    },
    {
      field: ' ',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      minWidth: 120,
      renderCell({ row }) {
        return (
          <>
            <IconButton
              size="medium"
              color="primary"
              onClick={() => onSelectProperty(row)}
              title="Selecionar"
              disabled={loading}
            >
              <CheckIcon fontSize="inherit" />
            </IconButton>
            <Link
              to={`/imovel/identidades/${row.identity_id}/imoveis/${row.id}`}
              target="_blank"
            >
              <IconButton size="medium" title="Editar" disabled={loading}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Link>
          </>
        );
      },
    },
  ];

  const handleClose = useCallback<
    NonNullable<SearchPropertyDialogProps['onClose']>
  >(
    (event, reason) => {
      setOpen(false);

      if (onClose) onClose(event, reason);
    },
    [onClose],
  );

  const loadProperties = useCallback(
    async (page = 1, perPage = 10, filters = {}) => {
      try {
        setSearchLoading(true);

        const {
          data: responseData,
        } = await propertyApi.admin.common.getProperties({
          params: {
            page,
            per_page: perPage,
            ...defaultQueryParams,
            ...filters,
          },
        });

        setInfo(responseData.meta || INITIAL_INFO);
        setData(responseData.data || []);
      } catch (error) {
        handleApiResponseErrors(
          error.response,
          'Ocorreu um erro na busca dos dados.',
        );
      } finally {
        setSearchLoading(false);
      }
    },
    [defaultQueryParams],
  );

  const handlePageChange: NonNullable<DataGridProps['onPageChange']> = async (
    page,
  ) => {
    await loadProperties(page + 1, info.per_page, filters);
  };

  const handlePageSizeChange: NonNullable<
    DataGridProps['onPageSizeChange']
  > = async (pageSize) => {
    await loadProperties(info.current_page, pageSize, filters);
  };

  const handleSubmit: SubmitHandler = async (formData) => {
    setFilters(formData);

    await loadProperties(1, 10, formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" {...rest}>
      <DialogTitle>Procurar Imóvel</DialogTitle>

      <Form ref={searchFormRef} onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                name="search"
                placeholder="Título / Endereço"
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm display="flex" alignItems="center" gap={0.5}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SearchIcon fontSize="inherit" />}
                fullWidth
                onClick={() => searchFormRef.current?.submitForm()}
              >
                Pesquisar
              </Button>

              <LinkButton
                to="/imovel/identidades/novo"
                target="_blank"
                startIcon={<AddIcon fontSize="inherit" />}
                variant="contained"
                color="primary"
                fullWidth
              >
                Novo
              </LinkButton>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                autoHeight
                components={{ Toolbar: undefined }}
                disableSelectionOnClick
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={searchLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                startIcon={<CloseIcon fontSize="inherit" />}
                variant="text"
                color="primary"
                onClick={() => handleClose({}, 'escapeKeyDown')}
              >
                Fechar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export default forwardRef(SearchPropertyDialog);
