import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { IdentityOwner } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getIdentityOwners(
  identityId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<IdentityOwner>>(
    `/property/admin/identities/${identityId}/owners`,
    config,
  );

  return response;
}

function useIdentityOwners(
  identityId: string | number,
  data?: IApiUseQueryData<IdentityOwner>,
) {
  return useQuery<IApiUseQueryFnData<IdentityOwner>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.IDENTITIES_OWNERS,
      identityId,
      data?.requestConfig?.params,
    ],
    () => getIdentityOwners(identityId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateIdentityOwnersQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.IDENTITIES_OWNERS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.IDENTITIES_OWNERS]);
}

async function getIdentityOwner(
  identityId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IdentityOwner>(
    `/property/admin/identities/${identityId}/owners/${id}`,
    config,
  );

  return response;
}

async function updateIdentityOwners(
  identityId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<IdentityOwner[]>(
    `/property/admin/identities/${identityId}/owners`,
    data,
    config,
  );

  invalidateIdentityOwnersQueries();

  return response;
}

export const owners = {
  getIdentityOwners,
  useIdentityOwners,
  getIdentityOwner,
  updateIdentityOwners,
};
