import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { SubmitHandler, FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePicker from 'src/components/Form/DatePicker';
import InputPhoneNumber from 'src/components/Form/InputPhoneNumber';
import RadioGroup from 'src/components/Form/RadioGroup';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { ICivilStatusType, IClient } from 'src/interfaces/models/IClient';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import apiAdmin from 'src/services/api/admin';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { hasPermission } from 'src/utils/helpers';
import masks from 'src/utils/masks';
import yupValidate from 'src/utils/yupValidate';
import {
  ClientStoreSchema,
  ClientUpdateSchema,
} from 'src/validators/Client/save.schema';

const Save: FC = () => {
  // const loggedUser = useSelector((state) => state.auth.user);
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const bondDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [civilType, setCivilTypes] = useState<ICivilStatusType[]>([]);
  const [clientEnterprises, setClientEnterprises] = useState<IClient[]>([]);
  const [client, setClient] = useState<IClient | null>(null);
  const [clientToBond, setClientToBond] = useState<IClient | null>(null);
  const [clientType, setClientType] = useState('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? ClientUpdateSchema : ClientStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const getCivilTypes = apiAdminPublic.getCivilStatusTypes();
        const getClientEnterprises = apiAdminPublic.getClients({
          params: { type: 'J' },
        });
        const [civilTypesResp, enterprisesResp] = await Promise.all([
          getCivilTypes,
          getClientEnterprises,
        ]);

        setCivilTypes(civilTypesResp.data);
        setClientEnterprises(enterprisesResp.data);

        if (params.id) {
          const response = await api.get<IClient>(`admin/clients/${params.id}`);

          setClient(response.data);

          formRef.current?.setData(response.data);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    const currentData = formRef.current?.getData();
    if (clientType === 'F') {
      formRef.current?.setData({
        ...currentData,
        person: {
          nacionality: 'Brasileira',
          ...client?.person,
        },
      });
    } else if (clientType === 'J') {
      formRef.current?.setData({
        ...currentData,
        enterprise: client?.enterprise,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientType]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/clients/${params.id}`, data);
      } else {
        await api.post('/admin/clients', data);
      }

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (client?.status === 'active') {
        await api.delete(`/admin/clients/${params.id}`);
        toast.success('Cliente desativado!');
      } else {
        await api.post(`/admin/clients/${params.id}/restore`);
        toast.success('Cliente restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar cliente.');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckDocument = async () => {
    try {
      startLayoutLoading();

      const params: any = {};
      if (clientType === 'F') {
        const document = formRef.current?.getFieldValue('person.document');
        params.person_document = document;
      } else {
        const document = formRef.current?.getFieldValue('enterprise.document');
        params.enterprise_document = document;
      }

      const { data } = await apiAdminPublic.getClients({ params });

      if (data.length) {
        if (hasPermission(['bond-clients']) && client) {
          setClientToBond(data[0]);
          bondDialogRef.current?.show();
        } else {
          toast.error('Documento já está em uso!');
        }
      } else {
        toast.success('Documento liberado para cadastro!');
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro buscar documento.');
    } finally {
      stopLayoutLoading();
    }
  };

  const handleBond = async () => {
    try {
      setLoading(true);

      if (clientToBond && client) {
        await apiAdmin.storeClientBond(clientToBond.id, client.id);
        history.goBack();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao vincular cliente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={2}>
        {/* {params.id && (
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <LinkButton
              to={{
                pathname: '/omnichannel',
                search: `client_id=${params.id}`,
              }}
              startIcon={<MessageIcon />}
              variant="outlined"
              // color="primary"
            >
              Conversar
            </LinkButton>
          </Grid>
        )} */}

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                  <TextField name="username" label="Nome de Usuário" />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField name="email" label="E-mail" type="email" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputPhoneNumber name="phone" label="Telefone" required />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    name="type"
                    label="Tipo"
                    row
                    options={[
                      { key: 1, label: 'Contato', value: 'L' },
                      { key: 2, label: 'Pessoa Física', value: 'F' },
                      { key: 3, label: 'Pessoa Jurídica', value: 'J' },
                    ]}
                    onChange={setClientType}
                    disabled={!!(client && client.type !== 'L')}
                  />
                </Grid>

                {clientType === 'F' && (
                  <Scope path="person">
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="document"
                        label="CPF"
                        mask="cpf"
                        returnUnmasked
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleCheckDocument}
                                size="large"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField name="register_code" label="RG" required />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <DatePicker
                        name="birth_date"
                        label="Data de Nascimento"
                        inputProps={{ required: true }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name="name" label="Nome Completo" required />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        name="civil_status_type_id"
                        label="Estado Civil"
                        options={civilType.map((type) => ({
                          key: type.id,
                          label: type.name,
                          value: type.id,
                        }))}
                        textFieldProps={{ required: true }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Autocomplete
                        name="employer_id"
                        label="Empresa que Trabalha"
                        options={clientEnterprises.map((client) => ({
                          key: client.id,
                          label: `${
                            client.enterprise?.company_name
                          } (${masks.cnpj(client.enterprise?.document || '')})`,
                          value: client.id,
                        }))}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField name="profession" label="Profissão" />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField name="nacionality" label="Nacionalidade" />
                    </Grid>
                  </Scope>
                )}

                {clientType === 'J' && (
                  <Scope path="enterprise">
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="document"
                        label="CNPJ"
                        mask="cnpj"
                        returnUnmasked
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="state_registry"
                        label="Inscrição Estadual"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="municipal_registry"
                        label="Inscrição Municipal"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        name="company_name"
                        label="Razão Social"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField name="fantasy_name" label="Nome Fantasia" />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField name="contact" label="Contato na Empresa" />
                    </Grid>
                  </Scope>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Can behavior="disable" permissions={['destroy-clients']}>
              {client?.status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="error"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}
            </Can>

            <Button
              type="submit"
              loading={loading}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${
          client?.status === 'active' ? 'Desativar' : 'Restaurar'
        } cliente`}
        description="Confirma esta ação?"
        confirmColor={client?.status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
        loading={loading}
      />

      <ConfirmDialog
        ref={bondDialogRef}
        title="Documento já cadastrado!"
        description={`Deseja vincular este contato ao cliente ${
          clientToBond?.id
        } - ${
          clientToBond?.person?.name || clientToBond?.enterprise?.company_name
        }?`}
        confirmColor="error"
        onConfirm={handleBond}
        loading={loading}
      />
    </Form>
  );
};

export default Save;
