import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { PropertyKey } from 'src/modules/property/@types/models';
import { SaveIdentityPropertyKeyParams } from 'src/modules/property/@types/params';
import { KEY_STATUSES } from 'src/modules/property/constants';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  PropertyKeyStoreSchema,
  PropertyKeyUpdateSchema,
} from 'src/modules/property/validators/PropertyKey/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [keyStatus, setKeyStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<SaveIdentityPropertyKeyParams>();
  const { url } = useRouteMatch();

  const keySchema = params.keyId
    ? PropertyKeyUpdateSchema
    : PropertyKeyStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.keyId) {
        const {
          data: key,
        } = await propertyApi.admin.properties.keys.getPropertyKey(
          params.propertyId,
          params.keyId,
        );
        setKeyStatus(key.status);

        setTimeout(() => {
          formRef.current?.setData(key);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.propertyId, params.keyId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<PropertyKey> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(keySchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newKey: PropertyKey;
      if (params.keyId) {
        const response = await propertyApi.admin.properties.keys.updatePropertyKey(
          params.propertyId,
          params.keyId,
          data,
        );
        newKey = response.data;
      } else {
        const response = await propertyApi.admin.properties.keys.storePropertyKey(
          params.propertyId,
          data,
        );
        newKey = response.data;
      }

      toast.success('Chave salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.keyId) {
        history.replace(`${url}/${newKey.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', keySchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async (isDeleting) => {
    try {
      setLoading(true);

      if (params.keyId) {
        let deleteConfig: AxiosRequestConfig = {};
        if (isDeleting) {
          deleteConfig = { data: { delete: true } };
        }

        await propertyApi.admin.properties.keys.destroyPropertyKey(
          params.propertyId,
          params.keyId,
          deleteConfig,
        );

        toast.success('Chave desativada!');

        if (isDeleting) return history.goBack();

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Chave');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      if (params.keyId) {
        await propertyApi.admin.properties.keys.restorePropertyKey(
          params.propertyId,
          params.keyId,
        );

        toast.success('Chave restaurada!');

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Chave.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [PROPERTY_PERMISSIONS.PROPERTIES.STORE],
        update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
        destroy: [PROPERTY_PERMISSIONS.PROPERTIES.DESTROY],
        restore: [PROPERTY_PERMISSIONS.PROPERTIES.RESTORE],
      }}
      resourceName="Chave"
      onSave={handleSave}
      onDestroy={handleDestroy}
      canDelete
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.keyId}
      isActive={keyStatus === KEY_STATUSES.AVAILABLE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.keyId ? 'Editar' : 'Nova'} Chave
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField name="description" label="Descrição" required />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
