import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Realtor } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getRealtors(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Realtor>>(
    '/property/admin/realtors',
    config,
  );

  return response;
}

export function useRealtorsQuery(data?: IApiUseQueryData<Realtor>) {
  return useQuery<IApiUseQueryFnData<Realtor>>(
    [PROPERTY_ADMIN_QUERY_KEYS.REALTORS, data?.requestConfig?.params],
    () => getRealtors(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateRealtorsQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.REALTORS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.REALTORS]);
}

export async function storeRealtor(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Realtor>(
    '/property/admin/realtors',
    data,
    config,
  );

  invalidateRealtorsQueries();

  return response;
}

export async function getRealtor(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Realtor>(
    `/property/admin/realtors/${id}`,
    config,
  );

  return response;
}

export async function updateRealtor(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Realtor>(
    `/property/admin/realtors/${id}`,
    data,
    config,
  );

  invalidateRealtorsQueries();

  return response;
}

export async function destroyRealtor(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/realtors/${id}`,
    config,
  );

  invalidateRealtorsQueries();

  return response;
}

export async function restoreRealtor(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/realtors/${id}/restore`,
    config,
  );

  invalidateRealtorsQueries();

  return response;
}
