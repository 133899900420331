import styled, { css } from 'styled-components';

import { Card } from '@mui/material';
import { Opportunity } from 'src/modules/attendance/@types/models';

type ContainerProps = {
  opportunity: Opportunity;
  selected?: boolean;
};

export const Container = styled(Card)<ContainerProps>`
  position: relative;
  ${({ opportunity, theme, selected }) => {
    let backgroundColor = theme.palette.background.default;
    let color = theme.palette.text.primary;
    if (opportunity.status === 'gain') {
      backgroundColor = theme.palette.success.light;
      color = theme.palette.success.contrastText;
    } else if (opportunity.status === 'lost') {
      backgroundColor = theme.palette.error.light;
      color = theme.palette.error.contrastText;
    } else if (opportunity.status === 'transferred') {
      backgroundColor = theme.palette.background.default;
      color = theme.palette.text.disabled;
    } else if (opportunity.is_waiting) {
      backgroundColor = theme.palette.warning.light;
      color = theme.palette.warning.contrastText;
    }

    return css`
      background-color: ${backgroundColor};
      color: ${color};

      ${selected &&
      css`
        border: 1px solid ${theme.palette.action.active};
      `}
    `;
  }};

  .MuiTypography-root {
    line-break: anywhere;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: inherit;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
`;
