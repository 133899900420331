import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import { IOption } from 'src/components/Form/Autocomplete/interfaces';
import DatePicker from 'src/components/Form/DatePicker';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IBank, IBankAccount } from 'src/interfaces/models/IBank';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  BankAccountStoreSchema,
  BankAccountUpdateSchema,
} from 'src/validators/BankAccount/save.schema';

const pixTypeOptions: IOption[] = [
  { key: 1, label: 'CPF / CNPJ', value: 'document' },
  { key: 2, label: 'E-mail', value: 'email' },
  { key: 3, label: 'Celular', value: 'cellphone' },
  { key: 4, label: 'Aleatória', value: 'random' },
];

const paymentTypeOptions: IOption[] = [
  { key: 1, label: 'Dinheiro', value: 'money' },
  { key: 2, label: 'Depósito na Conta', value: 'deposit' },
  { key: 3, label: 'Cheque', value: 'bank_check' },
];

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState<IBank[]>([]);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? BankAccountUpdateSchema
    : BankAccountStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const response = await api.get<IBank[]>('/admin/banks');

        setBanks(response.data);

        if (params.id) {
          const response = await api.get<IBankAccount>(
            `admin/bank-accounts/${params.id}`,
          );
          const account = response.data;

          setStatus(account.status);

          formRef.current?.setData(account);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/bank-accounts/${params.id}`, data);
      } else {
        await api.post('/admin/bank-accounts', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/bank-accounts/${params.id}`);
        toast.success('Conta Bancária desativado!');
      } else {
        await api.post(`/admin/bank-accounts/${params.id}/restore`);
        toast.success('Conta Bancária restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar conta bancária.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    name="bank_id"
                    label="Banco"
                    options={banks.map((bank) => ({
                      key: bank.id,
                      label: bank.name,
                      value: bank.id,
                    }))}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="agency"
                    label="Agência"
                    type="number"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField name="agency_digit" label="Dígito" />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField name="account" label="Nº Conta" required />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="account_type"
                    label="Tipo da Conta"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="holder_name"
                    label="Nome do Titular"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    name="document"
                    label="CPF / CNPJ"
                    mask="cpfOrCnpj"
                    returnUnmasked
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <DatePicker name="birth_date" label="Data de Nascimento" />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    name="pix_key_type"
                    label="Tipo da Chave Pix"
                    options={pixTypeOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField name="pix_key" label="Chave Pix" />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    name="payment_type"
                    label="Forma de Pagamento"
                    options={paymentTypeOptions}
                    textFieldProps={{ required: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    name="payment_day"
                    label="Dia de Pagamento"
                    type="number"
                    inputProps={{ min: 1, max: 31 }}
                    required
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Can permissions={['destroy-bank-accounts']}>
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="error"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}
            </Can>

            <Button
              type="submit"
              loading={loading}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${
          status === 'active' ? 'Desativar' : 'Restaurar'
        } conta bancária`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Form>
  );
};

export default Save;
