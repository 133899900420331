import React from 'react';

import { Grid } from '@mui/material';
import { Scope } from '@unform/core';
import Button from 'src/components/Button';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { InputPropertyMediasField } from 'src/modules/property/components/Form/InputPropertyMedias';

type SourceExternalLinkProps = {
  name: string;
  onAdd: () => void;
  disabled?: boolean;
  fields: InputPropertyMediasField[];
  removeField: (field: InputPropertyMediasField) => void;
};

const SourceExternalLink: React.FC<SourceExternalLinkProps> = ({
  name,
  onAdd,
  disabled,
  fields,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Button variant="contained" disabled={disabled} onClick={onAdd}>
          Adicionar
        </Button>
      </Grid>

      {fields.map((field, index) => (
        <Scope key={field.id} path={`${name}[${index}]`}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  name="value"
                  label="URL"
                  placeholder="https://example.com.br/vídeo.mp4"
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField name="name" label="Título" required />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <CheckBox name="show_in_website" label="Mostrar no Site" />
              </Grid>
            </Grid>
          </Grid>
        </Scope>
      ))}
    </>
  );
};

export default SourceExternalLink;
