import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import { IEditPropertyTypeDetailParams } from 'src/interfaces/IEditParams';
import { DetailProperty } from 'src/modules/property/@types/models';
import AutocompleteDetailsProperties from 'src/modules/property/components/Form/_common/AutocompleteDetailsProperties';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  PropertyTypeDetailStoreSchema,
  PropertyTypeDetailUpdateSchema,
} from 'src/validators/PropertyTypeDetail/save.schema';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams<IEditPropertyTypeDetailParams>();

  const propertyTypeDetailSchema = params.propertyTypeDetailId
    ? PropertyTypeDetailUpdateSchema
    : PropertyTypeDetailStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.propertyTypeDetailId) {
        const { data } = await api.get<DetailProperty>(
          `admin/property-types/${params.id}/details/${params.propertyTypeDetailId}`,
        );
        setTimeout(() => {
          formRef.current?.setData({
            detail_property_id: data.id,
            is_required: data.is_required,
          });
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [
    params.id,
    params.propertyTypeDetailId,
    startLayoutLoading,
    stopLayoutLoading,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<DetailProperty> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        propertyTypeDetailSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newDetail: DetailProperty;
      if (params.propertyTypeDetailId) {
        const response = await api.put<DetailProperty>(
          `admin/property-types/${params.id}/details/${params.propertyTypeDetailId}`,
          data,
        );
        newDetail = response.data;
      } else {
        const response = await api.post<DetailProperty>(
          `admin/property-types/${params.id}/details`,
          data,
        );
        newDetail = response.data;
      }

      toast.success('Detalhe salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.propertyTypeDetailId) {
        history.replace(`/tipos-imovel/${params.id}/detalhes/${newDetail.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        propertyTypeDetailSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await api.delete(
        `admin/property-types/${params.id}/details/${params.propertyTypeDetailId}`,
      );

      toast.success('Detalhe desativado!');

      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Detalhe');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: ['store-property-types'],
        update: ['update-property-types'],
        destroy: ['destroy-property-types'],
        restore: ['destroy-property-types'],
      }}
      resourceName="Detalhe do Tipo de Imóvel"
      onSave={handleSave}
      onDestroy={handleDestroy}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.propertyTypeDetailId}
      isActive={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteDetailsProperties
                    name="detail_property_id"
                    label="Detalhe"
                    textFieldProps={{ required: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} alignSelf="center">
                  <CheckBox name="is_required" label="Obrigatório" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
