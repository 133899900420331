import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import { ButtonProps } from 'src/components/Button/interfaces';
import Can from 'src/components/Can';
import Autocomplete from 'src/components/Form/Autocomplete';
import { INeighborhood } from 'src/interfaces/models/IAddress';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

// import { Container } from './styles';

const ReviewNeighborhoodDialog: FC<ReviewNeighborhoodDialogProps> = ({
  neighborhood,
  buttonProps,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('bond');
  const [loading, setLoading] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState<INeighborhood[]>([]);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const response = await api.get<INeighborhood[]>('admin/neighborhoods', {
          params: { status: 'active' },
        });
        setNeighborhoods(response.data);
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (open && neighborhoods.length && neighborhood) {
      setTimeout(() => {
        formRef.current?.setFieldValue('bond_id', neighborhood.bond_id);
      });
    }
  }, [open, neighborhoods, neighborhood]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      await api.post(`/admin/neighborhoods/${neighborhood?.id}/review`, {
        ...formData,
        action,
      });

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao salvar revisão');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Can permissions={['review-neighborhoods']} behavior="hide">
        <Button
          type="button"
          size="small"
          startIcon={<VisibilityIcon />}
          color="primary"
          variant="outlined"
          onClick={() => setOpen(true)}
          {...buttonProps}
        >
          Revisar
        </Button>
      </Can>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        {...rest}
      >
        <DialogTitle>
          Revisar Bairro {`${neighborhood?.id} - ${neighborhood?.name}`}
        </DialogTitle>

        <DialogContent>
          <Form ref={formRef} onSubmit={handleOnSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={action}
                    onChange={(_, value) => {
                      setAction(value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="approve"
                      control={<Radio color="primary" />}
                      label="Aprovar"
                    />
                    <FormControlLabel
                      value="bond"
                      control={<Radio color="error" />}
                      label="Vincular"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {action === 'bond' && (
                <Grid item xs={12}>
                  <Autocomplete
                    name="bond_id"
                    label="Bairro"
                    options={neighborhoods.map((neighborhood) => ({
                      key: neighborhood.id,
                      label: `${neighborhood.id} - ${neighborhood.name}`,
                      value: neighborhood.id,
                    }))}
                    getOptionDisabled={(option) =>
                      option.value === neighborhood?.id
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Form>
        </DialogContent>

        <DialogActions>
          <Button
            startIcon={<CheckIcon />}
            loading={loading}
            color={action === 'bond' ? 'error' : 'primary'}
            variant="contained"
            type="button"
            onClick={() => formRef.current?.submitForm()}
          >
            {action === 'bond' ? 'Vincular' : 'Aprovar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewNeighborhoodDialog;

export interface ReviewNeighborhoodDialogProps
  extends Omit<DialogProps, 'open' | 'onClose'> {
  neighborhood?: INeighborhood;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
}
