import React from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <Grid
        container
        height="inherit"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Grid>
    </Container>
  );
};

export default Loading;
