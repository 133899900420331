import {
  ForwardRefRenderFunction,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { CustomDialogRef, CustomDialogProps } from './interfaces';

const CustomDialog: ForwardRefRenderFunction<
  CustomDialogRef,
  CustomDialogProps
> = ({ title, onClose, children, titleProps, ...rest }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }));

  const handleClose = useCallback<NonNullable<CustomDialogProps['onClose']>>(
    (event, reason) => {
      setOpen(false);

      if (onClose) onClose(event, reason);
    },
    [onClose],
  );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs" {...rest}>
      <DialogTitle {...titleProps}>{title}</DialogTitle>

      {children}
    </Dialog>
  );
};

export default forwardRef(CustomDialog);
