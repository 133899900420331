import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ADMIN_QUERY_KEYS, COMMON_QUERY_KEYS } from 'src/constants/query';
import IMedia from 'src/interfaces/models/IMedia';
import { adminAxios } from 'src/services/api/admin';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getMedias(config?: AxiosRequestConfig) {
  const response = await adminAxios.get<IResponseAPIFilter<IMedia>>(
    '/medias',
    config,
  );

  return response;
}

export function useMediasQuery(data?: IApiUseQueryData<IMedia>) {
  return useQuery<IApiUseQueryFnData<IMedia>>(
    [ADMIN_QUERY_KEYS.MEDIAS, data?.requestConfig?.params],
    () => getMedias(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateMediasQueries() {
  queryClient.invalidateQueries([COMMON_QUERY_KEYS.MEDIAS]);
  queryClient.invalidateQueries([ADMIN_QUERY_KEYS.MEDIAS]);
}

export async function storeMedia(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.post<IMedia>('/medias', data, config);

  invalidateMediasQueries();

  return response;
}

export async function getMedia(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.get<IMedia>(`/medias/${id}`, config);

  return response;
}

export async function updateMedia(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.put<IMedia>(`/medias/${id}`, data, config);

  invalidateMediasQueries();

  return response;
}

export async function destroyMedia(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.delete<boolean>(`/medias/${id}`, config);

  invalidateMediasQueries();

  return response;
}

export async function restoreMedia(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.post<boolean>(
    `/medias/${id}/restore`,
    config,
  );

  invalidateMediasQueries();

  return response;
}
