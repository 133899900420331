import { FC, useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IBank } from 'src/interfaces/models/IBank';
import api from 'src/services/api';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [banks, setBanks] = useState<IBank[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await api.get<IBank[]>('/admin/banks');

        setBanks(response.data);
      } catch (error) {}
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="bank_id"
          label="Banco"
          options={banks.map((bank) => ({
            key: bank.id,
            label: `${bank.id} - ${bank.name}`,
            value: bank.id,
          }))}
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="holder_name" label="Titular" size="small" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
