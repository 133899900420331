import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import AutocompleteDetailsProperties from 'src/modules/property/components/Form/_common/AutocompleteDetailsProperties';

const Filters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteDetailsProperties
          name="detail_property_id"
          label="Tipo"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="is_required"
          label="Obrigatório"
          size="small"
          options={[
            { key: 0, label: 'Sim', value: 1 },
            { key: 0, label: 'Não', value: 0 },
          ]}
        />
      </Grid>
    </>
  );
};

export default Filters;
