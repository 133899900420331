import yup from 'src/libs/yup';

export const CondominiumStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  slug: yup.string().label('Slug'),
  description: yup.string().label('Descrição'),
  syndicator_id: yup.number().nullable().label('Síndico'),
  accountant_id: yup.number().nullable().label('Contador'),
  amount: yup.string().required().label('Preço'),
  zip_code: yup.string().required().label('CEP'),
  city_id: yup.number().required().label('Cidade'),
  neighborhood_id: yup.number().nullable().required().label('Bairro'),
  street_id: yup.number().nullable().required().label('Rua'),
  number: yup.string().label('Número'),
  complement: yup.string().label('Complemento'),
  map_link: yup.string().label('Link do Mapa'),
  latitude: yup.string().label('Latitude'),
  longitude: yup.string().label('Longitude'),
});

export const CondominiumUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  slug: yup.string().label('Slug'),
  description: yup.string().label('Descrição'),
  syndicator_id: yup.number().nullable().label('Síndico'),
  accountant_id: yup.number().nullable().label('Contador'),
  amount: yup.string().required().label('Preço'),
  zip_code: yup.string().required().label('CEP'),
  city_id: yup.number().required().label('Cidade'),
  neighborhood_id: yup.number().nullable().required().label('Bairro'),
  street_id: yup.number().nullable().required().label('Rua'),
  number: yup.string().label('Número'),
  complement: yup.string().emptyAsNull().label('Complemento'),
  map_link: yup.string().emptyAsNull().label('Link do Mapa'),
  latitude: yup.string().emptyAsNull().label('Latitude'),
  longitude: yup.string().emptyAsNull().label('Longitude'),
});
