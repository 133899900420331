import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import {
  Condominium,
  CondominiumDetail,
  CondominiumPeculiarity,
  DetailCondominium,
  DetailProperty,
  EconomicIndicator,
  Identity,
  OfferBroughtVia,
  Property,
  PublicityType,
  Realtor,
} from 'src/modules/property/@types/models';
import { PROPERTY_COMMON_QUERY_KEYS } from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

/** CONDOMINIUMS */
export async function getCondominiums(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Condominium>>(
    '/property/admin/common/condominiums',
    config,
  );

  return response;
}

export function useCondominiumsQuery(data?: IApiUseQueryData<Condominium>) {
  return useQuery<IApiUseQueryFnData<Condominium>>(
    [PROPERTY_COMMON_QUERY_KEYS.CONDOMINIUMS, data?.requestConfig?.params],
    () => getCondominiums(data?.requestConfig),
    data?.queryOptions,
  );
}

/** DETAILS CONDOMINIUMS */
export async function getDetailsCondominiums(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<DetailCondominium>>(
    '/property/admin/common/details-condominiums',
    config,
  );

  return response;
}

export function useDetailsCondominiumsQuery(
  data?: IApiUseQueryData<DetailCondominium>,
) {
  return useQuery<IApiUseQueryFnData<DetailCondominium>>(
    [
      PROPERTY_COMMON_QUERY_KEYS.DETAILS_CONDOMINIUMS,
      data?.requestConfig?.params,
    ],
    () => getDetailsCondominiums(data?.requestConfig),
    data?.queryOptions,
  );
}

/** CONDOMINIUM DETAILS */
export async function getCondominiumDetails(
  condominiumId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<CondominiumDetail>>(
    `/property/admin/common/condominiums/${condominiumId}/details`,
    config,
  );

  return response;
}

export function useCondominiumDetailsQuery(
  condominiumId: number | string,
  data?: IApiUseQueryData<CondominiumDetail>,
) {
  return useQuery<IApiUseQueryFnData<CondominiumDetail>>(
    [
      PROPERTY_COMMON_QUERY_KEYS.CONDOMINIUM_DETAILS,
      condominiumId,
      data?.requestConfig?.params,
    ],
    () => getCondominiumDetails(condominiumId, data?.requestConfig),
    data?.queryOptions,
  );
}

/** CONDOMINIUM PECULIARITIES */
export async function getCondominiumPeculiarities(
  condominiumId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<
    IResponseAPIFilter<CondominiumPeculiarity>
  >(
    `/property/admin/common/condominiums/${condominiumId}/peculiarities`,
    config,
  );

  return response;
}

export function useCondominiumPeculiaritiesQuery(
  condominiumId: number | string,
  data?: IApiUseQueryData<CondominiumPeculiarity>,
) {
  return useQuery<IApiUseQueryFnData<CondominiumPeculiarity>>(
    [
      PROPERTY_COMMON_QUERY_KEYS.CONDOMINIUM_PECULIARITIES,
      condominiumId,
      data?.requestConfig?.params,
    ],
    () => getCondominiumPeculiarities(condominiumId, data?.requestConfig),
    data?.queryOptions,
  );
}

/** DETAILS PROPERTIES */
export async function getDetailsProperties(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<DetailProperty>>(
    '/property/admin/common/details-properties',
    config,
  );

  return response;
}

export function useDetailsPropertiesQuery(
  data?: IApiUseQueryData<DetailProperty>,
) {
  return useQuery<IApiUseQueryFnData<DetailProperty>>(
    [
      PROPERTY_COMMON_QUERY_KEYS.DETAILS_PROPERTIES,
      data?.requestConfig?.params,
    ],
    () => getDetailsProperties(data?.requestConfig),
    data?.queryOptions,
  );
}

/** IDENTITIES */
export async function getIdentities(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Identity>>(
    '/property/admin/common/identities',
    config,
  );

  return response;
}

export function useIdentitiesQuery(data?: IApiUseQueryData<Identity>) {
  return useQuery<IApiUseQueryFnData<Identity>>(
    [PROPERTY_COMMON_QUERY_KEYS.IDENTITIES, data?.requestConfig?.params],
    () => getIdentities(data?.requestConfig),
    data?.queryOptions,
  );
}

/** PUBLICITIES TYPES */
export async function getPublicitiesTypes(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<PublicityType>>(
    '/property/admin/common/publicities-types',
    config,
  );

  return response;
}

export function usePublicitiesTypesQuery(
  data?: IApiUseQueryData<PublicityType>,
) {
  return useQuery<IApiUseQueryFnData<PublicityType>>(
    [PROPERTY_COMMON_QUERY_KEYS.PUBLICITIES_TYPES, data?.requestConfig?.params],
    () => getPublicitiesTypes(data?.requestConfig),
    data?.queryOptions,
  );
}

/** REALTORS */
export async function getRealtors(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Realtor>>(
    '/property/admin/common/realtors',
    config,
  );

  return response;
}

export function useRealtorsQuery(data?: IApiUseQueryData<Realtor>) {
  return useQuery<IApiUseQueryFnData<Realtor>>(
    [PROPERTY_COMMON_QUERY_KEYS.REALTORS, data?.requestConfig?.params],
    () => getRealtors(data?.requestConfig),
    data?.queryOptions,
  );
}

/** ECONOMIC INDICATORS */
export async function getEconomicIndicators(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<EconomicIndicator>>(
    '/property/admin/common/economic-indicators',
    config,
  );

  return response;
}

export function useEconomicIndicatorsQuery(
  data?: IApiUseQueryData<EconomicIndicator>,
) {
  return useQuery<IApiUseQueryFnData<EconomicIndicator>>(
    [
      PROPERTY_COMMON_QUERY_KEYS.ECONOMIC_INDICATORS,
      data?.requestConfig?.params,
    ],
    () => getEconomicIndicators(data?.requestConfig),
    data?.queryOptions,
  );
}

/** OFFER BROUGHT VIAS */
export async function getOfferBroughtVias(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<OfferBroughtVia>>(
    '/property/admin/common/offer-brought-vias',
    config,
  );

  return response;
}

export function useOfferBroughtViasQuery(
  data?: IApiUseQueryData<OfferBroughtVia>,
) {
  return useQuery<IApiUseQueryFnData<OfferBroughtVia>>(
    [
      PROPERTY_COMMON_QUERY_KEYS.OFFER_BROUGHT_VIAS,
      data?.requestConfig?.params,
    ],
    () => getOfferBroughtVias(data?.requestConfig),
    data?.queryOptions,
  );
}

/** PROPERTIES */
export async function getProperties(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Property>>(
    '/property/admin/common/properties',
    config,
  );

  return response;
}

export function usePropertiesQuery(data?: IApiUseQueryData<Property>) {
  return useQuery<IApiUseQueryFnData<Property>>(
    [PROPERTY_COMMON_QUERY_KEYS.PROPERTIES, data?.requestConfig?.params],
    () => getProperties(data?.requestConfig),
    data?.queryOptions,
  );
}
