import yup from 'src/libs/yup';

export const DepartmentStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  z_api_instance_id: yup.number().nullable().label('Instância WhatsApp'),
  users: yup.array().of(yup.number()),
});

export const DepartmentUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().emptyAsNull().label('Descrição'),
  z_api_instance_id: yup.number().nullable().label('Instância WhatsApp'),
  users: yup.array().of(yup.number()),
});
