import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ptBR as gridPtBR } from '@mui/x-data-grid';
import { ptBR as datePtBR } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';

export default createTheme(
  {
    palette: { mode: 'light' },
    typography: {
      fontFamily: '"Poppins",sans-serif',
      fontSize: 12,
    },
  },
  gridPtBR,
  datePtBR,
  corePtBR,
);
