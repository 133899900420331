import styled from 'styled-components';

import MuiPaper from '@mui/material/Paper';

export const Container = styled.div`
  display: flex;
  align-items: start;
  gap: 12px;
  overflow: auto;
`;

export const Column = styled.div`
  min-width: 250px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const ColumnHeader = styled(MuiPaper)`
  background-color: ${({ theme }) =>
    theme.palette.mode === 'dark'
      ? theme.palette.grey[800]
      : theme.palette.grey[200]};
`;

export const ColumnBody = styled(MuiPaper)`
  flex: 1;
  display: grid;
  align-content: flex-start;
  gap: 12px;
  background-color: ${({ theme }) =>
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.grey[50]};
`;
