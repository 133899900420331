import yup from 'src/libs/yup';

export const OpportunityUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
});

export const OpportunityStatusSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  wait_until: yup.date().nullable().label('Em espera até'),
  amount: yup.string().nullable().label('Valor Ganho'),
  reason_id: yup.number().nullable().label('Motivo da Perda'),
});

export const OpportunityTransferSchema = yup.object().shape({
  attendant_id: yup
    .number()
    .nullable()
    .required()
    .label('Atendente Responsável'),
});

export const OpportunityAttendantsSchema = yup.object().shape({
  attendants: yup.array().of(yup.number()).nullable().label('Atendentes'),
});

export const InterestProductFormSchema = yup.object().shape({
  id: yup.number().required().label('ID'),
  reference_code: yup.string().label('Código'),
  name: yup.string().required().label('Nome'),
  type: yup.string().label('Tipo'),
  price: yup.string().label('Preço'),
  quantity: yup.string().default('1').label('Qtde'),
});

export const InterestAttendanceFormSchema = yup.object().shape({
  description: yup.string().required().label('Descrição'),
  amount: yup.string().required().label('Valor'),
  funnel_step_id: yup.number().nullable().required().label('Etapa'),
});
