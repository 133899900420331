import yup from 'src/libs/yup';

export const IdentityOwnersUpdateSchema = yup.object().shape({
  owners: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        owner_id: yup.number().required().label('Proprietário'),
        percentage: yup.string().required().label('Participação'),
      }),
    ),
});
