import { IRouteInterface } from 'src/interfaces/IRoutes';
import { AttendanceRouteList } from 'src/modules/attendance/routes/list';
import { PropertyRouteList } from 'src/modules/property/routes/list';

import AuthPages from '../pages/Auth';
import PrivatePages from '../pages/Private';
import PublicPages from '../pages/Public';

export const authRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: AuthPages.Login,
    label: 'Login',
    path: '/',
    type: 'auth',
  },
  {
    component: AuthPages.ForgotPassword,
    exact: true,
    label: 'Esqueci minha senha',
    path: '/esqueci-minha-senha',
    type: 'auth',
  },
  {
    component: AuthPages.RecoverPassword,
    label: 'Recuperar senha',
    path: '/recuperar-senha/:token',
    type: 'auth',
  },
];

export const privateRoutes: IRouteInterface[] = [
  {
    exact: true,
    component: PrivatePages.Dashboard,
    label: 'Início',
    path: '/dashboard',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Profile,
    label: 'Perfil',
    path: '/perfil',
    type: 'private',
  },
  {
    exact: true,
    component: PrivatePages.Users.List,
    label: 'Usuários',
    path: '/usuarios',
    type: 'private',
    permissions: ['list-users'],
  },
  {
    component: PrivatePages.Users.Save,
    label: 'Novo Usuário',
    path: '/usuarios/novo',
    type: 'private',
    permissions: ['store-users'],
  },
  {
    component: PrivatePages.Users.Save,
    label: 'Editar Usuário',
    path: '/usuarios/:id',
    type: 'private',
    permissions: ['update-users'],
  },
  {
    exact: true,
    component: PrivatePages.Roles.List,
    label: 'Funções',
    path: '/funcoes',
    type: 'private',
    permissions: ['list-roles'],
  },
  {
    component: PrivatePages.Roles.Save,
    label: 'Nova Função',
    path: '/funcoes/novo',
    type: 'private',
    permissions: ['store-roles'],
  },
  {
    component: PrivatePages.Roles.Save,
    label: 'Editar Função',
    path: '/funcoes/:id',
    type: 'private',
    permissions: ['update-roles'],
  },
  {
    component: PrivatePages.Addresses,
    label: 'Endereços',
    path: '/enderecos/:selectedTab?',
    type: 'private',
    permissions: [
      'list-addresses',
      'list-countries',
      'list-states',
      'list-cities',
      'list-neighborhoods',
      'list-streets',
    ],
  },
  {
    exact: true,
    component: PrivatePages.CivilStatusTypes.List,
    label: 'Estados Civis',
    path: '/estados-civis',
    type: 'private',
    permissions: ['list-civil-status-types'],
  },
  {
    component: PrivatePages.CivilStatusTypes.Save,
    label: 'Novo Estado Civil',
    path: '/estados-civis/novo',
    type: 'private',
    permissions: ['store-civil-status-types'],
  },
  {
    component: PrivatePages.CivilStatusTypes.Save,
    label: 'Editar Estado Civil',
    path: '/estados-civis/:id',
    type: 'private',
    permissions: ['update-civil-status-types'],
  },
  {
    exact: true,
    component: PrivatePages.Clients.List,
    label: 'Clientes',
    path: '/clientes',
    type: 'private',
    permissions: ['list-clients'],
  },
  {
    component: PrivatePages.Clients.Save,
    label: 'Novo Cliente',
    path: '/clientes/novo',
    type: 'private',
    permissions: ['store-clients'],
  },
  {
    component: PrivatePages.Clients.Save,
    label: 'Editar Cliente',
    path: '/clientes/:id/:selectedTab?',
    type: 'private',
    permissions: ['update-clients'],
  },
  {
    exact: true,
    component: PrivatePages.DocumentTypes.List,
    label: 'Documentos',
    path: '/documentos',
    type: 'private',
    permissions: ['list-document-types'],
  },
  {
    component: PrivatePages.DocumentTypes.Save,
    label: 'Novo Documento',
    path: '/documentos/novo',
    type: 'private',
    permissions: ['store-document-types'],
  },
  {
    component: PrivatePages.DocumentTypes.Save,
    label: 'Editar Documento',
    path: '/documentos/:id',
    type: 'private',
    permissions: ['update-document-types'],
  },
  {
    exact: true,
    component: PrivatePages.ContactTypes.List,
    label: 'Contatos',
    path: '/contatos',
    type: 'private',
    permissions: ['list-contact-types'],
  },
  {
    component: PrivatePages.ContactTypes.Save,
    label: 'Novo Contato',
    path: '/contatos/novo',
    type: 'private',
    permissions: ['store-contact-types'],
  },
  {
    component: PrivatePages.ContactTypes.Save,
    label: 'Editar Contato',
    path: '/contatos/:id',
    type: 'private',
    permissions: ['update-contact-types'],
  },
  {
    exact: true,
    component: PrivatePages.Banks.List,
    label: 'Bancos',
    path: '/bancos',
    type: 'private',
    permissions: ['list-banks'],
  },
  {
    component: PrivatePages.Banks.Save,
    label: 'Novo Banco',
    path: '/bancos/novo',
    type: 'private',
    permissions: ['store-banks'],
  },
  {
    component: PrivatePages.Banks.Save,
    label: 'Editar Banco',
    path: '/bancos/:id',
    type: 'private',
    permissions: ['update-banks'],
  },
  {
    exact: true,
    component: PrivatePages.BankAccounts.List,
    label: 'Correntistas',
    path: '/correntistas',
    type: 'private',
    permissions: ['list-bank-accounts'],
  },
  {
    component: PrivatePages.BankAccounts.Save,
    label: 'Novo Correntista',
    path: '/correntistas/novo',
    type: 'private',
    permissions: ['store-bank-accounts'],
  },
  {
    component: PrivatePages.BankAccounts.Save,
    label: 'Editar Correntista',
    path: '/correntistas/:id/:selectedTab?',
    type: 'private',
    permissions: ['update-bank-accounts'],
  },
  {
    exact: true,
    component: PrivatePages.AddressComplements.List,
    label: 'Complementos',
    path: '/complementos',
    type: 'private',
    permissions: ['list-address-complements'],
  },
  {
    component: PrivatePages.AddressComplements.Save,
    label: 'Novo Complemento',
    path: '/complementos/novo',
    type: 'private',
    permissions: ['store-address-complements'],
  },
  {
    component: PrivatePages.AddressComplements.Save,
    label: 'Editar Complemento',
    path: '/complementos/:id/:selectedTab?',
    type: 'private',
    permissions: ['update-address-complements'],
  },
  {
    exact: true,
    component: PrivatePages.PropertyTypes.List,
    label: 'Tipos de Imóvel',
    path: '/tipos-imovel',
    type: 'private',
    permissions: ['list-property-types'],
  },
  {
    component: PrivatePages.PropertyTypes.Save,
    label: 'Novo Tipo de Imóvel',
    path: '/tipos-imovel/novo',
    type: 'private',
    permissions: ['store-property-types'],
  },
  {
    component: PrivatePages.PropertyTypes.Save,
    label: 'Editar Tipo de Imóvel',
    path: '/tipos-imovel/:id/:selectedTab?',
    type: 'private',
    permissions: ['update-property-types'],
  },
  {
    exact: true,
    component: PrivatePages.Medias.List,
    label: 'Mídia',
    path: '/midias',
    type: 'private',
    permissions: ['list-medias'],
  },
  {
    component: PrivatePages.Medias.Save,
    label: 'Nova Mídia',
    path: '/midias/novo',
    type: 'private',
    permissions: ['store-medias'],
  },
  {
    component: PrivatePages.Medias.Save,
    label: 'Editar Mídia',
    path: '/midias/:id',
    type: 'private',
    permissions: ['update-medias'],
  },
  {
    component: PrivatePages.Omnichannel,
    label: 'WhatsApp',
    path: '/omnichannel/:departmentId',
    type: 'private',
    permissions: ['list-omnichannel'],
  },
  {
    exact: true,
    component: PrivatePages.ZApiInstances.List,
    label: 'Instâncias WhatsApp',
    path: '/instancias-whatsapp',
    type: 'private',
    permissions: ['list-z-api-instances'],
  },
  {
    component: PrivatePages.ZApiInstances.Save,
    label: 'Nova Instância WhatsApp',
    path: '/instancias-whatsapp/novo',
    type: 'private',
    permissions: ['store-z-api-instances'],
  },
  {
    component: PrivatePages.ZApiInstances.Save,
    label: 'Editar Instância WhatsApp',
    path: '/instancias-whatsapp/:id',
    type: 'private',
    permissions: ['update-z-api-instances'],
  },
  {
    exact: true,
    component: PrivatePages.Departments.List,
    label: 'Departamentos',
    path: '/departamentos',
    type: 'private',
    permissions: ['list-departments'],
  },
  {
    component: PrivatePages.Departments.Save,
    label: 'Nova Departamento',
    path: '/departamentos/novo',
    type: 'private',
    permissions: ['store-departments'],
  },
  {
    component: PrivatePages.Departments.Save,
    label: 'Editar Departamento',
    path: '/departamentos/:id',
    type: 'private',
    permissions: ['update-departments'],
  },
];

export const publicRoutes: IRouteInterface[] = [
  {
    component: PublicPages.NotFound,
    label: 'Página não encontrada',
    path: '*',
    type: 'public',
  },
];

export default [
  ...authRoutes,
  ...privateRoutes,
  ...AttendanceRouteList,
  ...PropertyRouteList,
  ...publicRoutes,
];
