import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Property } from 'src/modules/property/@types/models';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import PropertyFilterSchema from 'src/modules/property/validators/Property/filter.schema';

import PropertyFilters from './Filters';

const List: FC = () => {
  const { url } = useRouteMatch();
  const params = useParams<IEditParams>();
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination<Property>(
    (queryConfig) =>
      propertyApi.admin.properties.usePropertiesQuery(queryConfig),
    {
      identity_id: params.id,
    },
  );

  const columns: GridColDef<Property>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'propertyType.name',
      headerName: 'Tipo',
      valueGetter({ row }) {
        return row.propertyType?.name;
      },
    },
    {
      field: 'title',
      headerName: 'Título',
      flex: 1,
    },
    {
      field: 'full_address',
      headerName: 'Endereço',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ row }) {
        return (
          <TableActions
            resourceName="Imóvel"
            resourceId={row.id}
            resourceStatus={row.status}
            editLink={`${url}/${row.id}`}
            editPermissions={[PROPERTY_PERMISSIONS.PROPERTIES.UPDATE]}
            canDelete
            destroyApiEndpoint={(resourceId, config) =>
              propertyApi.admin.properties.destroyProperty(resourceId, config)
            }
            destroyPermissions={[PROPERTY_PERMISSIONS.PROPERTIES.DESTROY]}
            restoreApiEndpoint={(resourceId, config) =>
              propertyApi.admin.properties.restoreProperty(resourceId, config)
            }
            restorePermissions={[PROPERTY_PERMISSIONS.PROPERTIES.RESTORE]}
            descriptionPrefix={`O Imóvel ${row.id}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="end" alignItems="center">
          <Can
            behavior="disable"
            permissions={[PROPERTY_PERMISSIONS.PROPERTIES.STORE]}
          >
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Can>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Table
              autoHeight
              rows={response?.data.data || []}
              pageSize={response?.data.meta.per_page}
              rowCount={response?.data.meta.total}
              columns={columns}
              loading={isLoading}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              canSearch
              showFilters
              filterProps={{
                yupSchema: PropertyFilterSchema,
                children: <PropertyFilters />,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default List;
