import { FC } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BackButton from 'src/components/BackButton';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';

import IndicatorTab from './IndicatorTab';
import { Container } from './styles';
import ValuesTab from './ValuesTab';

const Save: FC = () => {
  const params = useParams<ITabEditParams>();

  const EconomicIndicatorsTabs: NavigationTab[] = [
    { name: 'Indicador', slug: '', Component: IndicatorTab },
    {
      name: 'Valores',
      slug: 'valores',
      Component: ValuesTab,
      disabled: !params.id,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Novo'} Indicador Econômico
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <NavigationTabs
            baseUrl={`/imovel/indicadores-economicos${
              params.id ? `/${params.id}` : ''
            }`}
            tabs={EconomicIndicatorsTabs}
          />
        </Grid>

        <Grid item xs={12}>
          {EconomicIndicatorsTabs.map(({ name, slug, Component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              <Component />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
