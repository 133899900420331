import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ADMIN_QUERY_KEYS, COMMON_QUERY_KEYS } from 'src/constants/query';
import IDepartment from 'src/interfaces/models/IDepartment';
import { adminAxios } from 'src/services/api/admin';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getDepartments(config?: AxiosRequestConfig) {
  const response = await adminAxios.get<IResponseAPIFilter<IDepartment>>(
    '/departments',
    config,
  );

  return response;
}

export function useDepartmentsQuery(data?: IApiUseQueryData<IDepartment>) {
  return useQuery<IApiUseQueryFnData<IDepartment>>(
    [ADMIN_QUERY_KEYS.DEPARTMENTS, data?.requestConfig?.params],
    () => getDepartments(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateDepartmentsQueries() {
  queryClient.invalidateQueries([COMMON_QUERY_KEYS.DEPARTMENTS]);
  queryClient.invalidateQueries([ADMIN_QUERY_KEYS.DEPARTMENTS]);
}

export async function storeDepartment(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.post<IDepartment>(
    '/departments',
    data,
    config,
  );

  invalidateDepartmentsQueries();

  return response;
}

export async function getDepartment(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.get<IDepartment>(
    `/departments/${id}`,
    config,
  );

  return response;
}

export async function updateDepartment(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.put<IDepartment>(
    `/departments/${id}`,
    data,
    config,
  );

  invalidateDepartmentsQueries();

  return response;
}

export async function destroyDepartment(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.delete<boolean>(
    `/departments/${id}`,
    config,
  );

  invalidateDepartmentsQueries();

  return response;
}

export async function restoreDepartment(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.post<boolean>(
    `/departments/${id}/restore`,
    config,
  );

  invalidateDepartmentsQueries();

  return response;
}
