import React from 'react';

import { Grid } from '@mui/material';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';

const SaleFields: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Scope path="sale">
        <Grid item xs={12}>
          <TextField
            name="conditions"
            label="Condições da Venda"
            multiline
            rows={4}
            required
          />
        </Grid>

        <Grid item xs={12} alignSelf="center">
          <CheckBox name="accept_interchange" label="Aceita Permuta" />
        </Grid>

        <Grid item xs={12} alignSelf="center">
          <CheckBox name="accept_property" label="Aceita Imóvel" />
        </Grid>

        <Grid item xs={12} alignSelf="center">
          <CheckBox name="accept_vehicle" label="Aceita Veículo" />
        </Grid>

        <Grid item xs={12} alignSelf="center">
          <CheckBox name="accept_financing" label="Aceita Financiamento" />
        </Grid>
      </Scope>
    </Grid>
  );
};

export default SaleFields;
