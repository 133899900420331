import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { INPUT_TYPE_LABEL } from 'src/constants';

import { DetailFieldData, PropertyTypeDetailsFieldsProps } from './interfaces';

const PropertyTypeDetailsFields: React.FC<PropertyTypeDetailsFieldsProps> = ({
  propertyType,
  currentValues,
  formRef,
  path = 'details',
}) => {
  const [detailsFields, setDetailsFields] = useState<DetailFieldData[]>([]);

  useEffect(() => {
    const details = propertyType?.details?.map((detailProperty) => {
      const exists = currentValues?.find(
        (current) => current.detail_property_id == detailProperty.id,
      );
      return {
        id: exists?.id,
        detail_property_id: detailProperty.id,
        value: exists?.value || '',
        detailProperty,
      } as DetailFieldData;
    });

    setDetailsFields(details || []);
  }, [propertyType, currentValues]);

  useEffect(() => {
    detailsFields.map((detailField, index) => {
      formRef?.current?.setFieldValue(
        `${path}[${index}].value`,
        detailField.value,
      );
    });
  }, [detailsFields, formRef, path]);

  return (
    <>
      {detailsFields.map((detailField, index) => (
        <Scope key={detailField.detail_property_id} path={`${path}[${index}]`}>
          <TextField name="id" value={detailField.id} disabled hidden />
          <TextField
            name="is_required"
            value={detailField.detailProperty?.is_required}
            disabled
            hidden
          />
          <TextField
            name="detail_property_id"
            value={detailField.detail_property_id}
            disabled
            hidden
          />
          <Grid item xs={12} sm={4}>
            {detailField.detailProperty?.input_type === 'boolean' ? (
              <CheckBox
                name="value"
                label={detailField.detailProperty?.name}
                returnAs="string"
              />
            ) : (
              <TextField
                name="value"
                label={`${detailField.detailProperty?.name} (${
                  INPUT_TYPE_LABEL[detailField.detailProperty?.input_type || '']
                })`}
                required={detailField.detailProperty?.is_required}
                type={detailField.detailProperty?.input_type}
              />
            )}
          </Grid>
        </Scope>
      ))}
    </>
  );
};

export default PropertyTypeDetailsFields;
