import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { Realtor } from 'src/modules/property/@types/models';
import { REALTOR_TYPES_LABEL } from 'src/modules/property/constants';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import RealtorFilterSchema from 'src/modules/property/validators/Realtor/filter.schema';

import RealtorsFilters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination(propertyApi.admin.realtors.useRealtorsQuery);

  const columns: GridColDef<Realtor>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'type',
      headerName: 'Tipo',
      valueGetter({ value }) {
        return REALTOR_TYPES_LABEL[value];
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Imobiliária"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/imovel/imobiliarias/${id}`}
            editPermissions={[PROPERTY_PERMISSIONS.REALTORS.UPDATE]}
            destroyApiEndpoint={propertyApi.admin.realtors.destroyRealtor}
            destroyPermissions={[PROPERTY_PERMISSIONS.REALTORS.DESTROY]}
            restoreApiEndpoint={propertyApi.admin.realtors.restoreRealtor}
            restorePermissions={[PROPERTY_PERMISSIONS.REALTORS.RESTORE]}
            descriptionPrefix={`A Imobiliária ${row.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Imobiliárias</Typography>

            <Can
              behavior="disable"
              permissions={[PROPERTY_PERMISSIONS.REALTORS.STORE]}
            >
              <LinkButton
                to="/imovel/imobiliarias/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                canSearch
                showFilters
                filterProps={{
                  yupSchema: RealtorFilterSchema,
                  children: <RealtorsFilters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
