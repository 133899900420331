import path from 'path-browserify';
import IMedia from 'src/interfaces/models/IMedia';
import { PropertyMedia } from 'src/modules/property/@types/models';

export function validateMediaFile(media: IMedia, file: File) {
  const validExt = media.accept?.split(',');
  if (validExt?.length) {
    const fileExt = file.name.split('.').pop();
    if (!fileExt || !validExt.includes(`.${fileExt}`)) return false;
  }

  if (media.max_size) {
    const fileSize = file.size / 1024; // KB
    if (fileSize > media.max_size) return false;
  }

  return true;
}

export function validateMediaFiles(media: IMedia, files: File[]) {
  const validFiles: File[] = [];
  const invalidFiles: File[] = [];

  for (const file of files) {
    if (validateMediaFile(media, file)) {
      validFiles.push(file);
    } else {
      invalidFiles.push(file);
    }
  }

  return { validFiles, invalidFiles };
}

export function propertyMediaIsImage(propertyMedia: PropertyMedia) {
  const fileUrl = propertyMedia.url?.split('?')[0];

  if (!fileUrl) return false;

  return ['.png', '.jpg', '.jpeg', '.gif', '.webp'].includes(
    path.extname(fileUrl).toLowerCase(),
  );
}
