import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardContent, CircularProgress, Grid } from '@mui/material';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import SearchClientDialog from 'src/components/_functions/SearchClientDialog';
import { SearchClientDialogRef } from 'src/components/_functions/SearchClientDialog/interfaces';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
import IconButton from 'src/components/IconButton';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IClient } from 'src/interfaces/models/IClient';
import propertyApi from 'src/modules/property/services/api';
import { IdentityOwnersUpdateSchema } from 'src/modules/property/validators/IdentityOwner/save.schema';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

type OwnerField = {
  owner_id: string | number;
  percentage: string;
  owner_name: string;
};

const OwnersTab: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IEditParams>();
  const {
    isLoading,
    data: response,
  } = propertyApi.admin.identities.owners.useIdentityOwners(params.id);
  const [ownersFields, setOwnersFields] = useState<OwnerField[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const searchClientRef = useRef<SearchClientDialogRef>(null);
  const history = useHistory();

  function handleSelectOwner(client: IClient) {
    if (ownersFields.find((ownerField) => ownerField.owner_id == client.id)) {
      return toast.warn('Cliente já definido como proprietário');
    }

    setOwnersFields((state) => [
      ...state,
      { owner_id: client.id, percentage: '', owner_name: client.username },
    ]);

    toast.success('Proprietário adicionado.');
  }

  function handleDeleteOwner(owner: OwnerField) {
    setOwnersFields((state) =>
      state.filter((s) => s.owner_id !== owner.owner_id),
    );
  }

  function handleCancel() {
    history.goBack();
  }

  async function handleSubmit(formData: OwnerField[]) {
    try {
      setSubmitting(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        IdentityOwnersUpdateSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await propertyApi.admin.identities.owners.updateIdentityOwners(
        params.id,
        data,
      );

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        IdentityOwnersUpdateSchema,
        formRef,
      );
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (!response?.data) return setOwnersFields([]);

    const fields: OwnerField[] = response.data.map((identityOwner) => ({
      owner_id: identityOwner.owner_id,
      percentage: identityOwner.percentage.toString(),
      owner_name: identityOwner.owner?.username || '',
    }));

    setOwnersFields(fields);
    setTimeout(() => {
      formRef.current?.setData({
        owners: fields,
      });
    });
  }, [response?.data]);

  return isLoading ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    startIcon={<SearchIcon />}
                    variant="outlined"
                    color="success"
                    onClick={() => searchClientRef.current?.show()}
                    disabled={submitting}
                  >
                    Proprietário
                  </Button>
                </Grid>

                {ownersFields.map((ownerField, index) => (
                  <Grid item xs={12} key={ownerField.owner_id}>
                    <Grid container spacing={1} alignItems="center">
                      <Scope path={`owners[${index}]`}>
                        <TextField
                          hidden
                          name="owner_id"
                          value={ownerField.owner_id}
                        />
                        <TextField
                          hidden
                          name="owner_name"
                          value={ownerField.owner_name}
                        />

                        <Grid item xs={12} sm={4} md={2}>
                          <TextField
                            name="percentage"
                            label="Participação (%)"
                            mask="percent"
                            returnUnmasked
                            disabled={submitting}
                          />
                        </Grid>
                      </Scope>

                      <Grid item xs={12} sm>
                        <IconButton
                          onClick={() => handleDeleteOwner(ownerField)}
                          tooltip="Remover"
                          color="error"
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        {ownerField.owner_name}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <SearchClientDialog
                ref={searchClientRef}
                onSelectClient={handleSelectOwner}
                defaultQueryParams={{ types: ['F', 'J', 'L'] }}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="end" gap={1}>
            <Button
              startIcon={<CloseIcon />}
              color="error"
              onClick={handleCancel}
              disabled={submitting}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              startIcon={<CheckIcon />}
              color="success"
              variant="contained"
              loading={submitting}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default OwnersTab;
