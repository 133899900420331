import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import IZApiInstance from 'src/interfaces/models/IZApiInstance';
import PrivateContext from 'src/routes/Private/PrivateContext';
import apiAdmin from 'src/services/api/admin';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  ZApiInstanceStoreSchema,
  ZApiInstanceUpdateSchema,
} from 'src/validators/ZApiInstance/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [zApiInstance, setZApiInstance] = useState<IZApiInstance>();
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? ZApiInstanceUpdateSchema
    : ZApiInstanceStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        if (params.id) {
          const { data } = await apiAdmin.getZApiInstance(params.id);
          setZApiInstance(data);
          formRef.current?.setData(data);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await apiAdmin.updateZApiInstance(params.id, data);
      } else {
        await apiAdmin.storeZApiInstance(data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (zApiInstance?.status === 'active') {
        await apiAdmin.destroyZApiInstance(params.id);
        toast.success('Instância desativada!');
      } else {
        await apiAdmin.restoreZApiInstance(params.id);
        toast.success('Instância restaurada!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Instância.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Instância WhatsApp
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={4}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="instance_id"
                      label="ID da Instância"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name="instance_token"
                      label="Token da Instância"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="instance_client_token"
                      label="Token de Segurança da Instância (Client Token)"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CheckBox
                      name="update_webhooks"
                      label="Atualizar Webhooks"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can permissions={['destroy-z-api-instance']}>
                {zApiInstance?.status === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${
          zApiInstance?.status === 'active' ? 'Desativar' : 'Restaurar'
        } mídia`}
        description="Confirma esta ação?"
        confirmColor={zApiInstance?.status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
