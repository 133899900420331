import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import { hasPermission } from 'src/utils/helpers';

import AddressTab from './AddressTab';
import CityTab from './CityTab';
import CountryTab from './CountryTab';
import NeighborhoodTab from './NeighborhoodTab';
import StateTab from './StateTab';
import StreetTab from './StreetTab';
import { Container } from './styles';

const addressesTabs = [
  {
    name: 'Endereços',
    slug: 'endereco',
    component: <AddressTab />,
    permissions: ['list-addresses'],
  },
  {
    name: 'Países',
    slug: 'paises',
    component: <CountryTab />,
    permissions: ['list-countries'],
  },
  {
    name: 'Estados',
    slug: 'estados',
    component: <StateTab />,
    permissions: ['list-states'],
  },
  {
    name: 'Cidades',
    slug: 'cidades',
    component: <CityTab />,
    permissions: ['list-cities'],
  },
  {
    name: 'Bairros',
    slug: 'bairros',
    component: <NeighborhoodTab />,
    permissions: ['list-neighborhoods'],
  },
  {
    name: 'Logradouros',
    slug: 'logradouros',
    component: <StreetTab />,
    permissions: ['list-streets'],
  },
];

const Addresses: FC = () => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const params = useParams<Omit<ITabEditParams, 'id'>>();
  const history = useHistory();

  const handleTabChange = (e: ChangeEvent<any>, newValue: number) => {
    let goToUrl = `/enderecos`;
    // if (newValue > 0) {
    goToUrl = goToUrl.concat(`/${addressesTabs[newValue].slug}?status=active`);
    // }

    history.replace(goToUrl);
  };

  useEffect(() => {
    if (params.selectedTab) {
      setCurrentTab(
        addressesTabs.findIndex((tab) => tab.slug === params.selectedTab),
      );
    } else {
      if (hasPermission(['list-addresses'])) {
        history.replace('/enderecos/endereco?status=active');
      }
    }
  }, [params.selectedTab, history]);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Typography variant="h5">Endereços</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AppBar color="transparent" position="relative">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {addressesTabs.map(({ name, permissions }) => (
                <Tab
                  key={name}
                  label={name}
                  disabled={!hasPermission(permissions)}
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12}>
          {addressesTabs.map(({ name, slug, component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              {component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Addresses;
