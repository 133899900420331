import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { Funnel } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';

type AutocompleteFunnelsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<Funnel>, 'options'>;

const AutocompleteFunnels: React.FC<AutocompleteFunnelsProps> = ({
  requestConfig,
  ...rest
}) => {
  const {
    isLoading,
    data: response,
  } = attendanceApi.admin.common.useFunnelsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((funnel) => ({
      key: funnel.id,
      label: funnel.name,
      value: funnel.id,
      data: funnel,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteFunnels;
