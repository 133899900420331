import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import IconArrowForward from '@mui/icons-material/ArrowForward';
import IconClose from '@mui/icons-material/Close';
import { Card, CardContent, Grid } from '@mui/material';
import Button from 'src/components/Button';
import AddressFields from 'src/components/Form/AddressFields';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import RadioGroup from 'src/components/Form/RadioGroup';
import TextField from 'src/components/Form/TextField';
import { Condominium } from 'src/modules/property/@types/models';
import AutocompleteCondominiums from 'src/modules/property/components/Form/_common/AutocompleteCondominiums';
import { StoreIdentityContext } from 'src/modules/property/pages/Private/Identities/Store/context';
import propertyApi from 'src/modules/property/services/api';
import { IdentityStoreStepAddressSchema } from 'src/modules/property/validators/Identity/save.schema';
import { handleApiResponseErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

type IdentityStepProps = {
  show?: boolean;
};

const IdentityStep: React.FC<IdentityStepProps> = ({ show }) => {
  const {
    formRef,
    handleBack,
    handleNext,
    setSelectedCondominium,
  } = useContext(StoreIdentityContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function handleCondominiumChange(option: AutocompleteValue<Condominium>) {
    if (!setSelectedCondominium) return;
    if (Array.isArray(option)) return;

    setSelectedCondominium(option?.data);
  }

  async function handleSubmit() {
    try {
      setLoading(true);

      const formData = formRef?.current?.getData();
      const { success, errors } = await yupValidate(
        IdentityStoreStepAddressSchema,
        formData,
      );

      if (!success || !formData) {
        formRef?.current?.setErrors(errors);
        return toast.error('Verifique os dados informados.');
      }

      const { data } = await propertyApi.admin.identities.getIdentities({
        params: {
          'address_exists[zip_code]': formData.zip_code,
          'address_exists[city_id]': formData.city_id,
          'address_exists[neighborhood_id]': formData.neighborhood_id,
          'address_exists[street_id]': formData.street_id,
        },
      });

      const exists = data.shift();
      if (exists) {
        history.replace(`/imovel/identidades/${exists.id}`);
        return toast.info(
          'Já existe um imóvel com esse endereço, você foi redirecionado.',
        );
      }

      handleNext();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao verificar identidade.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container spacing={1} display={!show ? 'none' : undefined}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  options={[
                    { key: 0, label: 'Única', value: 'unique' },
                    { key: 1, label: 'Múltipla', value: 'multiple' },
                  ]}
                  name="type"
                  label="Tipo da Identidade"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <AddressFields formRef={formRef} withComplement withLatLng />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AutocompleteCondominiums
                  name="condominium_id"
                  label="Condomínio"
                  onChange={handleCondominiumChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField name="name" label="Título" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="description"
                  label="Descrição"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="end">
          <Grid item>
            <Button
              variant="contained"
              color="error"
              startIcon={<IconClose />}
              onClick={handleBack}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="info"
              endIcon={<IconArrowForward />}
              onClick={handleSubmit}
              loading={loading}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IdentityStep;
