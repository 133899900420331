import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { ADMIN_QUERY_KEYS, COMMON_QUERY_KEYS } from 'src/constants/query';
import IZApiInstance from 'src/interfaces/models/IZApiInstance';
import { adminAxios } from 'src/services/api/admin';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getZApiInstances(config?: AxiosRequestConfig) {
  const response = await adminAxios.get<IResponseAPIFilter<IZApiInstance>>(
    '/z-api-instances',
    config,
  );

  return response;
}

export function useZApiInstancesQuery(data?: IApiUseQueryData<IZApiInstance>) {
  return useQuery<IApiUseQueryFnData<IZApiInstance>>(
    [ADMIN_QUERY_KEYS.Z_API_INSTANCES, data?.requestConfig?.params],
    () => getZApiInstances(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateZApiInstancesQueries() {
  queryClient.invalidateQueries([COMMON_QUERY_KEYS.Z_API_INSTANCES]);
  queryClient.invalidateQueries([ADMIN_QUERY_KEYS.Z_API_INSTANCES]);
}

export async function storeZApiInstance(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.post<IZApiInstance>(
    '/z-api-instances',
    data,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function getZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.get<IZApiInstance>(
    `/z-api-instances/${id}`,
    config,
  );

  return response;
}

export async function updateZApiInstance(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.put<IZApiInstance>(
    `/z-api-instances/${id}`,
    data,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function destroyZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.delete<boolean>(
    `/z-api-instances/${id}`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function restoreZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.post<boolean>(
    `/z-api-instances/${id}/restore`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function checkZApiInstanceConnection(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.get(
    `/z-api-instances/${id}/check-connection`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function disconnectZApiInstance(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.get(
    `/z-api-instances/${id}/disconnect`,
    config,
  );

  invalidateZApiInstancesQueries();

  return response;
}

export async function updateZApiInstanceWebhooks(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await adminAxios.get(
    `/z-api-instances/${id}/update-webhooks`,
    config,
  );

  return response;
}
