import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import BackButton from 'src/components/BackButton';
import IconButton from 'src/components/IconButton';
import { OpportunityDetailsParams } from 'src/modules/attendance/@types/params';
import ChangeStatusOpportunityDialog from 'src/modules/attendance/components/ChangeStatusOpportunityDialog';
import OpportunityAttendantsDialog from 'src/modules/attendance/components/OpportunityAttendantsDialog';
import OpportunityNewChatDialog from 'src/modules/attendance/components/OpportunityNewChatDialog';
import OpportunityTransferFunnelDialog from 'src/modules/attendance/components/OpportunityTransferFunnelDialog';
import StoreOpportunityAttendanceDialog from 'src/modules/attendance/components/StoreOpportunityAttendanceDialog';
import UpdateOpportunityDialog from 'src/modules/attendance/components/UpdateOpportunityDialog';
import { OPPORTUNITY_STATUS_LABEL } from 'src/modules/attendance/constants';
import { opportunityStatusTextColor } from 'src/modules/attendance/helpers';
import attendanceApi from 'src/modules/attendance/services/api';
import { formatDate, formatIntToMoney } from 'src/utils/helpers';
import masks from 'src/utils/masks';

import InterestCard from './InterestCard';
import Loading from './Loading';
import NotFound from './NotFound';
import OpportunityChat from './OpportunityChat';
import { Body, Container, Header, HeaderTitle } from './styles';

const OpportunityDetails: React.FC = () => {
  const params = useParams<OpportunityDetailsParams>();
  const {
    data: response,
    isLoading,
    refetch,
  } = attendanceApi.admin.funnels.opportunities.useFunnelOpportunityQuery(
    params.funnelId,
    params.id,
  );
  const {
    data: chatsResponse,
  } = attendanceApi.admin.funnels.opportunities.useFunnelOpportunityChatQuery(
    params.funnelId,
    params.id,
    { queryOptions: { staleTime: Infinity } },
  );
  const [openStoreAttendance, setOpenStoreAttendance] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openAttendants, setOpenAttendants] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openNewChat, setOpenNewChat] = useState(false);

  if (isLoading) return <Loading />;

  if (!response?.data) return <NotFound />;

  const { data: opportunity } = response;

  const clientPhoneNumber = parsePhoneNumberFromString(
    opportunity.client?.phone || '',
    'BR',
  )?.number;

  function getStatusText() {
    const statusLabel = opportunity.is_waiting
      ? 'Em Espera até'
      : OPPORTUNITY_STATUS_LABEL[opportunity.status];

    let formattedStatusDate = '';
    if (opportunity.is_waiting) {
      formattedStatusDate = formatDate(opportunity.wait_until || '');
    } else if (opportunity.status === 'open') {
      formattedStatusDate = formatDate(
        opportunity.created_at,
        'dd/MM/yyyy HH:mm',
      );
    } else {
      formattedStatusDate = formatDate(
        opportunity.closed_at || '',
        'dd/MM/yyyy HH:mm',
      );
    }

    return `${statusLabel} - ${formattedStatusDate}`;
  }

  async function handleClickWhatsApp() {
    const openChat = chatsResponse?.data.find(
      (chat) => chat.status === 'active',
    );

    if (openChat) {
      toast.info('A oportunidade já possui um Chat em aberto.');
      return;
    }

    setOpenNewChat(true);
  }

  return (
    <Container maxWidth="xl">
      <HeaderTitle square>
        <Typography>
          <BackButton /> Voltar
        </Typography>
        <Typography variant="h6">
          <strong>
            {opportunity.name ||
              `Oportunidade de ${opportunity.client?.username}`}
          </strong>
          <IconButton
            size="small"
            tooltip="Alterar Título"
            color="info"
            onClick={() => setOpenUpdate(true)}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Typography>
      </HeaderTitle>

      <Header square>
        <Grid container spacing={1}>
          <Grid item xs={12} sm>
            <Typography>
              Lead: <strong>{opportunity.client?.username}</strong>{' '}
              <a
                href={`/clientes/${opportunity.client?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton size="small" color="info">
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </a>
            </Typography>
            <Typography>
              E-mail: <strong>{opportunity.client?.email}</strong>
            </Typography>
            {clientPhoneNumber && (
              <Typography>
                Telefone:{' '}
                <strong>
                  {masks.strictPhoneOrCellphone(clientPhoneNumber)}
                </strong>{' '}
                <IconButton size="small" onClick={handleClickWhatsApp}>
                  <WhatsAppIcon color="success" fontSize="inherit" />
                </IconButton>
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm>
            <Typography>
              Status:{' '}
              <Typography
                display="inline"
                fontWeight="bold"
                color={opportunityStatusTextColor(opportunity)}
                marginRight={1}
              >
                {getStatusText()}
              </Typography>
              <IconButton
                size="small"
                tooltip="Alterar Status"
                color="info"
                onClick={() => setOpenChangeStatus(true)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Typography>
            <Typography>
              Funil: <strong>{opportunity.step?.funnel?.name}</strong>
              <IconButton
                size="small"
                tooltip="Alterar Funil"
                color="info"
                onClick={() => setOpenTransfer(true)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Typography>
            <Typography>
              Etapa:{' '}
              <strong>
                {opportunity.step?.name} -{' '}
                {formatDate(
                  opportunity.funnel_step_entered_at,
                  'dd/MM/yyyy HH:mm',
                )}
              </strong>
            </Typography>
            <Typography>
              Valor: <strong>{formatIntToMoney(opportunity.amount)}</strong>
            </Typography>

            <Grid container alignItems="center" gap={1}>
              <Typography>
                Atendente: <strong>{opportunity.attendant?.name}</strong>
              </Typography>

              <IconButton
                size="small"
                tooltip="Ver Atendentes"
                color="info"
                onClick={() => setOpenAttendants(true)}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Header>

      <Body>
        <Paper square sx={{ flex: 1, p: 1, overflowY: 'auto' }}>
          <Grid container gap={1}>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Interesses
              </Typography>
            </Grid>

            {opportunity.interests?.map((interest) => (
              <Grid key={interest.id} item xs={12}>
                <InterestCard interest={interest} />
              </Grid>
            ))}
          </Grid>
        </Paper>

        <Paper
          square
          sx={{ minHeight: '100%', p: 1, flex: 1, overflowY: 'auto' }}
        >
          <Grid container gap={1}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Typography variant="h6" align="center">
                  Atendimentos
                </Typography>
                <IconButton
                  size="small"
                  tooltip="Novo Atendimento"
                  color="success"
                  variant="outlined"
                  onClick={() => setOpenStoreAttendance(true)}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>

            {opportunity.attendances?.map((attendance) => (
              <Grid key={attendance.id} item xs={12}>
                <Card>
                  <CardHeader
                    title={formatDate(
                      attendance.created_at,
                      'dd/MM/yyyy HH:mm',
                    )}
                    titleTypographyProps={{ fontSize: 16 }}
                    subheader={attendance.attendant?.name}
                  />
                  <CardContent style={{ whiteSpace: 'break-spaces' }}>
                    {attendance.description}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Paper>

        {!!chatsResponse?.data.length && (
          <OpportunityChat
            opportunity={opportunity}
            chats={chatsResponse.data}
          />
        )}
      </Body>

      <StoreOpportunityAttendanceDialog
        open={openStoreAttendance}
        onClose={() => setOpenStoreAttendance(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
      />

      <ChangeStatusOpportunityDialog
        open={openChangeStatus}
        onClose={() => setOpenChangeStatus(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
      />

      <OpportunityAttendantsDialog
        open={openAttendants}
        onClose={() => setOpenAttendants(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        onSuccessTransfer={refetch}
      />

      <OpportunityTransferFunnelDialog
        open={openTransfer}
        onClose={() => setOpenTransfer(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        onSuccess={refetch}
      />

      <UpdateOpportunityDialog
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
        // onSuccess={refetch}
      />

      <OpportunityNewChatDialog
        open={openNewChat}
        onClose={() => setOpenNewChat(false)}
        funnelId={Number(params.funnelId)}
        opportunity={opportunity}
      />
    </Container>
  );
};

export default OpportunityDetails;
