import yup from 'src/libs/yup';

export const ClientAddressStoreSchema = yup.object().shape({
  zip_code: yup.string().required().label('CEP'),
  city_id: yup.number().required().label('Cidade'),
  neighborhood_id: yup.number().nullable().required().label('Bairro'),
  street_id: yup.number().nullable().required().label('Rua'),
  number: yup.string().required().label('Número'),
  map_link: yup.string().label('Link'),
  property_type_id: yup.string().nullable().required().label('Tipo'),
  description: yup.string().label('Nome'),
  complements: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      address_complement_id: yup.string().required(),
      complement_value: yup.string(),
    }),
  ),
  main: yup.boolean(),
});

export const ClientAddressUpdateSchema = yup.object().shape({
  zip_code: yup.string().required().label('CEP'),
  city_id: yup.number().required().label('Cidade'),
  neighborhood_id: yup.number().nullable().required().label('Bairro'),
  street_id: yup.number().nullable().required().label('Rua'),
  number: yup.string().required().label('Número'),
  map_link: yup.string().label('Link'),
  property_type_id: yup.string().nullable().required().label('Tipo'),
  description: yup.string().label('Nome'),
  complements: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      address_complement_id: yup.string().required(),
      complement_value: yup.string(),
    }),
  ),
  main: yup.boolean(),
});
