import { createContext, Dispatch, RefObject, SetStateAction } from 'react';

import { FormHandles } from '@unform/core';
import { Condominium } from 'src/modules/property/@types/models';

type StoreIdentityContextData = {
  formRef?: RefObject<FormHandles>;
  handleBack: () => void;
  handleNext: () => void;
  selectedCondominium?: Condominium;
  setSelectedCondominium?: Dispatch<SetStateAction<Condominium | undefined>>;
};

export const StoreIdentityContext = createContext<StoreIdentityContextData>({
  handleBack: () => null,
  handleNext: () => null,
});
