import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import AutocompletePropertyTypes from 'src/components/Form/_Common/AutocompletePropertyTypes';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import PropertyTypeComplementsFields from 'src/components/Form/PropertyTypeComplementsFields';
import PropertyTypeDetailsFields from 'src/components/Form/PropertyTypeDetailsFields';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import IPropertyType from 'src/interfaces/models/IPropertyType';
import {
  CondominiumDetail,
  Property,
} from 'src/modules/property/@types/models';
import { SaveIdentityPropertyParams } from 'src/modules/property/@types/params';
import AutocompletePublicitiesTypes from 'src/modules/property/components/Form/_common/AutocompletePublicitiesTypes';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import { IdentityUpdateContext } from 'src/modules/property/pages/Private/Identities/Update/context';
import propertyApi from 'src/modules/property/services/api';
import {
  PropertyStoreSchema,
  PropertyUpdateSchema,
} from 'src/modules/property/validators/Property/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

import PropertyInfos from './Infos';
import PropertyOwners from './Owners';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const { identity } = useContext(IdentityUpdateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState<Property>();
  const history = useHistory();
  const params = useParams<SaveIdentityPropertyParams>();
  const { url } = useRouteMatch();
  const [propertyType, setPropertyType] = useState<IPropertyType>();

  const detailSchema = params.propertyId
    ? PropertyUpdateSchema
    : PropertyStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.propertyId) {
        const { data } = await propertyApi.admin.properties.getProperty(
          params.propertyId,
        );
        setProperty(data);

        setTimeout(() => {
          formRef.current?.setData({
            ...data,
            publicities: data.publicities?.map((publicity) => publicity.id),
          });
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.propertyId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<CondominiumDetail> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        detailSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newProperty: Property;
      if (params.propertyId) {
        const response = await propertyApi.admin.properties.updateProperty(
          params.propertyId,
          data,
        );
        newProperty = response.data;
      } else {
        const response = await propertyApi.admin.properties.storeProperty(data);
        newProperty = response.data;
      }

      toast.success('Imóvel salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.propertyId) {
        history.replace(`${url}/${newProperty.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', detailSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async (isDeleting) => {
    try {
      setLoading(true);

      if (params.propertyId) {
        let deleteConfig: AxiosRequestConfig = {};
        if (isDeleting) {
          deleteConfig = { data: { delete: true } };
        }

        await propertyApi.admin.properties.destroyProperty(
          params.propertyId,
          deleteConfig,
        );

        toast.success('Imóvel desativado!');

        if (isDeleting) return history.goBack();

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Imóvel');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      if (params.propertyId) {
        await propertyApi.admin.properties.restoreProperty(params.propertyId);

        toast.success('Imóvel restaurado!');

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao restaurar Imóvel.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  function handleChangePropertyType(value: AutocompleteValue<IPropertyType>) {
    if (Array.isArray(value) || !value) return setPropertyType(undefined);

    setPropertyType(value.data);
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [PROPERTY_PERMISSIONS.PROPERTIES.STORE],
        update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
        destroy: [PROPERTY_PERMISSIONS.PROPERTIES.DESTROY],
        restore: [PROPERTY_PERMISSIONS.PROPERTIES.RESTORE],
      }}
      resourceName="Imóvel"
      onSave={handleSave}
      onDestroy={handleDestroy}
      canDelete
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.propertyId}
      isActive={property?.status === STATUSES.ACTIVE}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField name="title" label="Título (Site)" required />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Descrição (Site)"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompletePublicitiesTypes
                    name="publicities"
                    label="Tipos de Publicidades"
                    multiple
                  />
                </Grid>

                <Grid item xs={12}>
                  <AutocompletePropertyTypes
                    name="property_type_id"
                    label="Tipo"
                    textFieldProps={{ required: true }}
                    requestConfig={{
                      params: { preload: ['complements', 'details'] },
                    }}
                    onChange={handleChangePropertyType}
                  />
                </Grid>

                {propertyType && (
                  <>
                    <Grid item xs={12}>
                      <Divider textAlign="left">
                        Complemento de Endereço
                      </Divider>
                    </Grid>
                    <PropertyTypeComplementsFields
                      path="complements"
                      propertyType={propertyType}
                      formRef={formRef}
                      currentValues={property?.complements}
                    />
                  </>
                )}

                {propertyType && (
                  <>
                    <Grid item xs={12}>
                      <Divider textAlign="left">Detalhes do Imóvel</Divider>
                    </Grid>
                    <PropertyTypeDetailsFields
                      path="details"
                      propertyType={propertyType}
                      formRef={formRef}
                      currentValues={property?.details}
                    />
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {identity?.type === 'multiple' && (
          <>
            <Grid item xs={12}>
              <PropertyOwners currentValues={property?.owners} />
            </Grid>

            <Grid item xs={12}>
              <PropertyInfos />
            </Grid>
          </>
        )}
      </Grid>
    </Form>
  );
};

export default Save;
