import yup from 'src/libs/yup';

const DetailCondominiumFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  slug: yup.string().label('Slug'),
  input_type: yup.string().nullable().label('Tipo do Valor'),
  is_required: yup.number().nullable().label('Obrigatório'),
  status: yup.string().nullable().label('Status'),
});

export default DetailCondominiumFilterSchema;
