import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import List from './List';
import Store from './Store';
import Update from './Update';

const PhotosTab: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/novo`} component={Store} />
      <Route path={`${path}/:propertyMediaId`} component={Update} />
    </Switch>
  );
};

export default PhotosTab;
