import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { ICountry, IState } from 'src/interfaces/models/IAddress';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  StateStoreSchema,
  StateUpdateSchema,
} from 'src/validators/State/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [countries, setCountries] = useState<ICountry[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? StateUpdateSchema : StateStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const response = await apiAdminPublic.getCountries();

        setCountries(response.data);

        if (params.id) {
          const response = await api.get<IState>(`admin/states/${params.id}`);
          const state = response.data;

          setStatus(state.status);

          formRef.current?.setData(state);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/states/${params.id}`, data);
      } else {
        await api.post(`/admin/states`, data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/states/${params.id}`);
        toast.success('Estado desativado!');
      } else {
        await api.post(`/admin/states/${params.id}/restore`);
        toast.success('Estado restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar estado.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h6">
                {params.id ? 'Editar' : 'Novo'} Estado
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      name="country_id"
                      label="País"
                      options={countries.map((country) => ({
                        key: country.id,
                        label: `${country.name} (${country.initials})`,
                        value: country.id,
                      }))}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField name="initials" label="Iniciais" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can permissions={['destroy-states']}>
                {status === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} estado`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
