import yup from 'src/libs/yup';

export const CityStoreSchema = yup.object().shape({
  state_id: yup.number().required().label('Estado'),
  name: yup.string().required().label('Nome'),
});

export const CityUpdateSchema = yup.object().shape({
  state_id: yup.number().required().label('Estado'),
  name: yup.string().required().label('Nome'),
});
