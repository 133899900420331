import React from 'react';

import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { MEDIA_TYPE_OPTIONS } from 'src/constants';

type AutocompleteMediaTypesProps = Omit<AutocompleteProps, 'options'>;

const AutocompleteMediaTypes: React.FC<AutocompleteMediaTypesProps> = ({
  ...rest
}) => {
  return <Autocomplete {...rest} options={MEDIA_TYPE_OPTIONS} />;
};

export default AutocompleteMediaTypes;
