import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';

function invalidatePropertyCoverQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PROPERTIES]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PROPERTIES]);
}

async function storePropertyCover(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post(
    `/property/admin/properties/${propertyId}/cover`,
    data,
    config,
  );

  invalidatePropertyCoverQueries();

  return response;
}

async function destroyPropertyCover(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${propertyId}/cover/${id}`,
    config,
  );

  invalidatePropertyCoverQueries();

  return response;
}

export const cover = {
  storePropertyCover,
  destroyPropertyCover,
};
