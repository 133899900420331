import * as AddressComplements from './AddressComplements';
import Addresses from './Addresses';
import * as BankAccounts from './BankAccounts';
import * as Banks from './Banks';
import * as CivilStatusTypes from './CivilStatusTypes';
import * as Clients from './Clients';
import * as ContactTypes from './ContactTypes';
import Dashboard from './Dashboard';
import * as Departments from './Departments';
import * as DocumentTypes from './DocumentTypes';
import * as Medias from './Medias';
import Omnichannel from './Omnichannel';
import Profile from './Profile';
import * as PropertyTypes from './PropertyTypes';
import * as Roles from './Roles';
import * as Users from './Users';
import * as ZApiInstances from './ZApiInstances';

export default {
  Dashboard,
  Users,
  Profile,
  Roles,
  Addresses,
  CivilStatusTypes,
  Clients,
  DocumentTypes,
  ContactTypes,
  Banks,
  BankAccounts,
  AddressComplements,
  PropertyTypes,
  Medias,
  Omnichannel,
  ZApiInstances,
  Departments,
};
