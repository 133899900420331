import yup from 'src/libs/yup';

export const PropertyTypeDetailStoreSchema = yup.object().shape({
  detail_property_id: yup.string().nullable().required().label('Detalhe'),
  is_required: yup.boolean().label('Obrigátorio'),
});

export const PropertyTypeDetailUpdateSchema = yup.object().shape({
  detail_property_id: yup.string().nullable().required().label('Detalhe'),
  is_required: yup.boolean().label('Obrigátorio'),
});
