import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Grid, IconButton, TextField } from '@mui/material';
import Button from 'src/components/Button';
import LinkButton from 'src/components/LinkButton';
import OmnichannelContext, {
  UploadFile,
} from 'src/pages/Private/Omnichannel/context';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { ChatMessage } from 'src/services/websocket/omnichannel/interfaces';

import { Container, FilesContainer, FileItem } from './styles';

interface UploadFilesProps {
  channel: string;
}

const MAX_UPLOAD_FILE_SIZE = 100 * 1024 * 1024; // 100Mb

const UploadFiles: React.FC<UploadFilesProps> = ({ channel }) => {
  const {
    upload,
    setUpload,
    selectedChat,
    answerMessage,
    setAnswerMessage,
  } = useContext(OmnichannelContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const removeFile = (removedUpload: UploadFile) => {
    URL.revokeObjectURL(removedUpload.previewURL);
    setUpload((data) => {
      if (!data) return data;

      const uploadFiles = data.uploadFiles.filter(
        (upload) => upload.file.name !== removedUpload.file.name,
      );

      return { ...data, uploadFiles };
    });
  };

  const handleAddFiles = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const acceptTypes = upload?.accept.split(',');
    const selectedFiles = Array.from(e.target.files);

    const typeNotAccept = selectedFiles.some(
      (file) => !acceptTypes?.includes(file.type),
    );

    if (typeNotAccept) {
      toast.info('Você selecionou arquivos com tipos não permitidos.');
      return;
    }

    setUpload((data) => {
      if (!data) return data;

      const filteredFiles = selectedFiles.filter(
        (file) => !data.uploadFiles.find((d) => d.file.name === file.name),
      );

      const newFiles = filteredFiles.map((file) => ({
        previewURL: URL.createObjectURL(file),
        file,
      }));

      return { ...data, uploadFiles: [...data.uploadFiles, ...newFiles] };
    });
  };

  const cancelUpload = () => {
    upload?.uploadFiles.forEach((upload) =>
      URL.revokeObjectURL(upload.previewURL),
    );
    setUpload(null);
  };

  function sendChatMessage(data: {
    chat_id: string;
    message?: string;
    uploadFile?: UploadFile;
  }): Promise<ChatMessage> {
    return new Promise((resolve, reject) => {
      try {
        WSOmnichannel.socket?.emit(
          'send_message',
          {
            chat_id: data.chat_id,
            channel: channel,
            type: data.uploadFile ? upload?.type || 'document' : 'text',
            message: data.message,
            file: data.uploadFile?.file,
            replyMessageId: answerMessage?.whatsapp?.messageId,
          },
          (result) => {
            if (result.error) {
              reject(result.error);
            } else {
              if (data.uploadFile) {
                removeFile(data.uploadFile);
              }
              resolve(result.data);
            }
          },
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleUpload = async () => {
    try {
      if (!upload?.uploadFiles.length) {
        return toast.info('Adicione algum arquivo.');
      }

      if (selectedChat?._id && !loading) {
        setLoading(true);

        const exceedFileSize = upload.uploadFiles.some(
          (uploadFile) => uploadFile.file.size > MAX_UPLOAD_FILE_SIZE,
        );
        if (exceedFileSize) {
          throw new Error('Tamanho máximo por arquivo: 100Mb');
        }

        if (message) {
          await sendChatMessage({
            chat_id: selectedChat._id,
            message,
          });
          setMessage('');
        }

        for (const uploadFile of upload.uploadFiles) {
          await sendChatMessage({
            chat_id: selectedChat._id,
            uploadFile,
          });
        }

        setAnswerMessage(undefined);
        setUpload(null);
      }
    } catch (error) {
      toast.error(error?.message || 'Erro ao enviar arquivos');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Grid item>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button
                color="primary"
                type="button"
                onClick={() => fileRef.current?.click()}
                variant="contained"
                disabled={loading}
              >
                Adicionar
                <input
                  ref={fileRef}
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  accept={upload?.accept}
                  onChange={handleAddFiles}
                />
              </Button>
            </Grid>

            <Grid item>
              <IconButton
                color="error"
                type="button"
                onClick={cancelUpload}
                disabled={loading}
                size="large"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ flex: 1, overflowY: 'auto' }}>
          <FilesContainer>
            {upload?.uploadFiles.map((upload) => (
              <FileItem key={upload.file.name}>
                <LinkButton
                  href={upload.previewURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {upload.file.name} (
                  {(upload.file.size / 1024 / 1024).toFixed(2)}Mb)
                </LinkButton>

                <IconButton
                  color="error"
                  type="button"
                  size="small"
                  onClick={() => removeFile(upload)}
                  disabled={loading}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </FileItem>
            ))}
          </FilesContainer>
        </Grid>

        <Grid item>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs>
              <TextField
                label="Mensagem"
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                maxRows={5}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Button
                color="error"
                type="button"
                variant="contained"
                endIcon={<CloseIcon />}
                onClick={cancelUpload}
                disabled={loading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                type="button"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleUpload}
                loading={loading}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UploadFiles;
