import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import AutocompleteClients from 'src/components/Form/_Common/AutocompleteClients';
import AddressFields from 'src/components/Form/AddressFields';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Reason } from 'src/modules/attendance/@types/models';
import { Condominium } from 'src/modules/property/@types/models';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  CondominiumStoreSchema,
  CondominiumUpdateSchema,
} from 'src/modules/property/validators/Condominium/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const CondominiumTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [condominium, setCondominium] = useState<Condominium>();
  const history = useHistory();
  const params = useParams<IEditParams>();

  const condominiumSchema = params.id
    ? CondominiumUpdateSchema
    : CondominiumStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data } = await propertyApi.admin.condominiums.getCondominium(
          params.id,
        );
        setCondominium(data);

        setTimeout(() => {
          formRef.current?.setData(data);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<Reason> = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        condominiumSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newCondominium: Condominium;
      if (params.id) {
        const response = await propertyApi.admin.condominiums.updateCondominium(
          params.id,
          data,
        );
        newCondominium = response.data;
      } else {
        const response = await propertyApi.admin.condominiums.storeCondominium(
          data,
        );
        newCondominium = response.data;
      }

      toast.success('Condomínio salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/imovel/condominios/${newCondominium.id}`);
      } else {
        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        condominiumSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.condominiums.destroyCondominium(params.id);

      toast.success('Condomínio desativado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Condomínio');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.condominiums.restoreCondominium(params.id);

      toast.success('Condomínio restaurado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Condomínio.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <>
      <Form
        ref={formRef}
        permissions={{
          store: [PROPERTY_PERMISSIONS.CONDOMINIUMS.STORE],
          update: [PROPERTY_PERMISSIONS.CONDOMINIUMS.UPDATE],
          destroy: [PROPERTY_PERMISSIONS.CONDOMINIUMS.DESTROY],
          restore: [PROPERTY_PERMISSIONS.CONDOMINIUMS.RESTORE],
        }}
        resourceName="Condomínio"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={condominium?.status === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={9}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Grid item xs={12} sm={4} md={3}>
                    <TextField name="slug" label="Slug" required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AutocompleteClients
                      name="syndicator_id"
                      label="Síndico"
                      requestConfig={{ params: { type: 'F' } }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AutocompleteClients
                      name="accountant_id"
                      label="Contador"
                      requestConfig={{ params: { types: ['F', 'J'] } }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      name="amount"
                      label="Preço"
                      mask="currency"
                      returnUnmasked
                      required
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <AddressFields
                  formRef={formRef}
                  currentAddress={condominium?.address}
                  withLatLng
                  withComplement
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default CondominiumTab;
