import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { PublicityType } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getPublicitiesTypes(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<PublicityType>>(
    '/property/admin/publicities-types',
    config,
  );

  return response;
}

export function usePublicitiesTypesQuery(
  data?: IApiUseQueryData<PublicityType>,
) {
  return useQuery<IApiUseQueryFnData<PublicityType>>(
    [PROPERTY_ADMIN_QUERY_KEYS.PUBLICITIES_TYPES, data?.requestConfig?.params],
    () => getPublicitiesTypes(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePublicitiesTypesQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PUBLICITIES_TYPES]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PUBLICITIES_TYPES]);
}

export async function storePublicityType(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<PublicityType>(
    '/property/admin/publicities-types',
    data,
    config,
  );

  invalidatePublicitiesTypesQueries();

  return response;
}

export async function getPublicityType(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PublicityType>(
    `/property/admin/publicities-types/${id}`,
    config,
  );

  return response;
}

export async function updatePublicityType(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PublicityType>(
    `/property/admin/publicities-types/${id}`,
    data,
    config,
  );

  invalidatePublicitiesTypesQueries();

  return response;
}

export async function destroyPublicityType(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/publicities-types/${id}`,
    config,
  );

  invalidatePublicitiesTypesQueries();

  return response;
}

export async function restorePublicityType(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/publicities-types/${id}/restore`,
    config,
  );

  invalidatePublicitiesTypesQueries();

  return response;
}
