import yup from 'src/libs/yup';

export const MediaStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  type: yup.string().emptyAsNull().required().label('Tipo'),
  source: yup.string().emptyAsNull().required().label('Origem'),
  accept: yup
    .string()
    .emptyAsNull()
    .when('source', {
      is: 'local',
      then: (schema) => schema.required(),
    })
    .label('Formato'),
  max_size: yup
    .string()
    .emptyAsNull()
    .when('source', {
      is: 'local',
      then: (schema) => schema.required(),
    })
    .label('Tamanho Máximo'),
});

export const MediaUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  type: yup.string().emptyAsNull().required().label('Tipo'),
  source: yup.string().emptyAsNull().required().label('Origem'),
  accept: yup
    .string()
    .emptyAsNull()
    .when('source', {
      is: 'local',
      then: (schema) => schema.required(),
    })
    .label('Formato'),
  max_size: yup
    .string()
    .emptyAsNull()
    .when('source', {
      is: 'local',
      then: (schema) => schema.required(),
    })
    .label('Tamanho Máximo'),
});
