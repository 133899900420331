import { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import { Condominium } from 'src/modules/property/@types/models';

import { StoreIdentityContext } from './context';
import IdentityInfoStep from './IdentityInfoStep';
import IdentityStep from './IdentityStep';
import PropertyStep from './PropertyStep';
import { Container } from './styles';

const UNIQUE_STEPS = [
  { title: 'Endereço', Component: IdentityStep },
  { title: 'Informações', Component: IdentityInfoStep },
  { title: 'Imóveis', Component: PropertyStep },
];
const MULTIPLE_STEPS = [
  { title: 'Endereço', Component: IdentityStep },
  { title: 'Imóveis', Component: PropertyStep },
];

const Store: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [identityType, setIdentityType] = useState('unique');
  const [selectedCondominium, setSelectedCondominium] = useState<Condominium>();

  const steps = identityType === 'unique' ? UNIQUE_STEPS : MULTIPLE_STEPS;

  async function handleBack() {
    if (activeStep === 0) return history.goBack();

    setActiveStep((oldStep) => oldStep - 1);
  }

  async function handleNext() {
    if (activeStep === steps.length) return;

    const type = formRef.current?.getFieldValue('type');
    setIdentityType(type);

    setActiveStep((oldStep) => oldStep + 1);
  }

  return (
    <StoreIdentityContext.Provider
      value={{
        formRef,
        handleBack,
        handleNext,
        selectedCondominium,
        setSelectedCondominium,
      }}
    >
      <Form
        ref={formRef}
        onSubmit={() => null}
        initialData={{ type: 'unique' }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <BackButton onClick={handleBack} />

                <Typography variant="h5">Novo Imóvel</Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Stepper activeStep={activeStep}>
                {steps.map((step) => (
                  <Step key={step.title}>
                    <StepLabel>{step.title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>

            <Grid item xs={12}>
              {steps.map((step, index) => {
                return (
                  <step.Component
                    key={step.title}
                    show={activeStep === index}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Form>
    </StoreIdentityContext.Provider>
  );
};

export default Store;
