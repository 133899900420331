import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { EconomicIndicator } from 'src/modules/property/@types/models';
import AutocompleteEconomicIndicatorFrequencies from 'src/modules/property/components/Form/_common/AutocompleteEconomicIndicatorFrequencies';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  EconomicIndicatorStoreSchema,
  EconomicIndicatorUpdateSchema,
} from 'src/modules/property/validators/EconomicIndicator/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const IndicatorTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const schema = params.id
    ? EconomicIndicatorUpdateSchema
    : EconomicIndicatorStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const {
          data: realtor,
        } = await propertyApi.admin.economicIndicators.getEconomicIndicator(
          params.id,
        );
        setStatus(realtor.status);

        setTimeout(() => {
          formRef.current?.setData(realtor);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<EconomicIndicator> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(schema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newIndicator: EconomicIndicator;
      if (params.id) {
        const response = await propertyApi.admin.economicIndicators.updateEconomicIndicator(
          params.id,
          data,
        );
        newIndicator = response.data;
      } else {
        const response = await propertyApi.admin.economicIndicators.storeEconomicIndicator(
          data,
        );
        newIndicator = response.data;
      }

      toast.success('Indicador Econômico salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/imovel/indicadores-economicos/${newIndicator.id}`);
      } else {
        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', schema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.economicIndicators.destroyEconomicIndicator(
        params.id,
      );

      toast.success('Indicador Econômico desativado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao desativar Indicador Econômico',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.economicIndicators.restoreEconomicIndicator(
        params.id,
      );

      toast.success('Indicador Econômico restaurado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar Indicador Econômico.',
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.STORE],
        update: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.UPDATE],
        destroy: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.DESTROY],
        restore: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.RESTORE],
      }}
      resourceName="Indicador Econômico"
      onSave={handleSave}
      onDestroy={handleDestroy}
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.id}
      isActive={status === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8} md={8}>
                  <TextField name="name" label="Nome" required />
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  <AutocompleteEconomicIndicatorFrequencies
                    name="frequency"
                    label="Frequência"
                    textFieldProps={{ required: true }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default IndicatorTab;
