import React, { InputHTMLAttributes } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Button, Grid, Typography } from '@mui/material';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import IMedia from 'src/interfaces/models/IMedia';
import { InputPropertyMediasField } from 'src/modules/property/components/Form/InputPropertyMedias';

type SourceLocalProps = {
  name: string;
  media: IMedia;
  onSelectFiles: InputHTMLAttributes<HTMLInputElement>['onChange'];
  removeFile: (field: InputPropertyMediasField) => void;
  disabled?: boolean;
  fields: InputPropertyMediasField[];
  multiple: boolean;
};

const SourceLocal: React.FC<SourceLocalProps> = ({
  name,
  media,
  onSelectFiles,
  removeFile,
  disabled,
  fields,
  multiple,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <input
          id="input-file-photos"
          type="file"
          style={{ display: 'none' }}
          disabled={disabled}
          onChange={(e) => {
            if (onSelectFiles) onSelectFiles(e);
            e.target.value = '';
          }}
          accept={media.accept}
          multiple={multiple}
        />
        <label htmlFor="input-file-photos">
          <Button variant="contained" component="span" disabled={disabled}>
            Selecionar Fotos
          </Button>
        </label>

        {media.accept && (
          <Typography
            display="inline"
            variant="subtitle2"
            style={{ marginLeft: 8 }}
          >
            Extensões: {media.accept.split(',').join(', ')}.
          </Typography>
        )}

        {media.max_size && (
          <Typography
            display="inline"
            variant="subtitle2"
            style={{ marginLeft: 8 }}
          >
            Tamanho: {media.max_size} KB
          </Typography>
        )}
      </Grid>

      {fields.map((field, index) => (
        <Scope key={field.id} path={`${name}[${index}]`}>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12} textAlign="center">
                {field.file?.type.includes('image/') ? (
                  <img
                    src={field.filePreview}
                    alt={field.name}
                    style={{
                      maxWidth: '100%',
                      maxHeight: 250,
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<RemoveRedEyeIcon fontSize="inherit" />}
                    onClick={() => {
                      window.open(field.filePreview, '_blank');
                    }}
                  >
                    Ver Arquivo
                  </Button>
                )}
              </Grid>

              <Grid item xs={12} textAlign="center">
                <Button
                  variant="text"
                  size="small"
                  color="error"
                  startIcon={<DeleteIcon fontSize="inherit" />}
                  onClick={() => removeFile(field)}
                >
                  Remover
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField name="name" label="Título" required />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <CheckBox name="show_in_website" label="Mostrar no Site" />
              </Grid>
            </Grid>
          </Grid>
        </Scope>
      ))}
    </>
  );
};

export default SourceLocal;
