import { createContext } from 'react';

export type OpportunitiesTotals = {
  qty: number;
  sum: number;
};

export type OpportunitiesContextValue = {
  needsReload: boolean;
  setNeedsReload: React.Dispatch<React.SetStateAction<boolean>>;
  opportunitiesTotals: OpportunitiesTotals;
  setOpportunitiesTotals: React.Dispatch<
    React.SetStateAction<OpportunitiesTotals>
  >;
};

export const OpportunitiesContext = createContext<OpportunitiesContextValue>({
  needsReload: false,
  setNeedsReload: () => false,
  opportunitiesTotals: { qty: 0, sum: 0 },
  setOpportunitiesTotals: () => null,
});
