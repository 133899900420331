import React from 'react';

import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { MEDIA_SOURCE_OPTIONS } from 'src/constants';

type AutocompleteMediaSourcesProps = Omit<AutocompleteProps, 'options'>;

const AutocompleteMediaSources: React.FC<AutocompleteMediaSourcesProps> = ({
  ...rest
}) => {
  return <Autocomplete {...rest} options={MEDIA_SOURCE_OPTIONS} />;
};

export default AutocompleteMediaSources;
