export const PROPERTY_COMMON_QUERY_KEYS = {
  DETAILS_CONDOMINIUMS: 'common-details-condominiums',
  CONDOMINIUMS: 'common-condominiums',
  CONDOMINIUM_DETAILS: 'common-condominium-details',
  CONDOMINIUM_PECULIARITIES: 'common-condominium-peculiarities',
  PUBLICITIES_TYPES: 'common-publicities-types',
  DETAILS_PROPERTIES: 'common-details-properties',
  IDENTITIES: 'common-identities',
  IDENTITIES_OWNERS: 'common-identities-owners',
  PROPERTIES: 'common-properties',
  PROPERTY_PECULIARITIES: 'common-property-peculiarities',
  PROPERTY_KEYS: 'common-property-keys',
  PROPERTY_PHOTOS: 'common-property-photos',
  PROPERTY_VIDEOS: 'common-property-videos',
  PROPERTY_FILES: 'common-property-files',
  REALTORS: 'common-realtors',
  OFFER_BROUGHT_VIAS: 'common-offer-brought-vias',
  ECONOMIC_INDICATORS: 'common-economic-indicators',
  OFFERS: 'common-offers',
};

export const PROPERTY_ADMIN_QUERY_KEYS = {
  DETAILS_CONDOMINIUMS: 'admin-details-condominiums',
  CONDOMINIUMS: 'admin-condominiums',
  CONDOMINIUM_DETAILS: 'admin-condominium-details',
  CONDOMINIUM_PECULIARITIES: 'admin-condominium-peculiarities',
  PUBLICITIES_TYPES: 'admin-publicities-types',
  DETAILS_PROPERTIES: 'admin-details-properties',
  IDENTITIES: 'admin-identities',
  IDENTITIES_OWNERS: 'admin-identities-owners',
  PROPERTIES: 'admin-properties',
  PROPERTY_PECULIARITIES: 'admin-property-peculiarities',
  PROPERTY_KEYS: 'admin-property-keys',
  PROPERTY_PHOTOS: 'admin-property-photos',
  PROPERTY_VIDEOS: 'admin-property-videos',
  PROPERTY_FILES: 'admin-property-files',
  REALTORS: 'admin-realtors',
  OFFER_BROUGHT_VIAS: 'admin-offer-brought-vias',
  ECONOMIC_INDICATORS: 'admin-economic-indicators',
  OFFERS: 'admin-offers',
};
