export const IDENTITY_TYPE_LABEL = {
  unique: 'Única',
  multiple: 'Múltipla',
};

export const IDENTITY_TYPE_OPTIONS = [
  {
    key: 0,
    label: IDENTITY_TYPE_LABEL['unique'],
    value: 'unique',
  },
  {
    key: 1,
    label: IDENTITY_TYPE_LABEL['multiple'],
    value: 'multiple',
  },
];

export const KEY_STATUSES = {
  AVAILABLE: 'available',
  RESERVED: 'reserved',
  WITHDRAWN: 'withdrawn',
  INACTIVE: 'inactive',
};
export const KEY_STATUSES_LABEL = {
  available: 'Disponível',
  reserved: 'Reservada',
  withdrawn: 'Retirada',
  inactive: 'inativa',
};
export const KEY_STATUSES_OPTIONS = Object.values(KEY_STATUSES).map(
  (val, index) => ({
    key: index,
    label: KEY_STATUSES_LABEL[val],
    value: val,
  }),
);

export const REALTOR_TYPES = {
  REALTOR: 'realtor',
  REAL_ESTATE: 'real_estate',
};
export const REALTOR_TYPES_LABEL = {
  realtor: 'Corretor',
  real_estate: 'Imobiliária',
};
export const REALTOR_TYPES_OPTIONS = Object.values(REALTOR_TYPES).map(
  (val, index) => ({
    key: index,
    label: REALTOR_TYPES_LABEL[val],
    value: val,
  }),
);

export const ECONOMIC_INDICATOR_FREQUENCIES = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  SEMIANNUAL: 'semiannual',
  YEARLY: 'yearly',
};
export const ECONOMIC_INDICATOR_FREQUENCIES_LABEL = {
  daily: 'Diária',
  monthly: 'Mensal',
  quarterly: 'Trimestral',
  semiannual: 'Semestral',
  yearly: 'Anual',
};
export const ECONOMIC_INDICATOR_FREQUENCIES_OPTIONS = Object.values(
  ECONOMIC_INDICATOR_FREQUENCIES,
).map((val, index) => ({
  key: index,
  label: ECONOMIC_INDICATOR_FREQUENCIES_LABEL[val],
  value: val,
}));

export const OFFER_TYPES = {
  RENT: 'rent',
  SALE: 'sale',
};
export const OFFER_TYPES_LABEL = {
  rent: 'Aluguel',
  sale: 'Venda',
};
export const OFFER_TYPES_OPTIONS = Object.values(OFFER_TYPES).map(
  (val, index) => ({
    key: index,
    label: OFFER_TYPES_LABEL[val],
    value: val,
  }),
);
