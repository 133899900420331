import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { INPUT_TYPE_LABEL } from 'src/constants';

import {
  ComplementFieldData,
  PropertyTypeComplementsFieldsProps,
} from './interfaces';

const PropertyTypeComplementsFields: React.FC<PropertyTypeComplementsFieldsProps> = ({
  propertyType,
  currentValues,
  formRef,
  path = 'complements',
}) => {
  const [complementsFields, setComplementsFields] = useState<
    ComplementFieldData[]
  >([]);

  useEffect(() => {
    const complements = propertyType?.complements?.map((complement) => {
      const exists = currentValues?.find(
        (current) => current.address_complement_id == complement.id,
      );
      return {
        id: exists?.id,
        address_complement_id: complement.id,
        value: exists?.value || '',
        addressComplement: complement,
      } as ComplementFieldData;
    });

    setComplementsFields(complements || []);
  }, [propertyType, currentValues]);

  useEffect(() => {
    complementsFields.map((complementField, index) => {
      formRef?.current?.setFieldValue(
        `${path}[${index}].value`,
        complementField.value,
      );
    });
  }, [complementsFields, formRef, path]);

  return (
    <>
      {complementsFields.map((complementField, index) => (
        <Scope
          key={complementField.address_complement_id}
          path={`${path}[${index}]`}
        >
          <TextField name="id" value={complementField.id} disabled hidden />
          <TextField
            name="is_required"
            value={complementField.addressComplement?.is_required}
            disabled
            hidden
          />
          <TextField
            name="address_complement_id"
            value={complementField.address_complement_id}
            disabled
            hidden
          />
          <Grid item xs={12} sm={4}>
            {complementField.addressComplement?.input_type === 'boolean' ? (
              <CheckBox
                name="value"
                label={complementField.addressComplement?.name}
                returnAs="string"
              />
            ) : (
              <TextField
                name="value"
                label={`${complementField.addressComplement?.name} (${
                  INPUT_TYPE_LABEL[
                    complementField.addressComplement?.input_type || ''
                  ]
                })`}
                required={complementField.addressComplement?.is_required}
                type={complementField.addressComplement?.input_type}
              />
            )}
          </Grid>
        </Scope>
      ))}
    </>
  );
};

export default PropertyTypeComplementsFields;
