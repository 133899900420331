import yup from 'src/libs/yup';

const OpportunitiesFilterSchema = yup.object().shape({
  client_search: yup.string().label('Cliente'),
  attendants: yup.array().of(yup.number()).nullable().label('Atendentes'),
  attendances_description: yup.string().label('Descrição de Atendimento'),
  status: yup.string().nullable().label('Status'),
  is_waiting: yup.boolean().nullable().label('Em Espera'),
});

export default OpportunitiesFilterSchema;
