import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import EditButton from 'src/components/Table/EditButton';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { IClient } from 'src/interfaces/models/IClient';
import masks from 'src/utils/masks';
import ClientFilterSchema from 'src/validators/Client/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'username',
    headerName: 'Cliente',
    flex: 1,
    renderCell({ row }) {
      return (
        <div>
          {row.username && (
            <Typography variant="body2">{row.username}</Typography>
          )}
          <Typography variant="body2">{row.email}</Typography>
        </div>
      );
    },
  },
  {
    field: 'type',
    headerName: 'Tipo',
    valueGetter({ value }) {
      switch (value) {
        case 'F':
          return 'PF';
        case 'J':
          return 'PJ';
        default:
          return 'C';
      }
    },
  },
  {
    field: 'name',
    headerName: 'Pessoa / Empresa',
    flex: 1,
    renderCell({ row }) {
      return (
        <div>
          <Typography variant="body2">
            {row.type === 'F' ? row.person?.name : row.enterprise?.company_name}
          </Typography>
          <Typography variant="body2">
            {row.type === 'F'
              ? masks.cpf(row.person?.document)
              : masks.cnpj(row.enterprise?.document)}
          </Typography>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    renderCell(params) {
      return <StatusIcon status={params.row.status} />;
    },
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    renderCell(params) {
      return (
        <Can permissions={['update-clients']}>
          <EditButton to={`/clientes/${params.id}`} />
        </Can>
      );
    },
  },
];

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
  } = usePagination<IClient>('admin/clients');

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Clientes</Typography>

            <Can permissions={['store-clients']}>
              <LinkButton
                to="/clientes/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                showFilters
                filterProps={{
                  yupSchema: ClientFilterSchema,
                  children: <Filters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
