import yup from 'src/libs/yup';

export const PropertyPeculiarityStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  value: yup.string().required().label('Valor'),
});

export const PropertyPeculiarityUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  value: yup.string().required().label('Valor'),
});
