import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Tooltip } from '@mui/material';
import { Opportunity } from 'src/modules/attendance/@types/models';

type OpportunityHasProductsProps = {
  opportunity: Opportunity;
};

const OpportunityHasProducts: React.FC<OpportunityHasProductsProps> = ({
  opportunity,
}) => {
  if (opportunity.has_products) {
    return (
      <Grid item>
        <Tooltip title="Tem interesse com produtos">
          <CircleIcon fontSize="inherit" color="warning" />
        </Tooltip>
      </Grid>
    );
  }

  return null;
};

export default OpportunityHasProducts;
