import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { PropertyMedia } from 'src/modules/property/@types/models';
import { SaveIdentityPropertyMediaParams } from 'src/modules/property/@types/params';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import PropertyMediaFilterSchema from 'src/modules/property/validators/PropertyMedia/filter.schema';
import { propertyMediaIsImage } from 'src/utils/medias';

import Filters from './Filters';

const List: FC = () => {
  const { url } = useRouteMatch();
  const params = useParams<SaveIdentityPropertyMediaParams>();
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination<PropertyMedia>((queryConfig) =>
    propertyApi.admin.properties.files.usePropertyFilesQuery(
      params.propertyId,
      queryConfig,
    ),
  );

  const columns: GridColDef<PropertyMedia>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'url',
      align: 'center',
      headerName: 'URL',
      headerAlign: 'center',
      renderCell({ row }) {
        const isImage = propertyMediaIsImage(row);
        return isImage ? (
          <a href={row.url} target="_blank" rel="noreferrer">
            <img src={row.url} width="100%" alt={row.name} />
          </a>
        ) : (
          <LinkButton
            href={row.url}
            target="_blank"
            size="small"
            startIcon={<RemoveRedEyeIcon fontSize="inherit" />}
          >
            Abrir
          </LinkButton>
        );
      },
    },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'show_in_website',
      align: 'center',
      headerName: 'Site',
      headerAlign: 'center',
      valueGetter({ row }) {
        return row.show_in_website ? 'Sim' : 'Não';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ row }) {
        return (
          <TableActions
            resourceName="Arquivo"
            resourceId={row.id}
            resourceStatus={row.status}
            editLink={`${url}/${row.id}`}
            editPermissions={[PROPERTY_PERMISSIONS.PROPERTIES.UPDATE]}
            canDelete
            destroyApiEndpoint={(resourceId, config) =>
              propertyApi.admin.properties.files.destroyPropertyFile(
                row.property_id,
                resourceId,
                config,
              )
            }
            destroyPermissions={[PROPERTY_PERMISSIONS.PROPERTIES.DESTROY]}
            restoreApiEndpoint={(resourceId, config) =>
              propertyApi.admin.properties.files.restorePropertyFile(
                row.property_id,
                resourceId,
                config,
              )
            }
            restorePermissions={[PROPERTY_PERMISSIONS.PROPERTIES.RESTORE]}
            descriptionPrefix={`O Arquivo ${row.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="end" alignItems="center">
          <Can
            behavior="disable"
            permissions={[PROPERTY_PERMISSIONS.PROPERTIES.STORE]}
          >
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Can>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Table
              autoHeight
              rows={response?.data.data || []}
              pageSize={response?.data.meta.per_page}
              rowCount={response?.data.meta.total}
              columns={columns}
              loading={isLoading}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              canSearch
              showFilters
              filterProps={{
                yupSchema: PropertyMediaFilterSchema,
                children: <Filters />,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default List;
