import React, { createContext } from 'react';

export type InterestProductData = {
  id: number;
  reference_code?: string;
  type?: string;
  name?: string;
  price?: string;
  quantity?: string;
};

export type InterestData = {
  id: number;
  description: string;
  products: InterestProductData[];
};

export type AttendanceData = {
  lead_name?: string;
  amount: string;
  funnel_step_id: number;
  description?: string;
};

export type ContextData = {
  interests: InterestData[];
  attendance?: AttendanceData;
};

type ContextType = {
  funnelId: number;
  activeStep: number;
  setActiveStep: (index: number) => void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  data: ContextData;
  setData: React.Dispatch<React.SetStateAction<ContextData>>;
  onClose: () => void;
  submitAttendance: (formData: AttendanceData) => void;
};

export const StoreOpportunityAttendanceDialogContext = createContext<ContextType>(
  {
    funnelId: 0,
    activeStep: 0,
    setActiveStep: () => null,
    loading: false,
    setLoading: () => null,
    data: {
      interests: [],
    },
    setData: () => null,
    onClose: () => null,
    submitAttendance: () => null,
  },
);
