import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

// import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
} from '@mui/material';
import SearchClientDialog from 'src/components/_functions/SearchClientDialog';
import Can from 'src/components/Can';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import OmnichannelContext from 'src/pages/Private/Omnichannel/context';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { queryToObject } from 'src/utils/helpers';

import ChatContent from './ChatContent';
import ChatItem from './ChatItem';
import { Container, Side } from './styles';

const OmnichannelChat: FC = () => {
  const searchClientRef = useRef<CustomDialogRef>(null);
  const { search } = useLocation();
  const routeClientSelectedRef = useRef(false);
  const {
    department,
    chats,
    selectedChat,
    setSelectedChat,
    adminView,
    setAdminView,
  } = useContext(OmnichannelContext);

  // function changeDepartment() {
  //   setDepartment(undefined);
  // }

  const handleNewChat = useCallback(
    (clientId: number) => {
      const chat = chats.find((chat) => chat.client_id === clientId);
      if (chat) {
        setSelectedChat(chat);
      } else {
        WSOmnichannel.socket?.emit(
          'new_chat',
          { client_id: clientId },
          (result) => {
            if (result.error) {
              toast.error(result.error.message);
            } else {
              // setChats((data) => [...data, result.data]);
            }
          },
        );
      }

      searchClientRef.current?.hide();
    },
    [chats, setSelectedChat],
  );

  useEffect(() => {
    const queryParams = queryToObject(search);
    if (
      queryParams.client_id &&
      chats.length &&
      !routeClientSelectedRef.current
    ) {
      handleNewChat(Number(queryParams.client_id));
      routeClientSelectedRef.current = true;
    }
  }, [chats, search, handleNewChat]);

  return (
    <Container>
      <Side isChatOpen={!!selectedChat}>
        <List disablePadding>
          <ListItem>
            <ListItemText>Conversas ({department?.name})</ListItemText>

            <ListItemSecondaryAction>
              {/* <IconButton
                size="small"
                color="error"
                onClick={changeDepartment}
                title="Mudar departamento"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton> */}

              <IconButton size="small" onClick={searchClientRef.current?.show}>
                <SearchIcon fontSize="inherit" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          <Divider />
        </List>

        <Can permissions={['admin']} behavior="hide">
          <List disablePadding>
            <ListItem>
              <Tooltip title="Conversas não serão marcadas como lidas.">
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={adminView}
                      onChange={(e, checked) => setAdminView(checked)}
                    />
                  }
                  label="Visão Admin"
                  slotProps={{ typography: { variant: 'body2' } }}
                />
              </Tooltip>
            </ListItem>

            <Divider />
          </List>
        </Can>

        <List className="chat-list">
          {chats.map((chat) => (
            <ChatItem key={chat._id} chat={chat} />
          ))}
        </List>
      </Side>

      {selectedChat && <ChatContent />}

      <SearchClientDialog
        ref={searchClientRef}
        onSelectClient={(client) => handleNewChat(client.id)}
      />
    </Container>
  );
};

export default OmnichannelChat;
