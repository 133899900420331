import styled from 'styled-components';

import Kanban from 'src/components/Kanban';

export const Container = styled(Kanban.Container)`
  flex: 1;
  padding: 4px;
`;

export const Column = styled(Kanban.Column)``;

export const ColumnHeader = styled(Kanban.ColumnHeader)`
  padding: 12px;
  text-align: center;
`;

export const ColumnBody = styled(Kanban.ColumnBody)`
  padding: 8px 12px;
`;
