import { IRouteInterface } from 'src/interfaces/IRoutes';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';

import PrivatePages from '../pages/Private';

export const PropertyRouteList: IRouteInterface[] = [
  {
    exact: true,
    component: PrivatePages.Condominiums.List,
    label: 'Condomínios',
    path: '/imovel/condominios',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.CONDOMINIUMS.LIST],
  },
  {
    component: PrivatePages.Condominiums.Save,
    label: 'Novo Condomínios',
    path: '/imovel/condominios/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.CONDOMINIUMS.STORE],
  },
  {
    component: PrivatePages.Condominiums.Save,
    label: 'Editar Condomínios',
    path: '/imovel/condominios/:id/:selectedTab?',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.CONDOMINIUMS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.DetailsCondominiums.List,
    label: 'Detalhes de Condomínios',
    path: '/imovel/detalhes-condominios',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.DETAILS_CONDOMINIUMS.LIST],
  },
  {
    component: PrivatePages.DetailsCondominiums.Save,
    label: 'Novo Detalhe de Condomínios',
    path: '/imovel/detalhes-condominios/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.DETAILS_CONDOMINIUMS.STORE],
  },
  {
    component: PrivatePages.DetailsCondominiums.Save,
    label: 'Editar Detalhe de Condomínios',
    path: '/imovel/detalhes-condominios/:id',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.DETAILS_CONDOMINIUMS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.PublicitiesTypes.List,
    label: 'Tipos de Publicidades',
    path: '/imovel/tipos-publicidades',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.LIST],
  },
  {
    component: PrivatePages.PublicitiesTypes.Save,
    label: 'Novo Tipo de Publicidade',
    path: '/imovel/tipos-publicidades/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.STORE],
  },
  {
    component: PrivatePages.PublicitiesTypes.Save,
    label: 'Editar Tipo de Publicidade',
    path: '/imovel/tipos-publicidades/:id',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.DetailsProperties.List,
    label: 'Detalhes de Imóveis',
    path: '/imovel/detalhes-imoveis',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.LIST],
  },
  {
    component: PrivatePages.DetailsProperties.Save,
    label: 'Novo Detalhe de Imóveis',
    path: '/imovel/detalhes-imoveis/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.STORE],
  },
  {
    component: PrivatePages.DetailsProperties.Save,
    label: 'Editar Detalhe de Imóveis',
    path: '/imovel/detalhes-imoveis/:id',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Identities.List,
    label: 'Imóveis',
    path: '/imovel/identidades',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.PROPERTIES.LIST],
  },
  {
    component: PrivatePages.Identities.Store,
    label: 'Novo Imóvel',
    path: '/imovel/identidades/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.PROPERTIES.STORE],
  },
  {
    component: PrivatePages.Identities.Update,
    label: 'Editar Imóvel',
    path: '/imovel/identidades/:id/:selectedTab?',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Realtors.List,
    label: 'Imobiliárias',
    path: '/imovel/imobiliarias',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.REALTORS.LIST],
  },
  {
    component: PrivatePages.Realtors.Save,
    label: 'Nova Imobiliária',
    path: '/imovel/imobiliarias/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.REALTORS.STORE],
  },
  {
    component: PrivatePages.Realtors.Save,
    label: 'Editar Imobiliária',
    path: '/imovel/imobiliarias/:id',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.REALTORS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.OfferBroughtVias.List,
    label: 'Motivo de Ofertas',
    path: '/imovel/motivos-ofertas',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.LIST],
  },
  {
    component: PrivatePages.OfferBroughtVias.Save,
    label: 'Novo Motivo de Oferta',
    path: '/imovel/motivos-ofertas/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.STORE],
  },
  {
    component: PrivatePages.OfferBroughtVias.Save,
    label: 'Editar Motivo de Oferta',
    path: '/imovel/motivos-ofertas/:id',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.EconomicIndicators.List,
    label: 'Indicador Econômicos',
    path: '/imovel/indicadores-economicos',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.LIST],
  },
  {
    component: PrivatePages.EconomicIndicators.Save,
    label: 'Novo Indicador Econômico',
    path: '/imovel/indicadores-economicos/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.STORE],
  },
  {
    component: PrivatePages.EconomicIndicators.Save,
    label: 'Editar Indicador Econômico',
    path: '/imovel/indicadores-economicos/:id/:selectedTab?',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.UPDATE],
  },
  {
    exact: true,
    component: PrivatePages.Offers.List,
    label: 'Ofertas',
    path: '/imovel/ofertas',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.OFFERS.LIST],
  },
  {
    component: PrivatePages.Offers.Save,
    label: 'Nova Oferta',
    path: '/imovel/ofertas/novo',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.OFFERS.STORE],
  },
  {
    component: PrivatePages.Offers.Save,
    label: 'Editar Oferta',
    path: '/imovel/ofertas/:id',
    type: 'private',
    permissions: [PROPERTY_PERMISSIONS.OFFERS.UPDATE],
  },
];
