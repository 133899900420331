import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { OfferBroughtVia } from 'src/modules/property/@types/models';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  OfferBroughtViaStoreSchema,
  OfferBroughtViaUpdateSchema,
} from 'src/modules/property/validators/OfferBourghtVia/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const schema = params.id
    ? OfferBroughtViaUpdateSchema
    : OfferBroughtViaStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const {
          data: offerBroughtVia,
        } = await propertyApi.admin.offerBroughtVias.getOfferBroughtVia(
          params.id,
        );
        setStatus(offerBroughtVia.status);

        setTimeout(() => {
          formRef.current?.setData(offerBroughtVia);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<OfferBroughtVia> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(schema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newRealtor: OfferBroughtVia;
      if (params.id) {
        const response = await propertyApi.admin.offerBroughtVias.updateOfferBroughtVia(
          params.id,
          data,
        );
        newRealtor = response.data;
      } else {
        const response = await propertyApi.admin.offerBroughtVias.storeOfferBroughtVia(
          data,
        );
        newRealtor = response.data;
      }

      toast.success('Motivo de Oferta salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/imovel/motivos-ofertas/${newRealtor.id}`);
      } else {
        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', schema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.offerBroughtVias.destroyOfferBroughtVia(
        params.id,
      );

      toast.success('Motivo de Oferta desativado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao desativar Motivo de Oferta',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.offerBroughtVias.restoreOfferBroughtVia(
        params.id,
      );

      toast.success('Motivo de Oferta restaurado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar Motivo de Oferta.',
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.STORE],
          update: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.UPDATE],
          destroy: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.DESTROY],
          restore: [PROPERTY_PERMISSIONS.OFFER_BROUGHT_VIAS.RESTORE],
        }}
        resourceName="Motivo de Oferta"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={status === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Motivo de Oferta
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField name="name" label="Nome" required />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
