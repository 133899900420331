import React from 'react';

import { Card, CardContent, Divider, Grid } from '@mui/material';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import DatePicker from 'src/components/Form/DatePicker';
import TextField from 'src/components/Form/TextField';

const PropertyInfos: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Scope path="info">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider textAlign="left">Informações</Divider>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField name="iptu_code" label="Código do IPTU" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="iptu_parcel_amount"
                label="Valor do IPTU"
                mask="currency"
                returnUnmasked
              />
            </Grid>

            <Grid item alignSelf="center" xs={12} md={4}>
              <CheckBox
                name="iptu_managed"
                label="IPTU Gerenciado pela Rossi"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField name="registration_code" label="Nº da Matrícula" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField name="energy_code" label="Código de Energia" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField name="water_code" label="Código de Água" />
            </Grid>

            <Grid item alignSelf="center" xs={12} md={4}>
              <CheckBox name="has_deed" label="Possui Escritura" />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                name="occupation_license_at"
                label="Data do Habite-se"
                returnAsDate
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="condominium_amount"
                label="Valor do Condomínio"
                mask="currency"
                returnUnmasked
              />
            </Grid>
          </Grid>
        </Scope>
      </CardContent>
    </Card>
  );
};

export default PropertyInfos;
