import React from 'react';
import { MessageBox } from 'react-chat-elements';

import { formatDistanceToNow } from 'date-fns';
import { MessageProps } from 'src/pages/Private/Omnichannel/Chat/ChatContent/Message';
import OminichannelUtils from 'src/utils/omnichannel';

const Image: React.FC<MessageProps> = ({ message, onReply, reply }) => {
  if (!message.whatsapp) return null;

  function openImage() {
    if (!message.whatsapp?.image?.imageUrl) {
      return;
    }

    window.open(message.whatsapp.image.imageUrl, '_blank');
  }

  return (
    <MessageBox
      id={message.whatsapp.messageId}
      notch
      removeButton={false}
      reply={reply}
      replyButton={!!onReply}
      onReplyClick={onReply}
      onOpen={openImage}
      retracted={false}
      status={OminichannelUtils.getChatMessageStatus(message)}
      position={message.direction === 'outgoing' ? 'right' : 'left'}
      type="photo"
      styles={{ objectFit: 'contain' }}
      data={{
        status: {
          click: true,
          download: true,
          error: false,
          autoDownload: false,
          loading: false,
        },
        uri: message.whatsapp.image?.thumbnailUrl || '',
        alt: message.whatsapp.image?.caption,
      }}
      title={''}
      titleColor=""
      text={message.whatsapp.image?.caption || ''}
      date={new Date(message.sended_at)}
      dateString={formatDistanceToNow(new Date(message.sended_at), {
        addSuffix: true,
        includeSeconds: true,
      })}
      focus={false}
      forwarded={message.whatsapp.forwarded}
    />
  );
};

export default Image;
