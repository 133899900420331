import {
  ForwardRefRenderFunction,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColumns } from '@mui/x-data-grid';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import TextField from 'src/components/Form/TextField';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import { TableProps } from 'src/components/Table/interfaces';
import { INITIAL_INFO } from 'src/hooks/usePagination/interfaces';
import { IAddress } from 'src/interfaces/models/IAddress';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';

import { SearchAddressDialogRef, SearchAddressDialogProps } from './interfaces';

const CustomDialog: ForwardRefRenderFunction<
  SearchAddressDialogRef,
  SearchAddressDialogProps
> = ({ onClose, onSelectAddress, ...rest }, ref) => {
  const searchFormRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<IAddress[]>([]);
  const [info, setInfo] = useState(INITIAL_INFO);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => setOpen(true),
    hide: () => setOpen(false),
  }));

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'zip_code',
      headerName: 'CEP',
      width: 120,
      valueGetter({ row }) {
        return masks.zip_code(row.zip_code);
      },
    },
    {
      field: 'address',
      headerName: 'Endereço',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">
              {row.city?.name} - {row.neighborhood?.name}
            </Typography>
            <Typography variant="body2">{row.street?.name}</Typography>
          </div>
        );
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ row }) {
        return (
          <IconButton
            size="medium"
            color="primary"
            onClick={() => onSelectAddress(row as IAddress)}
            title="Selecionar"
          >
            <CheckIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
  ];

  const handleClose = useCallback<
    NonNullable<SearchAddressDialogProps['onClose']>
  >(
    (event, reason) => {
      setOpen(false);

      if (onClose) onClose(event, reason);
    },
    [onClose],
  );

  const loadAddresses = useCallback(
    async (page = 1, perPage = 10, filters = {}) => {
      try {
        setLoading(true);

        const { data: responseData } = await apiAdminPublic.getAddresses({
          params: {
            page,
            per_page: perPage,
            ...filters,
          },
        });

        setInfo(responseData.meta || INITIAL_INFO);
        setData(responseData.data || []);
      } catch (error) {
        handleApiResponseErrors(
          error.response,
          'Ocorreu um erro na busca dos dados.',
        );
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handlePageChange: NonNullable<TableProps['onPageChange']> = async (
    page,
  ) => {
    await loadAddresses(page + 1, info.per_page, filters);
  };

  const handlePageSizeChange: NonNullable<
    TableProps['onPageSizeChange']
  > = async (pageSize) => {
    await loadAddresses(info.current_page, pageSize, filters);
  };

  const handleSubmit: SubmitHandler = async (formData) => {
    setFilters(formData);

    await loadAddresses(1, 10, formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" {...rest}>
      <DialogTitle>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          Selecionar Endereço
          <Can permissions={['store-addresses']}>
            <LinkButton
              to="/enderecos/endereco/novo"
              target="_blank"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              size="small"
            >
              Novo
            </LinkButton>
          </Can>
        </Grid>
      </DialogTitle>

      <Form ref={searchFormRef} onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={3}>
              <TextField
                name="zip_code"
                label="CEP"
                mask="zip_code"
                returnUnmasked
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="search"
                label="Endereço"
                placeholder="Cidade / Bairro / Rua"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SearchIcon />}
                fullWidth
                onClick={() => searchFormRef.current?.submitForm()}
              >
                Pesquisar
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Table
                autoHeight
                disableDensitySelector
                disableColumnSelector
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Form>
    </Dialog>
  );
};

export default forwardRef(CustomDialog);
