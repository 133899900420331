import { FC, useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import { IconButton, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import CustomDialog from 'src/components/CustomDialog';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import Table from 'src/components/Table';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import {
  IClientPerson,
  IClientPersonSpouse,
} from 'src/interfaces/models/IClient';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';

import AddForm from './AddForm';

const List: FC = () => {
  const addDialogRef = useRef<CustomDialogRef>(null);
  const deleteDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);
  const [
    currentSpouse,
    setCurrentSpouse,
  ] = useState<IClientPersonSpouse | null>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const params = useParams<ITabEditParams>();

  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
    loadData,
  } = usePagination<IClientPerson>(`admin/clients/${params.id}/spouses`);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      valueGetter({ row }) {
        return row.spouse?.name;
      },
    },
    {
      field: 'document',
      headerName: 'CPF',
      flex: 1,
      valueGetter({ row }) {
        return masks.cpf(String(row.spouse?.document));
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell({ row }) {
        return <StatusIcon status={row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ row }) {
        return (
          <Can permissions={['destroy-client-spouses']}>
            {row.status === 'active' && (
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setCurrentSpouse(row.spouse as IClientPersonSpouse);
                  deleteDialogRef.current?.show();
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            )}
            {row.status === 'inactive' && (
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  setCurrentSpouse(row.spouse as IClientPersonSpouse);
                  restoreDialogRef.current?.show();
                }}
              >
                <RestoreIcon fontSize="inherit" />
              </IconButton>
            )}
          </Can>
        );
      },
    },
  ];

  const handleAddSubmit = useCallback(() => {
    addDialogRef.current?.hide();
    loadData();
  }, [loadData]);

  const handleDeleteConfirm = async () => {
    try {
      setConfirmLoading(true);

      await api.delete(
        `admin/clients/${params.id}/spouses/${currentSpouse?.id}`,
      );

      toast.success('Cônjuge inativado com sucesso!');
      deleteDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao inativar cônjuge.');
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleRestoreConfirm = async () => {
    try {
      setConfirmLoading(true);

      await api.post(
        `admin/clients/${params.id}/spouses/${currentSpouse?.id}/restore`,
      );

      toast.success('Cônjuge restaurado com sucesso!');
      restoreDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao restaurar cônjuge.');
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Cônjuges</Typography>

            <Can permissions={['store-client-spouses']}>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
                onClick={() => addDialogRef.current?.show()}
              >
                Novo
              </Button>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CustomDialog ref={addDialogRef} title="Novo Cônjuge">
        <AddForm onSubmit={handleAddSubmit} />
      </CustomDialog>

      <ConfirmDialog
        ref={deleteDialogRef}
        title="Inativar Cônjuge"
        description="Também será inativado o vínculo do cônjuge com esse cliente."
        loading={confirmLoading}
        onConfirm={handleDeleteConfirm}
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title="Reativar Cônjuge"
        description="Também será reativado o vínculo do cônjuge com esse cliente."
        loading={confirmLoading}
        confirmColor="primary"
        onConfirm={handleRestoreConfirm}
      />
    </>
  );
};

export default List;
