import yup from 'src/libs/yup';

export const BankAccountStoreSchema = yup.object().shape({
  bank_id: yup.string().required().label('Banco'),
  agency: yup.string().required().label('Agência'),
  agency_digit: yup.string().label('Dígito'),
  account: yup.string().required().label('Conta'),
  account_type: yup.string().required().label('Tipo da Conta'),
  holder_name: yup.string().required().label('Titular'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Nascimento'),
  document: yup.string().cpfCnpj().required().label('CPF / CNPJ'),
  pix_key_type: yup.string().label('Tipo Pix'),
  pix_key: yup.string().label('Chave Pix'),
  payment_type: yup.string().required().label('Forma de Pagamento'),
  payment_day: yup.string().required().label('Dia de Pagamento'),
});

export const BankAccountUpdateSchema = yup.object().shape({
  bank_id: yup.string().required().label('Banco'),
  agency: yup.string().required().label('Agência'),
  agency_digit: yup.string().label('Dígito'),
  account: yup.string().required().label('Conta'),
  account_type: yup.string().required().label('Tipo da Conta'),
  holder_name: yup.string().required().label('Titular'),
  birth_date: yup.string().dateFormat('dd/MM/yyyy').label('Nascimento'),
  document: yup.string().cpfCnpj().required().label('CPF / CNPJ'),
  pix_key_type: yup.string().label('Tipo Pix'),
  pix_key: yup.string().label('Chave Pix'),
  payment_type: yup.string().required().label('Forma de Pagamento'),
  payment_day: yup.string().required().label('Dia de Pagamento'),
});
