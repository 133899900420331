import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { EconomicIndicator } from 'src/modules/property/@types/models';
import { ECONOMIC_INDICATOR_FREQUENCIES_LABEL } from 'src/modules/property/constants';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import EconomicIndicatorFilterSchema from 'src/modules/property/validators/EconomicIndicator/filter.schema';

import EconomicIndicatorsFilters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination(
    propertyApi.admin.economicIndicators.useEconomicIndicatorsQuery,
  );

  const columns: GridColDef<EconomicIndicator>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'frequency',
      headerName: 'Frequência',
      valueGetter({ value }) {
        return ECONOMIC_INDICATOR_FREQUENCIES_LABEL[value];
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Indicador Econômico"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/imovel/indicadores-economicos/${id}`}
            editPermissions={[PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.UPDATE]}
            destroyApiEndpoint={
              propertyApi.admin.economicIndicators.destroyEconomicIndicator
            }
            destroyPermissions={[
              PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.DESTROY,
            ]}
            restoreApiEndpoint={
              propertyApi.admin.economicIndicators.restoreEconomicIndicator
            }
            restorePermissions={[
              PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.RESTORE,
            ]}
            descriptionPrefix={`O Indicador Econômico ${row.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Indicadores Econômicos</Typography>

            <Can
              behavior="disable"
              permissions={[PROPERTY_PERMISSIONS.ECONOMIC_INDICATORS.STORE]}
            >
              <LinkButton
                to="/imovel/indicadores-economicos/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                canSearch
                showFilters
                filterProps={{
                  yupSchema: EconomicIndicatorFilterSchema,
                  children: <EconomicIndicatorsFilters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
