import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { PropertyMedia } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getPropertyPhotos(
  propertyId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<PropertyMedia>>(
    `/property/admin/properties/${propertyId}/photos`,
    config,
  );

  return response;
}

function usePropertyPhotosQuery(
  propertyId: string | number,
  data?: IApiUseQueryData<PropertyMedia>,
) {
  return useQuery<IApiUseQueryFnData<PropertyMedia>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_PHOTOS,
      propertyId,
      data?.requestConfig?.params,
    ],
    () => getPropertyPhotos(propertyId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePropertyPhotosQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PROPERTY_PHOTOS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_PHOTOS]);
}

async function storePropertyPhoto(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<PropertyMedia>(
    `/property/admin/properties/${propertyId}/photos`,
    data,
    config,
  );

  invalidatePropertyPhotosQueries();

  return response;
}

async function getPropertyPhoto(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PropertyMedia>(
    `/property/admin/properties/${propertyId}/photos/${id}`,
    config,
  );

  return response;
}

async function updatePropertyPhoto(
  propertyId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PropertyMedia>(
    `/property/admin/properties/${propertyId}/photos/${id}`,
    data,
    config,
  );

  invalidatePropertyPhotosQueries();

  return response;
}

async function destroyPropertyPhoto(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${propertyId}/photos/${id}`,
    config,
  );

  invalidatePropertyPhotosQueries();

  return response;
}

async function restorePropertyPhoto(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/properties/${propertyId}/photos/${id}/restore`,
    config,
  );

  invalidatePropertyPhotosQueries();

  return response;
}

export const photos = {
  getPropertyPhotos,
  usePropertyPhotosQuery,
  storePropertyPhoto,
  getPropertyPhoto,
  updatePropertyPhoto,
  destroyPropertyPhoto,
  restorePropertyPhoto,
};
