import { AxiosRequestConfig } from 'axios';
import { IClient } from 'src/interfaces/models/IClient';
import { adminAxios } from 'src/services/api/admin';
import { IAxiosResponseAPI } from 'src/services/api/interfaces';

export async function getClients(
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<IClient>> {
  const response = await adminAxios.get('clients', config);

  return response;
}

export async function storeClientBond(
  client_id: string | number,
  bond_id: string | number,
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<boolean>> {
  const response = await adminAxios.post(
    `clients/${client_id}/bonds`,
    { bond_id },
    config,
  );

  return response;
}

export async function destroyClientBond(
  client_id: string | number,
  bond_id: string | number,
  config?: AxiosRequestConfig,
): Promise<IAxiosResponseAPI<boolean>> {
  const response = await adminAxios.delete(
    `clients/${client_id}/bonds/${bond_id}`,
    config,
  );

  return response;
}
