import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import apiAdminPublic from 'src/services/api/adminPublic';

type AutocompleteClientsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps, 'options'>;

const AutocompleteClients: React.FC<AutocompleteClientsProps> = ({
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } = apiAdminPublic.useClientsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((client) => ({
      key: client.id,
      label: client.username,
      value: client.id,
      data: client,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteClients;
