import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import AutocompletePropertyTypes from 'src/components/Form/_Common/AutocompletePropertyTypes';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import PropertyTypeComplementsFields from 'src/components/Form/PropertyTypeComplementsFields';
import { CurrentComplementsFieldsData } from 'src/components/Form/PropertyTypeComplementsFields/interfaces';
import PropertyTypeDetailsFields from 'src/components/Form/PropertyTypeDetailsFields';
import { CurrentDetailsFieldsData } from 'src/components/Form/PropertyTypeDetailsFields/interfaces';
import TextField from 'src/components/Form/TextField';
import IPropertyType from 'src/interfaces/models/IPropertyType';
import { Identity } from 'src/modules/property/@types/models';
import AutocompletePublicitiesTypes from 'src/modules/property/components/Form/_common/AutocompletePublicitiesTypes';
import { StoreIdentityContext } from 'src/modules/property/pages/Private/Identities/Store/context';
import { IdentityStoreStepPropertySchema } from 'src/modules/property/validators/Identity/save.schema';
import yupValidate from 'src/utils/yupValidate';

import PropertyInfos from './Infos';
import PropertyOwners, { CurrentOwnersFieldsData } from './Owners';

export type PropertyDialogProps = {
  onClose: () => void;
  onSuccess: (propertyData: PropertyDialogFormData) => void;
  identityType?: Identity['type'];
  currentProperty?: PropertyDialogFormData;
} & DialogProps;

export type PropertyDialogFormData = {
  id?: string;
  title: string;
  description?: string;
  property_type_id: number;
  type?: IPropertyType;
  complements?: CurrentComplementsFieldsData;
  details?: CurrentDetailsFieldsData;
  owners?: CurrentOwnersFieldsData;
  info?: CurrentDetailsFieldsData;
};

const PropertyDialog: React.FC<PropertyDialogProps> = ({
  onSuccess,
  onClose,
  identityType = 'unique',
  currentProperty,
  ...rest
}) => {
  const { selectedCondominium } = useContext(StoreIdentityContext);
  const propertyFormRef = useRef<FormHandles>(null);
  const [propertyType, setPropertyType] = useState<IPropertyType>();

  async function handleSubmit(formData: PropertyDialogFormData) {
    const { success, errors } = await yupValidate(
      IdentityStoreStepPropertySchema,
      formData,
    );

    if (!success) {
      propertyFormRef?.current?.setErrors(errors);
      return toast.error('Verifique os dados informados.');
    }

    if (!formData.id) formData.id = Math.random().toString();
    formData.type = propertyType;
    onSuccess(formData);
    onClose();
  }

  function handleClose() {
    onClose();
  }

  function handleChangePropertyType(value: AutocompleteValue<IPropertyType>) {
    if (Array.isArray(value) || !value) return setPropertyType(undefined);

    setPropertyType(value.data);
  }

  useEffect(() => {
    if (!rest.open) setPropertyType(undefined);
  }, [rest.open]);

  useEffect(() => {
    propertyFormRef.current?.setErrors({});
  }, [propertyType]);

  function getInitialData() {
    const info = {
      condominium_amount: selectedCondominium?.amount,
      ...currentProperty?.info,
    };

    return { ...currentProperty, info };
  }

  return (
    <Dialog fullWidth maxWidth="lg" {...rest}>
      <DialogTitle align="center">Imóvel</DialogTitle>

      <Form
        ref={propertyFormRef}
        onSubmit={handleSubmit}
        initialData={getInitialData()}
        noValidate
      >
        <DialogContent>
          <Grid container spacing={1}>
            <TextField hidden name="id" value={currentProperty?.id} />
            <Grid item xs={12}>
              <TextField name="title" label="Título (Site)" required />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="description"
                label="Descrição (Site)"
                multiline
                rows={4}
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompletePublicitiesTypes
                name="publicities"
                label="Tipos de Publicidades"
                multiple
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompletePropertyTypes
                name="property_type_id"
                label="Tipo"
                textFieldProps={{ required: true }}
                requestConfig={{
                  params: { preload: ['complements', 'details'] },
                }}
                onChange={handleChangePropertyType}
              />
            </Grid>

            {propertyType && (
              <>
                <Grid item xs={12}>
                  <Divider textAlign="left">Complemento de Endereço</Divider>
                </Grid>
                <PropertyTypeComplementsFields
                  path="complements"
                  propertyType={propertyType}
                  formRef={propertyFormRef}
                  currentValues={currentProperty?.complements}
                />
              </>
            )}

            {propertyType && (
              <>
                <Grid item xs={12}>
                  <Divider textAlign="left">Detalhes do Imóvel</Divider>
                </Grid>
                <PropertyTypeDetailsFields
                  path="details"
                  propertyType={propertyType}
                  formRef={propertyFormRef}
                  currentValues={currentProperty?.details}
                />
              </>
            )}

            {identityType === 'multiple' && (
              <>
                <Grid item xs={12}>
                  <PropertyOwners currentValues={currentProperty?.owners} />
                </Grid>

                <Grid item xs={12}>
                  <PropertyInfos />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            startIcon={<CheckIcon />}
            color="success"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default PropertyDialog;
