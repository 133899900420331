import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Property } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getProperties(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Property>>(
    `/property/admin/properties`,
    config,
  );

  return response;
}

export function usePropertiesQuery(data?: IApiUseQueryData<Property>) {
  return useQuery<IApiUseQueryFnData<Property>>(
    [PROPERTY_ADMIN_QUERY_KEYS.PROPERTIES, data?.requestConfig?.params],
    () => getProperties(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePropertiesQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PROPERTIES]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PROPERTIES]);
}

export async function storeProperty(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Property>(
    `/property/admin/properties`,
    data,
    config,
  );

  invalidatePropertiesQueries();

  return response;
}

export async function getProperty(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Property>(
    `/property/admin/properties/${id}`,
    config,
  );

  return response;
}

export async function updateProperty(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Property>(
    `/property/admin/properties/${id}`,
    data,
    config,
  );

  invalidatePropertiesQueries();

  return response;
}

export async function destroyProperty(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${id}`,
    config,
  );

  invalidatePropertiesQueries();

  return response;
}

export async function restoreProperty(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/properties/${id}/restore`,
    config,
  );

  invalidatePropertiesQueries();

  return response;
}

export { peculiarities } from './peculiarities';
export { keys } from './keys';
export { financing } from './financing';
export { photos } from './photos';
export { videos } from './videos';
export { files } from './files';
export { cover } from './cover';
