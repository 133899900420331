import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import EditButton from 'src/components/Table/EditButton';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDate } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import { IBankAccountDocument } from 'src/interfaces/models/IBank';
import BankAccountDocumentFilterSchema from 'src/validators/BankAccountDocument/filter.schema';

import Filters from './Filters';

const List: FC = () => {
  const params = useParams<ITabEditParams>();
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading,
    info,
  } = usePagination<IBankAccountDocument>(
    `admin/bank-accounts/${params.id}/documents`,
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'document_type_id',
      headerName: 'Tipo',
      valueGetter({ row }) {
        return row.type?.name;
      },
    },
    { field: 'description', headerName: 'Descrição', flex: 1 },
    { field: 'valid_until', headerName: 'Validade', ...columnDate },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell(params) {
        return (
          <Can permissions={['update-bank-account-documents']}>
            <EditButton to={`${url}/${params.id}`} />
          </Can>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Documentos</Typography>

            <Can permissions={['store-bank-account-documents']}>
              <LinkButton
                to={`${url}/novo`}
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={loading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                showFilters
                filterProps={{
                  yupSchema: BankAccountDocumentFilterSchema,
                  children: <Filters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default List;
