import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import BackButton from 'src/components/BackButton';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import FunnelTab from 'src/modules/attendance/pages/Private/Funnels/Save/FunnelTab';
import StepsTab from 'src/modules/attendance/pages/Private/Funnels/Save/StepsTab';

import { Container } from './styles';

const Save: FC = () => {
  const params = useParams<ITabEditParams>();

  const FunnelsTabs: NavigationTab[] = [
    { name: 'Funil', slug: '', Component: FunnelTab },
    {
      name: 'Etapas',
      slug: 'etapas',
      Component: StepsTab,
      disabled: !params.id,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Novo'} Funil
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <NavigationTabs
            baseUrl={`/atendimento/funis${params.id ? `/${params.id}` : ''}`}
            tabs={FunnelsTabs}
          />
        </Grid>

        <Grid item xs={12}>
          {FunnelsTabs.map(({ name, slug, Component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              <Component />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
