import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { DetailProperty } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getDetailsProperties(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<DetailProperty>>(
    '/property/admin/details-properties',
    config,
  );

  return response;
}

export function useDetailsPropertiesQuery(
  data?: IApiUseQueryData<DetailProperty>,
) {
  return useQuery<IApiUseQueryFnData<DetailProperty>>(
    [PROPERTY_ADMIN_QUERY_KEYS.DETAILS_PROPERTIES, data?.requestConfig?.params],
    () => getDetailsProperties(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateDetailsPropertiesQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.DETAILS_PROPERTIES,
  ]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.DETAILS_PROPERTIES]);
}

export async function storeDetailProperty(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<DetailProperty>(
    '/property/admin/details-properties',
    data,
    config,
  );

  invalidateDetailsPropertiesQueries();

  return response;
}

export async function getDetailProperty(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<DetailProperty>(
    `/property/admin/details-properties/${id}`,
    config,
  );

  return response;
}

export async function updateDetailProperty(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<DetailProperty>(
    `/property/admin/details-properties/${id}`,
    data,
    config,
  );

  invalidateDetailsPropertiesQueries();

  return response;
}

export async function destroyDetailProperty(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/details-properties/${id}`,
    config,
  );

  invalidateDetailsPropertiesQueries();

  return response;
}

export async function restoreDetailProperty(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/details-properties/${id}/restore`,
    config,
  );

  invalidateDetailsPropertiesQueries();

  return response;
}
