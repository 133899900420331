import React, { useState } from 'react';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  List,
  ListItem,
} from '@mui/material';
import IconButton from 'src/components/IconButton';
import { Opportunity } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';

export type OpportunityNewChatDialogProps = {
  funnelId: number;
  opportunity: Opportunity;
  onClose: () => void;
  onSuccess?: () => void;
} & DialogProps;

const OpportunityNewChatDialog: React.FC<OpportunityNewChatDialogProps> = ({
  funnelId,
  opportunity,
  onSuccess,
  onClose,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  async function handleSelect(clientId: number) {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.opportunities.openFunnelOpportunityChat(
        funnelId,
        opportunity.id,
        {
          client_id: clientId,
        },
      );

      if (onSuccess) onSuccess();

      onClose();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao abrir chat.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} {...rest}>
      <DialogTitle align="center">
        Novo Chat para {opportunity.client?.username}
      </DialogTitle>

      <DialogContent>
        <List>
          <ListItem>
            {masks.strictPhoneOrCellphone(opportunity.client?.phone || '')}
            <IconButton
              color="success"
              onClick={() => handleSelect(opportunity.client_id)}
              disabled={loading}
            >
              <WhatsAppIcon fontSize="inherit" />
            </IconButton>
          </ListItem>

          {opportunity.client?.bonds?.map((bondClient) => (
            <ListItem key={bondClient.id}>
              {masks.strictPhoneOrCellphone(bondClient?.phone || '')}
              <IconButton
                color="success"
                onClick={() => handleSelect(bondClient.id)}
                disabled={loading}
              >
                <WhatsAppIcon fontSize="inherit" />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default OpportunityNewChatDialog;
