import { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import Form from 'src/components/Form/Form';
import { FormOnSave } from 'src/components/Form/Form/interfaces';
import InputFile from 'src/components/Form/InputFile';
import TextField from 'src/components/Form/TextField';
import IMedia from 'src/interfaces/models/IMedia';
import { SaveIdentityPropertyMediaParams } from 'src/modules/property/@types/params';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import { PropertyMediaUpdateSchema } from 'src/modules/property/validators/PropertyMedia/save.schema';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';

const Update: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState<IMedia>();
  const params = useParams<SaveIdentityPropertyMediaParams>();
  const history = useHistory();

  const isUpload = media?.source === 'local';

  async function goBack() {
    history.goBack();
  }

  const handleSave: FormOnSave = async ({ formData }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      if (!params.propertyMediaId) return;

      const { success, data: validData, errors } = await yupValidate(
        PropertyMediaUpdateSchema,
        formData,
      );

      if (isUpload) delete validData.value;

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const fileFormData = objectToFormData(validData);

      await propertyApi.admin.properties.files.updatePropertyFile(
        params.propertyId,
        params.propertyMediaId,
        fileFormData,
      );

      toast.success('Arquivo salvo com sucesso!');

      goBack();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        PropertyMediaUpdateSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        if (!params.propertyMediaId) return;

        const {
          data,
        } = await propertyApi.admin.properties.files.getPropertyFile(
          params.propertyId,
          params.propertyMediaId,
        );

        setMedia(data.media);

        formRef.current?.setData({
          ...data,
          file: data.url,
        });
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [params.propertyId, params.propertyMediaId]);

  return (
    <Form
      ref={formRef}
      permissions={{
        update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
      }}
      onSave={handleSave}
      isUpdating={!!params.propertyMediaId}
      loading={loading}
      onCancel={goBack}
    >
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={6} hidden={!isUpload}>
              <InputFile
                name="file"
                label="Arquivo"
                disabled={!isUpload}
                accept={media?.accept}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} hidden={isUpload}>
                  <TextField name="value" label="URL" disabled={isUpload} />
                </Grid>

                <Grid item xs={12}>
                  <TextField name="name" label="Título" />
                </Grid>

                <Grid item xs={12}>
                  <CheckBox name="show_in_website" label="Mostrar no Site" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default Update;
