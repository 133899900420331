import React, { useContext, useEffect, useRef, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import TextField from 'src/components/Form/TextField';
import IconButton from 'src/components/IconButton';
import { InterestProductFormSchema } from 'src/modules/attendance/validators/Opportunity/save.schema';
import { showFormErrors } from 'src/utils/errors';
import { showErrorToast } from 'src/utils/errors';
import masks from 'src/utils/masks';
import yupValidate from 'src/utils/yupValidate';

import {
  InterestProductData,
  StoreOpportunityAttendanceDialogContext,
} from '../context';

type InterestFormProps = {
  editId?: number;
  onCancel: () => void;
};

const InterestForm: React.FC<InterestFormProps> = ({ editId, onCancel }) => {
  const { data, setData } = useContext(StoreOpportunityAttendanceDialogContext);
  const interestFormRef = useRef<FormHandles>(null);
  const [products, setProducts] = useState<InterestProductData[]>([]);
  const productFormRef = useRef<FormHandles>(null);

  useEffect(() => {
    const interest = data.interests.find((interest) => interest.id === editId);
    if (interest) setProducts(interest.products);
  }, [data.interests, editId]);

  function handleSubmitInterest(formData) {
    if (!products.length)
      return showErrorToast('Insira ao menos 1 imóvelf no interesse.');

    formData.products = products;
    if (editId) {
      setData((state) => {
        const interests = [...state.interests];
        const index = interests.findIndex((interest) => interest.id === editId);
        if (index >= 0) {
          interests.splice(index, 1, formData);
        }
        return {
          ...state,
          interests,
        };
      });
    } else {
      setData((state) => ({
        ...state,
        interests: [...state.interests, formData],
      }));
    }
    onCancel();
  }

  function clearProductForm() {
    productFormRef.current?.setData({ id: Math.random() });
  }

  async function saveProduct(productFormData: InterestProductData) {
    const { success, data, errors } = await yupValidate(
      InterestProductFormSchema,
      productFormData,
    );

    if (!success) {
      return showFormErrors(errors, productFormRef);
    }

    setProducts((state) => {
      const editIndex = state.findIndex((s) => s.id === data.id);

      if (editIndex >= 0) {
        return state.map((s) => {
          if (s.id === data.id) return data;
          return s;
        });
      }

      return [...state, data];
    });

    clearProductForm();
  }

  function removeProduct(id: number) {
    setProducts((state) => state.filter((s) => s.id !== id));
  }

  const productsColumns: GridColDef<InterestProductData>[] = [
    { field: 'reference_code', headerName: 'Código' },
    { field: 'name', headerName: 'Imóvel', flex: 1 },
    { field: 'type', headerName: 'Tipo' },
    {
      field: 'price',
      headerName: 'Preço',
      valueGetter({ row }) {
        return masks.currency(row.price || '');
      },
    },
    // { field: 'quantity', headerName: 'Qtde' },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      renderCell({ row }) {
        return (
          <>
            <IconButton
              size="small"
              tooltip="Editar"
              color="info"
              onClick={() => productFormRef.current?.setData(row)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              tooltip="Remover"
              color="error"
              onClick={() => removeProduct(row.id)}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Grid container spacing={2} paddingTop={1}>
      <Grid item xs={12}>
        <Form
          ref={interestFormRef}
          onSubmit={handleSubmitInterest}
          initialData={
            data.interests.find((interest) => interest.id === editId) || {
              id: Math.random(),
            }
          }
        >
          <Grid container spacing={2}>
            <TextField name="id" hidden />
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Descrição do Interesse"
                multiline
                rows={4}
                autoFocus
              />
            </Grid>
          </Grid>
        </Form>
      </Grid>

      <Grid item xs={12}>
        <Form
          ref={productFormRef}
          onSubmit={saveProduct}
          initialData={{ id: Math.random() }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <TextField name="id" hidden />

                <Grid item xs={4} sm={1.5}>
                  <TextField name="reference_code" label="ID/Código" />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField name="name" label="Imóvel" required />
                </Grid>

                <Grid item xs={8} sm={2}>
                  <TextField name="type" label="Tipo" />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <TextField
                    name="price"
                    label="Preço"
                    mask="currency"
                    returnUnmasked
                  />
                </Grid>

                {/* <Grid item xs={12} sm={1}>
                  <TextField name="quantity" label="Qtde" type="number" />
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignSelf: 'center',
                  }}
                >
                  <IconButton
                    type="submit"
                    color="success"
                    tooltip="Salvar"
                    size="small"
                  >
                    <CheckIcon fontSize="inherit" />
                  </IconButton>

                  <IconButton
                    color="error"
                    onClick={clearProductForm}
                    tooltip="Cancelar"
                    size="small"
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                disableColumnMenu
                columns={productsColumns}
                rows={products}
                autoHeight
                hideFooter
              />
            </Grid>
          </Grid>
        </Form>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent="end" gap={2}>
          <Button
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
            onClick={onCancel}
          >
            Voltar
          </Button>
          <Button
            onClick={() => interestFormRef.current?.submitForm()}
            variant="contained"
            color="success"
            startIcon={<CheckIcon />}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InterestForm;
