import { FC, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import TextField from 'src/components/Form/TextField';
import Link from 'src/components/Link';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import recoverPasswordSchema from 'src/validators/User/recoverPassword.schema';

import { Content, Form, SubmitButton } from './styles';

const RecoverPassword: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        recoverPasswordSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await api.post('/admin/reset-password', {
        token,
        ...data,
      });

      toast('Senha alterada com sucesso!', { type: 'success' });

      history.push('/');
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        recoverPasswordSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Content>
        <Card>
          <CardContent>
            <Typography component="h1" variant="h5" align="center">
              Recuperar Senha
            </Typography>

            <Form ref={formRef} onSubmit={handleOnSubmit}>
              <TextField
                name="password"
                type="password"
                label="Nova Senha"
                fullWidth
                variant="outlined"
                margin="normal"
                autoFocus
              />

              <TextField
                name="password_confirmation"
                type="password"
                label="Confirmar Nova Senha"
                fullWidth
                variant="outlined"
                // margin="normal"
              />

              <SubmitButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Recuperar Senha
              </SubmitButton>
            </Form>

            <Grid container>
              <Link to="/" variant="body2">
                Voltar ao Login
              </Link>
            </Grid>
          </CardContent>
        </Card>
      </Content>
    </Container>
  );
};

export default RecoverPassword;
