import { FC, useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import IContactType from 'src/interfaces/models/IContactType';
import api from 'src/services/api';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [types, setTypes] = useState<IContactType[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const typesResp = await api.get<IContactType[]>(
          '/admin/contact-types',
          {
            params: {
              status: 'active',
              'order_by[0][column]': 'name',
              'order_by[0][direction]': 'asc',
            },
          },
        );
        setTypes(typesResp.data);
      } catch (error) {
        setTypes([]);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="contact_type_id"
          label="Tipo"
          size="small"
          options={types.map((type) => ({
            key: type.id,
            label: type.name,
            value: type.id,
          }))}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="contact_value" label="Valor" size="small" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
