import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import AutocompleteClients from 'src/components/Form/_Common/AutocompleteClients';
import { Opportunity } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

export type StoreOpportunityDialogProps = {
  selectedFunnelId: number;
  onClose: () => void;
  onSucess?: (opportunity: Opportunity) => void;
} & DialogProps;

type StoreOpportunityFormData = {
  lead_id: number;
};

const StoreOpportunityDialog: React.FC<StoreOpportunityDialogProps> = ({
  selectedFunnelId,
  onSucess,
  onClose,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(formData: StoreOpportunityFormData) {
    try {
      setLoading(true);

      const {
        data,
      } = await attendanceApi.admin.funnels.opportunities.storeFunnelOpportunity(
        selectedFunnelId,
        formData,
      );

      if (onSucess) onSucess(data);
      onClose();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao cadastrar oportunidade.',
      );
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    if (!loading) onClose();
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} {...rest}>
      <DialogTitle align="center">Nova Oportunidade</DialogTitle>

      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteClients
                name="client_id"
                label="Cliente"
                disabled={loading}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            startIcon={<CheckIcon />}
            color="success"
            variant="contained"
            loading={loading}
          >
            Salvar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default StoreOpportunityDialog;
