import React from 'react';

import { Card, CardContent, CardHeader } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  OpportunityInterest,
  OpportunityInterestProduct,
} from 'src/modules/attendance/@types/models';
import { formatDate } from 'src/utils/helpers';
import masks from 'src/utils/masks';

type InterestCardProps = {
  interest: OpportunityInterest;
};

const columns: GridColDef<OpportunityInterestProduct>[] = [
  {
    field: 'reference_code',
    headerName: 'Codigo',
    width: 70,
  },
  { field: 'name', headerName: 'Imóvel', flex: 1 },
  { field: 'type', headerName: 'Tipo' },
  // {
  //   field: 'name',
  //   headerName: 'Tipo - Produto',
  //   flex: 1,
  //   valueGetter({ row }) {
  //     return `${row.type} - ${row.name}`;
  //   },
  // },
  {
    field: 'price',
    headerName: 'Preço',
    valueFormatter({ value }) {
      return masks.currency(value);
    },
  },
  // { field: 'quantity', headerName: 'Qtde', width: 60 },
];

const InterestCard: React.FC<InterestCardProps> = ({ interest }) => {
  return (
    <Card>
      <CardHeader
        title={formatDate(interest.created_at, 'dd/MM/yyyy HH:mm')}
        titleTypographyProps={{ fontSize: 16 }}
        subheader={interest.description}
        subheaderTypographyProps={{ whiteSpace: 'break-spaces' }}
      />
      <CardContent>
        {!!interest.products?.length && (
          <DataGrid
            autoHeight
            disableColumnMenu
            disableSelectionOnClick
            columns={columns}
            rows={interest.products}
            hideFooter
          />
        )}
      </CardContent>
    </Card>
  );
};

export default InterestCard;
