import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import { ButtonProps } from 'src/components/Button/interfaces';
import Can from 'src/components/Can';
import Autocomplete from 'src/components/Form/Autocomplete';
import { ICity } from 'src/interfaces/models/IAddress';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

// import { Container } from './styles';

const ReviewCityDialog: FC<ReviewCityDialogProps> = ({
  city,
  buttonProps,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('bond');
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState<ICity[]>([]);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const response = await api.get<ICity[]>('admin/cities', {
          params: { status: 'active' },
        });
        setCities(response.data);
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (open && cities.length && city) {
      setTimeout(() => {
        formRef.current?.setFieldValue('bond_id', city.bond_id);
      });
    }
  }, [open, cities, city]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      await api.post(`/admin/cities/${city?.id}/review`, {
        ...formData,
        action,
      });

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao salvar revisão');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Can permissions={['review-cities']} behavior="hide">
        <Button
          type="button"
          size="small"
          startIcon={<VisibilityIcon />}
          color="primary"
          variant="outlined"
          onClick={() => setOpen(true)}
          {...buttonProps}
        >
          Revisar
        </Button>
      </Can>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        {...rest}
      >
        <DialogTitle>
          Revisar Cidade{' '}
          {`${city?.id} - ${city?.name} (${city?.state?.initials})`}
        </DialogTitle>

        <DialogContent>
          <Form ref={formRef} onSubmit={handleOnSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={action}
                    onChange={(_, value) => {
                      setAction(value);
                    }}
                    row
                  >
                    <FormControlLabel
                      value="approve"
                      control={<Radio color="primary" />}
                      label="Aprovar"
                    />
                    <FormControlLabel
                      value="bond"
                      control={<Radio color="error" />}
                      label="Vincular"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {action === 'bond' && (
                <Grid item xs={12}>
                  <Autocomplete
                    name="bond_id"
                    label="Cidade"
                    options={cities.map((city) => ({
                      key: city.id,
                      label: `${city.id} - ${city.name} (${city.state?.initials})`,
                      value: city.id,
                    }))}
                    getOptionDisabled={(option) => option.value === city?.id}
                  />
                </Grid>
              )}
            </Grid>
          </Form>
        </DialogContent>

        <DialogActions>
          <Button
            startIcon={<CheckIcon />}
            loading={loading}
            color={action === 'bond' ? 'error' : 'primary'}
            variant="contained"
            type="button"
            onClick={() => formRef.current?.submitForm()}
          >
            {action === 'bond' ? 'Vincular' : 'Aprovar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewCityDialog;

export interface ReviewCityDialogProps
  extends Omit<DialogProps, 'open' | 'onClose'> {
  city?: ICity;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
}
