import yup from 'src/libs/yup';

export const EconomicIndicatorStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  frequency: yup.string().required().label('Frequência'),
});

export const EconomicIndicatorUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  frequency: yup.string().required().label('Frequência'),
});

export const EconomicIndicatorValueStoreSchema = yup.object().shape({
  indicator_date: yup.string().required().label('Data de Referência'),
  indicator_value: yup.number().required().label('Valor'),
});

export const EconomicIndicatorValueUpdateSchema = yup.object().shape({
  indicator_date: yup.string().required().label('Data de Referência'),
  indicator_value: yup.number().required().label('Valor'),
});
