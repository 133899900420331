import { FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider as MuiStyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { setDefaultOptions } from 'date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import Toast from 'src/components/Toast';
import { queryClient } from 'src/config/query';
import Routes from 'src/routes';
import GlobalStyle from 'src/styles/GlobalStyle';
import MuiTheme from 'src/styles/themes/default/_MuiTheme';

const App: FC = () => {
  useEffect(() => {
    setDefaultOptions({ locale: ptBRLocale });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <BrowserRouter>
        <MuiStyledEngineProvider injectFirst>
          <MuiThemeProvider theme={MuiTheme}>
            <MuiLocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBRLocale}
            >
              <StyledThemeProvider theme={MuiTheme}>
                <QueryClientProvider client={queryClient}>
                  <GlobalStyle />
                  <CssBaseline />
                  <Routes />
                  <Toast />
                </QueryClientProvider>
              </StyledThemeProvider>
            </MuiLocalizationProvider>
          </MuiThemeProvider>
        </MuiStyledEngineProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
