import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import IZApiInstance from 'src/interfaces/models/IZApiInstance';
import apiAdminPublic from 'src/services/api/adminPublic';

type AutocompleteZApiInstancesProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<IZApiInstance>, 'options'>;

const AutocompleteZApiInstances: React.FC<AutocompleteZApiInstancesProps> = ({
  requestConfig,
  ...rest
}) => {
  const { isLoading, data: response } = apiAdminPublic.useZApiInstancesQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((instance) => ({
      key: instance.id,
      label: instance.name,
      value: instance.id,
      data: instance,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteZApiInstances;
