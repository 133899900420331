import styled, { css } from 'styled-components';

import MuiPaper from '@mui/material/Paper';

export const Container = styled.div`
  display: grid;
  gap: 8px;
`;

export const Input = styled.input`
  display: none;
`;

export const InputLabel = styled.label``;

const imgStyles = css`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const NoImage = styled(MuiPaper)`
  ${imgStyles}

  height: 200px;
  /* background-color: ${({ theme }) => theme.palette.background.default}; */

  display: flex;
  align-items: center;
  justify-content: center;
`;
