import { FC, useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import SearchClientDialog from 'src/components/_functions/SearchClientDialog';
import { SearchClientDialogRef } from 'src/components/_functions/SearchClientDialog/interfaces';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Table from 'src/components/Table';
import DeleteButton from 'src/components/Table/DeleteButton';
import usePagination from 'src/hooks/usePagination';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import { IClient } from 'src/interfaces/models/IClient';
import apiAdmin from 'src/services/api/admin';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';

const BondTab: FC = () => {
  const params = useParams<ITabEditParams>();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading: paginationLoading,
    info,
    loadData,
  } = usePagination<IClient>(`admin-public/clients/${params.id}/bonds`);
  const [loading, setLoading] = useState(false);
  const [destroyBondId, setDestroyBondId] = useState<number | null>(null);
  const searchClientDialogRef = useRef<SearchClientDialogRef>(null);
  const destroyDialogRef = useRef<ConfirmDialogRef>(null);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'username',
      headerName: 'Cliente',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            {row.username && (
              <Typography variant="body2">{row.username}</Typography>
            )}
            <Typography variant="body2">{row.email}</Typography>
          </div>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Tipo',
      valueGetter({ value }) {
        switch (value) {
          case 'F':
            return 'PF';
          case 'J':
            return 'PJ';
          default:
            return 'C';
        }
      },
    },
    {
      field: 'name',
      headerName: 'Pessoa / Empresa',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">
              {row.type === 'F'
                ? row.person?.name
                : row.enterprise?.company_name}
            </Typography>
            <Typography variant="body2">
              {row.type === 'F'
                ? masks.cpf(row.person?.document)
                : masks.cnpj(row.enterprise?.document)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ row }) {
        return (
          <Can permissions={['bond-clients']}>
            <DeleteButton onClick={() => handleOpenDestroyDialog(row.id)} />
          </Can>
        );
      },
    },
  ];

  const handleSelectBond = useCallback(
    async (bond: IClient) => {
      try {
        setLoading(true);

        await apiAdmin.storeClientBond(params.id, bond.id);

        searchClientDialogRef.current?.hide();
        loadData();
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao criar vínculo!');
      } finally {
        setLoading(false);
      }
    },
    [params.id, loadData],
  );

  const handleDestroyBond = useCallback(async () => {
    try {
      setLoading(true);

      if (!destroyBondId) {
        throw new Error();
      }

      await apiAdmin.destroyClientBond(params.id, destroyBondId);

      destroyDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao remover vínculo!');
    } finally {
      setLoading(false);
    }
  }, [params.id, destroyBondId, loadData]);

  const handleOpenDestroyDialog = useCallback((bondId: number) => {
    setDestroyBondId(bondId);
    destroyDialogRef.current?.show();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Vínculos</Typography>

            <Can permissions={['bond-clients']}>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
                onClick={searchClientDialogRef.current?.show}
              >
                Novo
              </Button>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2" color="error">
            <strong>Atenção, na criação do vínculo ocorrerá o seguinte:</strong>
            <br />
            As oportunidades do atendimento serão movidas para o cadastro
            principal.
            <br />
            Os chats de WhatsApp dos vinculos permanecerão abertos e será
            possível acessá-los dentro da oportunidade do cadastro principal ou
            na visualização de conversas do departamento.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                disableDensitySelector
                disableColumnSelector
                rows={data}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={paginationLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <SearchClientDialog
        ref={searchClientDialogRef}
        defaultQueryParams={{ type: 'L' }}
        onSelectClient={handleSelectBond}
        loading={loading}
      />

      <ConfirmDialog
        ref={destroyDialogRef}
        title="Remover vínculo?"
        description="Confirma esta ação?"
        confirmColor="error"
        loading={loading}
        onConfirm={handleDestroyBond}
      />
    </>
  );
};

export default BondTab;
