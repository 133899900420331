import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Offer } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getOffers(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Offer>>(
    '/property/admin/offers',
    config,
  );

  return response;
}

export function useOffersQuery(data?: IApiUseQueryData<Offer>) {
  return useQuery<IApiUseQueryFnData<Offer>>(
    [PROPERTY_ADMIN_QUERY_KEYS.OFFERS, data?.requestConfig?.params],
    () => getOffers(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateOffersQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.OFFERS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.OFFERS]);
}

export async function storeOffer(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Offer>(
    '/property/admin/offers',
    data,
    config,
  );

  invalidateOffersQueries();

  return response;
}

export async function getOffer(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Offer>(
    `/property/admin/offers/${id}`,
    config,
  );

  return response;
}

export async function updateOffer(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Offer>(
    `/property/admin/offers/${id}`,
    data,
    config,
  );

  invalidateOffersQueries();

  return response;
}

export async function destroyOffer(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/offers/${id}`,
    config,
  );

  invalidateOffersQueries();

  return response;
}

export async function restoreOffer(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/offers/${id}/restore`,
    config,
  );

  invalidateOffersQueries();

  return response;
}
