import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import BackButton from 'src/components/BackButton';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';

import DetailTab from './DetailTab';
import PropertyTypeTab from './PropertyTypeTab';
import { Container } from './styles';

const propertyTypesTabs = [
  {
    name: 'Tipo de Imóvel',
    slug: '',
    component: <PropertyTypeTab />,
  },
  {
    name: 'Detalhes',
    slug: 'detalhes',
    component: <DetailTab />,
  },
];

const Save: FC = () => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const params = useParams<ITabEditParams>();
  const history = useHistory();

  const handleTabChange = (e: ChangeEvent<any>, newValue: number) => {
    let goToUrl = `/tipos-imovel/${params.id}`;
    if (newValue > 0) {
      goToUrl = goToUrl.concat(
        `/${propertyTypesTabs[newValue].slug}?status=active`,
      );
    }

    history.replace(goToUrl);
  };

  useEffect(() => {
    if (params.selectedTab) {
      setCurrentTab(
        propertyTypesTabs.findIndex((tab) => tab.slug === params.selectedTab),
      );
    } else {
      setCurrentTab(0);
    }
  }, [params.selectedTab]);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Novo'} Tipo de Imóvel
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AppBar color="transparent" position="relative">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {propertyTypesTabs.map(({ name }, index) => (
                <Tab
                  key={name}
                  label={name}
                  disabled={index > 0 && !params.id}
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12}>
          {propertyTypesTabs.map(({ name, slug, component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              {component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
