import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { EconomicIndicator } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getEconomicIndicators(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<EconomicIndicator>>(
    '/property/admin/economic-indicators',
    config,
  );

  return response;
}

export function useEconomicIndicatorsQuery(
  data?: IApiUseQueryData<EconomicIndicator>,
) {
  return useQuery<IApiUseQueryFnData<EconomicIndicator>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.ECONOMIC_INDICATORS,
      data?.requestConfig?.params,
    ],
    () => getEconomicIndicators(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateEconomicIndicatorsQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.ECONOMIC_INDICATORS,
  ]);
  queryClient.invalidateQueries([
    PROPERTY_ADMIN_QUERY_KEYS.ECONOMIC_INDICATORS,
  ]);
}

export async function storeEconomicIndicator(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<EconomicIndicator>(
    '/property/admin/economic-indicators',
    data,
    config,
  );

  invalidateEconomicIndicatorsQueries();

  return response;
}

export async function getEconomicIndicator(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<EconomicIndicator>(
    `/property/admin/economic-indicators/${id}`,
    config,
  );

  return response;
}

export async function updateEconomicIndicator(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<EconomicIndicator>(
    `/property/admin/economic-indicators/${id}`,
    data,
    config,
  );

  invalidateEconomicIndicatorsQueries();

  return response;
}

export async function destroyEconomicIndicator(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/economic-indicators/${id}`,
    config,
  );

  invalidateEconomicIndicatorsQueries();

  return response;
}

export async function restoreEconomicIndicator(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/economic-indicators/${id}/restore`,
    config,
  );

  invalidateEconomicIndicatorsQueries();

  return response;
}

export { values } from './values';
