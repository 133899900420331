import React from 'react';

import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { DetailCondominium } from 'src/modules/property/@types/models';
import propertyApi from 'src/modules/property/services/api';

type AutocompleteDetailsCondominiumsProps = {
  requestConfig?: AxiosRequestConfig;
} & Omit<AutocompleteProps<DetailCondominium>, 'options'>;

const AutocompleteDetailsCondominiums: React.FC<AutocompleteDetailsCondominiumsProps> = ({
  requestConfig,
  ...rest
}) => {
  const {
    isLoading,
    data: response,
  } = propertyApi.admin.common.useDetailsCondominiumsQuery({
    requestConfig,
  });

  const options =
    response?.data?.map((detail) => ({
      key: detail.id,
      label: detail.name,
      value: detail.id,
      data: detail,
    })) || [];

  return <Autocomplete {...rest} loading={isLoading} options={options} />;
};

export default AutocompleteDetailsCondominiums;
