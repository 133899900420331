import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { PublicityType } from 'src/modules/property/@types/models';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import PublicityTypeFilterSchema from 'src/modules/property/validators/PublicityType/filter.schema';

import PublicitiesTypesFilters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination(
    propertyApi.admin.publicityTypes.usePublicitiesTypesQuery,
  );

  const columns: GridColDef<PublicityType>[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resourceName="Tipo de Publicidade"
            resourceId={id}
            resourceStatus={row.status}
            editLink={`/imovel/tipos-publicidades/${id}`}
            editPermissions={[PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.UPDATE]}
            canDelete
            destroyApiEndpoint={
              propertyApi.admin.publicityTypes.destroyPublicityType
            }
            destroyPermissions={[
              PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.DESTROY,
            ]}
            restoreApiEndpoint={
              propertyApi.admin.publicityTypes.restorePublicityType
            }
            restorePermissions={[
              PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.RESTORE,
            ]}
            descriptionPrefix={`O Tipo de Publicidade ${row.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Tipos de Publicidades</Typography>

            <Can
              behavior="disable"
              permissions={[PROPERTY_PERMISSIONS.PUBLICITIES_TYPES.STORE]}
            >
              <LinkButton
                to="/imovel/tipos-publicidades/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                canSearch
                showFilters
                filterProps={{
                  yupSchema: PublicityTypeFilterSchema,
                  children: <PublicitiesTypesFilters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
