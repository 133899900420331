import styled from 'styled-components';

import MuiContainer from '@mui/material/Container';

export const Container = styled(MuiContainer)`
  padding: ${({ theme }) => theme.spacing(3)};
  padding-bottom: 0;
  height: calc(100vh - 64px);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
