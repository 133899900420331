import React from 'react';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Tooltip, useTheme } from '@mui/material';
import { MessageProps } from 'src/pages/Private/Omnichannel/Chat/ChatContent/Message';
import ExternalAdReply from 'src/pages/Private/Omnichannel/Chat/ChatContent/Message/WhatsApp/ExternalAdReply';
import OminichannelUtils from 'src/utils/omnichannel';

import { Container } from '../styles';
import Audio from './Audio';
import Document from './Document';
import Image from './Image';
import Text from './Text';
import Video from './Video';

const WhatsAppMessage: React.FC<MessageProps> = ({
  message,
  onReply,
  onReplyClick,
}) => {
  const theme = useTheme();

  function renderMessageType() {
    let reply;
    if (message.reference_message) {
      reply = {
        title: message.reference_message.whatsapp?.fromMe
          ? 'Você'
          : message.reference_message.whatsapp?.senderName,
        titleColor: message.reference_message.whatsapp?.fromMe
          ? theme.palette.info.main
          : theme.palette.grey[600],
        message: OminichannelUtils.getChatMessageText(
          message.reference_message,
        ),
        onClick: () => {
          if (onReplyClick && message.reference_message) {
            onReplyClick(message.reference_message);
          }
        },
      };
    }

    switch (message.type) {
      case 'text':
        return <Text message={message} onReply={onReply} reply={reply} />;
      case 'image':
        return <Image message={message} onReply={onReply} reply={reply} />;
      case 'audio':
        return <Audio message={message} onReply={onReply} reply={reply} />;
      case 'document':
        return <Document message={message} onReply={onReply} reply={reply} />;
      case 'video':
        return <Video message={message} onReply={onReply} reply={reply} />;
      case 'externalAdReply':
        return <ExternalAdReply message={message} onReply={onReply} />;
      default:
        return `Mensagem do tipo "${message.type}" ainda não é suportado.`;
    }
  }

  return (
    <Container
      id={`chat-message-${message._id}`}
      key={message._id}
      direction={message.direction}
      hasReactions={!!message.reactions?.length}
    >
      <div className="message-icon">
        <Tooltip title="WhatsApp">
          <WhatsAppIcon htmlColor="#0A7923" />
        </Tooltip>
      </div>

      <div className="message-content">
        {renderMessageType()}

        {!!message.reactions?.length && (
          <div className="reactions">
            {message.reactions.map((reaction) => (
              <span key={reaction.id}>{reaction.value}</span>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default WhatsAppMessage;
