import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AutocompleteMediaSources from 'src/components/Form/_Common/AutocompleteMediaSources';
import AutocompleteMediaTypes from 'src/components/Form/_Common/AutocompleteMediaTypes';
import NumberOperator from 'src/components/Form/NumberOperator';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome" size="small" />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteMediaTypes name="type" label="Tipo" size="small" />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteMediaSources name="source" label="Origem" size="small" />
      </Grid>

      <Grid item xs={12}>
        <NumberOperator name="max_size" label="Tamanho Máximo" size="small" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
