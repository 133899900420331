import yup from 'src/libs/yup';

export const CountryStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  initials: yup.string().required().label('Iniciais'),
});

export const CountryUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  initials: yup.string().required().label('Iniciais'),
});
