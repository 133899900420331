import React from 'react';

import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { ECONOMIC_INDICATOR_FREQUENCIES_OPTIONS } from 'src/modules/property/constants';

type AutocompleteEconomicIndicatorFrequenciesProps = Omit<
  AutocompleteProps<string>,
  'options'
>;

const AutocompleteEconomicIndicatorFrequencies: React.FC<AutocompleteEconomicIndicatorFrequenciesProps> = (
  props,
) => {
  return (
    <Autocomplete {...props} options={ECONOMIC_INDICATOR_FREQUENCIES_OPTIONS} />
  );
};

export default AutocompleteEconomicIndicatorFrequencies;
