import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';

interface Props extends CardProps {
  title: string;
  total: string | number;
  icon?: React.ReactElement;
}

export default function SummaryCard({
  title,
  total,
  icon,
  sx,
  ...other
}: Props) {
  return (
    <Card sx={{ flexGrow: 1, p: 2, ...sx }} {...other}>
      <Box
        sx={{
          mb: 1,
          lineBreak: 'anywhere',
          typography: {
            xs: 'h5',
            sm: 'h6',
            md: 'h6',
          },
        }}
      >
        {total}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ color: 'text.secondary', typography: 'subtitle2' }}>
          {title}
        </Box>

        {icon && (
          <Box
            sx={{
              lineHeight: 0,
              borderRadius: '50%',
              bgcolor: 'background.neutral',
            }}
          >
            {icon}
          </Box>
        )}
      </Box>
    </Card>
  );
}
