import React, { useContext, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Button from 'src/components/Button';
import IconButton from 'src/components/IconButton';
import { StoreOpportunityAttendanceDialogContext } from 'src/modules/attendance/components/StoreOpportunityAttendanceDialog/context';

import InterestForm from './InterestForm';

const InterestStep: React.FC = () => {
  const { onClose, setActiveStep, data, setData } = useContext(
    StoreOpportunityAttendanceDialogContext,
  );
  const [showInterestForm, setShowInterestForm] = useState(false);
  const [editInterestId, setEditInterestId] = useState(0);

  function newInterest() {
    setEditInterestId(0);
    setShowInterestForm(true);
  }

  function editInterest(id: number) {
    setEditInterestId(id);
    setShowInterestForm(true);
  }

  function removeInterest(id: number) {
    setData((state) => {
      const interests = state.interests.filter(
        (interest) => interest.id !== id,
      );
      return {
        ...state,
        interests,
      };
    });
  }

  const columns: GridColDef[] = [
    { field: 'description', headerName: 'Descrição', flex: 1 },
    {
      field: 'products',
      headerAlign: 'center',
      headerName: 'Qtde Imóveis',
      align: 'center',
      valueGetter({ row }) {
        return row.products?.length;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      renderCell({ row }) {
        return (
          <>
            <IconButton
              size="small"
              tooltip="Editar"
              color="info"
              onClick={() => editInterest(row.id)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              size="small"
              tooltip="Remover"
              color="error"
              onClick={() => removeInterest(row.id)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          {showInterestForm ? (
            <Grid item xs={12}>
              <InterestForm
                onCancel={() => setShowInterestForm(false)}
                editId={editInterestId}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<AddIcon />}
                  onClick={newInterest}
                >
                  Interesse
                </Button>
              </Grid>

              <Grid item xs={12}>
                <DataGrid
                  disableColumnMenu
                  columns={columns}
                  rows={data.interests}
                  autoHeight
                  hideFooter
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>

      {!showInterestForm && (
        <DialogActions>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Button startIcon={<CloseIcon />} color="error" onClick={onClose}>
                Cancelar
              </Button>
            </Grid>

            <Grid item>
              <Button
                type="submit"
                endIcon={<ArrowForwardIcon />}
                color="success"
                onClick={() => setActiveStep(1)}
              >
                Próximo
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </>
  );
};

export default InterestStep;
