import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { RemoveRedEye, Search } from '@mui/icons-material';
import { CardHeader, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import AutocompleteUsers from 'src/components/Form/_Common/AutocompleteUsers';
import CheckBox from 'src/components/Form/Checkbox';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import RadioGroup from 'src/components/Form/RadioGroup';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Offer, Property } from 'src/modules/property/@types/models';
import SearchPropertyDialog from 'src/modules/property/components/_functions/SearchPropertyDialog';
import { SearchPropertyDialogRef } from 'src/modules/property/components/_functions/SearchPropertyDialog/interfaces';
import AutocompleteOfferBroughtVias from 'src/modules/property/components/Form/_common/AutocompleteOfferBroughtVias';
import AutocompleteRealtors from 'src/modules/property/components/Form/_common/AutocompleteRealtors';
import PropertyCard from 'src/modules/property/components/PropertyCard';
import {
  OFFER_TYPES,
  OFFER_TYPES_LABEL,
  OFFER_TYPES_OPTIONS,
} from 'src/modules/property/constants';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import RentFields from 'src/modules/property/pages/Private/Offers/Save/RentFields';
import SaleFields from 'src/modules/property/pages/Private/Offers/Save/SaleFields';
import propertyApi from 'src/modules/property/services/api';
import {
  OfferStoreSchema,
  OfferUpdateSchema,
} from 'src/modules/property/validators/Offer/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const searchPropertyRef = useRef<SearchPropertyDialogRef>(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
  const [mainProperty, setMainProperty] = useState<Property>();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const schema = params.id ? OfferUpdateSchema : OfferStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const { data: offer } = await propertyApi.admin.offers.getOffer(
          params.id,
        );
        setStatus(offer.status);
        setSelectedProperties(offer.properties || []);
        setMainProperty(offer.properties?.find((property) => property.main));

        formRef.current?.setFieldValue('type', offer.type);

        setTimeout(() => {
          formRef.current?.setData({
            ...offer,
            concurrents: offer.concurrents?.map((c) => c.id),
          });
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      if (selectedProperties.length === 0) {
        toast.error('Informe ao menos 1 imóvel.');
        return;
      }

      formData.properties = selectedProperties.map((property) => ({
        id: property.id,
        main: property.id === mainProperty?.id,
      }));

      const { success, data, errors } = await yupValidate(schema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (data.type === OFFER_TYPES.RENT) {
        data.sale = null;
      } else {
        data.rent = null;
      }

      let newOffer: Offer;
      if (params.id) {
        const response = await propertyApi.admin.offers.updateOffer(
          params.id,
          data,
        );
        newOffer = response.data;
      } else {
        const response = await propertyApi.admin.offers.storeOffer(data);
        newOffer = response.data;
      }

      toast.success('Oferta salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/imovel/ofertas/${newOffer.id}`);
      } else {
        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', schema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.offers.destroyOffer(params.id);

      toast.success('Oferta desativada!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Oferta');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.offers.restoreOffer(params.id);

      toast.success('Oferta restaurada!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Oferta.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  function handleAddProperty(property: Property) {
    const exists = selectedProperties.find((p) => p.id === property.id);
    if (exists) {
      return toast.warn('Imóvel já adicionado na oferta.');
    }

    if (selectedProperties.length === 0) {
      setMainProperty(property);
    }

    setSelectedProperties((state) => {
      return [...state, property];
    });
  }

  function handleRemoveProperty(property: Property) {
    if (mainProperty?.id === property.id) {
      setMainProperty(undefined);
    }

    setSelectedProperties((state) => {
      return state.filter((s) => s.id !== property.id);
    });
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [PROPERTY_PERMISSIONS.OFFERS.STORE],
          update: [PROPERTY_PERMISSIONS.OFFERS.UPDATE],
          destroy: [PROPERTY_PERMISSIONS.OFFERS.DESTROY],
          restore: [PROPERTY_PERMISSIONS.OFFERS.RESTORE],
        }}
        resourceName="Oferta"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={status === STATUSES.ACTIVE}
        initialData={{ type: OFFER_TYPES.RENT }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Nova'} Oferta
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RadioGroup
                      name="type"
                      label="Propósito"
                      options={OFFER_TYPES_OPTIONS}
                      row
                      required
                      disabled={!!params.id}
                      onChange={setSelectedType}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      name="observation"
                      label="Observações"
                      multiline
                      rows={4}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <AutocompleteOfferBroughtVias
                      name="offer_brought_via_id"
                      label="Motivo da Oferta"
                      textFieldProps={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutocompleteUsers name="captivator_id" label="Captador" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="price"
                      label={`Preço ${OFFER_TYPES_LABEL[selectedType]}`}
                      mask="currency"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} alignSelf="center">
                    <CheckBox
                      name="price_on_request"
                      label="Preço sob Consulta"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteRealtors
                      name="concurrents"
                      label="Concorrentes"
                      multiple
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader subheader={OFFER_TYPES_LABEL[selectedType]} />

              <CardContent>
                {selectedType === OFFER_TYPES.RENT ? (
                  <RentFields />
                ) : (
                  <SaleFields />
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<Search fontSize="inherit" />}
                      onClick={() => searchPropertyRef.current?.show()}
                    >
                      Imóvel
                    </Button>
                  </Grid>

                  {selectedProperties.map((property) => (
                    <Grid key={property.id} item xs={12} sm={6} md={3}>
                      <PropertyCard
                        property={property}
                        onDelete={() => handleRemoveProperty(property)}
                        startAction={
                          <Button
                            size="small"
                            startIcon={<RemoveRedEye fontSize="inherit" />}
                            onClick={() => setMainProperty(property)}
                            color="success"
                            disabled={mainProperty?.id === property.id}
                          >
                            Principal
                          </Button>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>

      <SearchPropertyDialog
        ref={searchPropertyRef}
        onSelectProperty={handleAddProperty}
      />
    </Container>
  );
};

export default Save;
