import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import EditButton from 'src/components/Table/EditButton';
import StatusIcon from 'src/components/Table/StatusIcon';
import { MEDIA_SOURCE_LABEL, MEDIA_TYPE_LABEL } from 'src/constants';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import IMedia from 'src/interfaces/models/IMedia';
import apiAdmin from 'src/services/api/admin';
import MediaFilterSchema from 'src/validators/Media/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  { field: 'name', headerName: 'Nome', flex: 1 },
  {
    field: 'type',
    headerName: 'Tipo',
    align: 'center',
    headerAlign: 'center',
    valueGetter({ value }) {
      return MEDIA_TYPE_LABEL[value];
    },
  },
  {
    field: 'source',
    headerName: 'Origem',
    align: 'center',
    headerAlign: 'center',
    valueGetter({ value }) {
      return MEDIA_SOURCE_LABEL[value];
    },
  },
  {
    field: 'max_size',
    headerName: 'Tam. Máx.',
    align: 'center',
    headerAlign: 'center',
    valueGetter({ value }) {
      return value ? `${value} KB` : '';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    renderCell(params) {
      return <StatusIcon status={params.row.status} />;
    },
  },
  {
    field: 'actions',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    renderCell(params) {
      return (
        <Can permissions={['update-medias']}>
          <EditButton to={`/midias/${params.id}`} />
        </Can>
      );
    },
  },
];

const List: FC = () => {
  const {
    handlePageChange,
    handlePageSizeChange,
    data: response,
    isLoading,
  } = useApiQueryPagination<IMedia>(apiAdmin.useMediasQuery);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5">Mídias</Typography>

            <Can permissions={['store-medias']}>
              <LinkButton
                to="/midias/novo"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
                Novo
              </LinkButton>
            </Can>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={response?.data.data || []}
                pageSize={response?.data.meta.per_page}
                rowCount={response?.data.meta.total}
                columns={columns}
                loading={isLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                showFilters
                filterProps={{
                  yupSchema: MediaFilterSchema,
                  children: <Filters />,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default List;
