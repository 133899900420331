import yup from 'src/libs/yup';

export const CondominiumDetailStoreSchema = yup.object().shape({
  detail_condominium_id: yup.number().nullable().required().label('Detalhe'),
  value: yup.string().required().label('Valor'),
});

export const CondominiumDetailUpdateSchema = yup.object().shape({
  detail_condominium_id: yup.number().nullable().required().label('Detalhe'),
  value: yup.string().required().label('Valor'),
});
