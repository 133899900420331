import { FC } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BackButton from 'src/components/BackButton';
import NavigationTabs, { NavigationTab } from 'src/components/NavigationTabs';
import TabPanel from 'src/components/TabPanel';
import { ITabEditParams } from 'src/interfaces/IEditParams';

import CondominiumTab from './CondominiumTab';
import DetailsTab from './DetailsTab';
import PeculiaritiesTab from './PeculiaritiesTab';
import { Container } from './styles';

const Save: FC = () => {
  const params = useParams<ITabEditParams>();

  const CondominiumTabs: NavigationTab[] = [
    { name: 'Condomínio', slug: '', Component: CondominiumTab },
    {
      name: 'Peculiaridades',
      slug: 'peculiaridades',
      Component: PeculiaritiesTab,
      disabled: !params.id,
    },
    {
      name: 'Detalhes',
      slug: 'detalhes',
      Component: DetailsTab,
      disabled: !params.id,
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Novo'} Condomínio
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <NavigationTabs
            baseUrl={`/imovel/condominios${params.id ? `/${params.id}` : ''}`}
            tabs={CondominiumTabs}
          />
        </Grid>

        <Grid item xs={12}>
          {CondominiumTabs.map(({ name, slug, Component }) => (
            <TabPanel key={name} show={slug === (params.selectedTab || '')}>
              <Component />
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
