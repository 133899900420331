import { FC, useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DialogActions, DialogContent } from '@mui/material';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Autocomplete from 'src/components/Form/Autocomplete';
import { ITabEditParams } from 'src/interfaces/IEditParams';
import { IClient } from 'src/interfaces/models/IClient';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import masks from 'src/utils/masks';

interface AddFormProps {
  onSubmit?: () => void;
}

const AddForm: FC<AddFormProps> = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const params = useParams<ITabEditParams>();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingData(true);

        const response = await api.get<IClient[]>('admin/clients', {
          params: { type: 'F' },
        });

        setClients(response.data);
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar pessoas.');
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, []);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        setLoading(true);

        await api.post(`admin/clients/${params.id}/spouses`, formData);

        if (onSubmit) onSubmit();
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao salvar cônjuge.');
      } finally {
        setLoading(false);
      }
    },
    [onSubmit, params.id],
  );

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <DialogContent>
        <Autocomplete
          name="client_person_id"
          label="Cônjuge"
          loading={loadingData}
          options={clients.map(({ id, person }) => ({
            key: id,
            label: `${person?.name} (${masks.cpf(person?.document || '')})`,
            value: person?.id,
          }))}
          getOptionDisabled={(option) => option.key == params.id}
          textFieldProps={{ required: true }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Form>
  );
};

export default AddForm;
