import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import {
  ICity,
  ICountry,
  INeighborhood,
  IStreet,
} from 'src/interfaces/models/IAddress';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  AddressStoreSchema,
  AddressUpdateSchema,
} from 'src/validators/Address/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [cities, setCities] = useState<ICity[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<INeighborhood[]>([]);
  const [streets, setStreets] = useState<IStreet[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id ? AddressUpdateSchema : AddressStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const getCities = apiAdminPublic.getCities();
        const getNeighborhoods = apiAdminPublic.getNeighborhoods();
        const getStreets = apiAdminPublic.getStreets();

        const [citiesResp, neighborhoodsResp, streetsResp] = await Promise.all([
          getCities,
          getNeighborhoods,
          getStreets,
        ]);

        setCities(citiesResp.data);
        setNeighborhoods(neighborhoodsResp.data);
        setStreets(streetsResp.data);

        if (params.id) {
          const response = await api.get<ICountry>(
            `admin/addresses/${params.id}`,
          );
          const address = response.data;

          setStatus(address.status);

          formRef.current?.setData(address);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/addresses/${params.id}`, data);
      } else {
        await api.post(`/admin/addresses`, data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/addresses/${params.id}`);
        toast.success('CEP desativado!');
      } else {
        await api.post(`/admin/addresses/${params.id}/restore`);
        toast.success('CEP restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar cep.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchZipCode = async () => {
    try {
      startLayoutLoading();

      const zipCode = formRef.current?.getFieldValue('zip_code');

      const { data } = await apiAdminPublic.getAddressByZipCode(zipCode);

      if (data.id) {
        history.replace(`/enderecos/endereco/${data.id}`);
        return;
      }

      let city = cities.find((city) => city.id === data.city?.id);
      if (!city && data.city) {
        setCities(cities.concat([data.city]));
        city = data.city;
      }
      let neighborhood = neighborhoods.find(
        (neighborhood) => neighborhood.id === data.neighborhood?.id,
      );
      if (!neighborhood && data.neighborhood) {
        setNeighborhoods(neighborhoods.concat([data.neighborhood]));
        neighborhood = data.neighborhood;
      }
      let street = streets.find((street) => street.id === data.street?.id);
      if (!street && data.street) {
        setStreets(streets.concat([data.street]));
        street = data.street;
      }
      setTimeout(() => {
        formRef.current?.setFieldValue('city_id', city?.id);
        formRef.current?.setFieldValue('neighborhood_id', neighborhood?.id);
        formRef.current?.setFieldValue('street_id', street?.id);
      });
      formRef.current?.setFieldValue('latitude', data.latitude);
      formRef.current?.setFieldValue('longitude', data.longitude);
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar CEP.');
    } finally {
      stopLayoutLoading();
    }
  };

  const handleCreateNeighborhood = useCallback(async (value: string) => {
    try {
      setLoading(true);

      const response = await api.post<INeighborhood>(`/admin/neighborhoods`, {
        name: value,
      });
      setNeighborhoods((state) => state.concat([response.data]));
      setTimeout(() => {
        formRef.current?.setFieldValue('neighborhood_id', response.data.id);
      });

      toast.success('Bairro Cadastrado!');
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao cadastrar bairro.');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateStreet = useCallback(async (value: string) => {
    try {
      setLoading(true);

      const response = await api.post<IStreet>(`/admin/streets`, {
        name: value,
      });
      setStreets((state) => state.concat([response.data]));
      setTimeout(() => {
        formRef.current?.setFieldValue('street_id', response.data.id);
      });

      toast.success('Logradouro Cadastrado!');
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao cadastrar logradouro.');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h6">
                {params.id ? 'Editar' : 'Novo'} Endereço
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="zip_code"
                      label="CEP"
                      mask="zip_code"
                      returnUnmasked
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleSearchZipCode}
                              size="large"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      name="city_id"
                      label="Cidade"
                      options={cities.map((city) => ({
                        key: city.id,
                        label: `${city.name} (${city.state?.initials})`,
                        value: city.id,
                      }))}
                      textFieldProps={{ required: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="neighborhood_id"
                      label="Bairro"
                      options={neighborhoods.map((neighborhood) => ({
                        key: neighborhood.id,
                        label: neighborhood.name,
                        value: neighborhood.id,
                      }))}
                      textFieldProps={{ required: true }}
                      creatable
                      handleCreate={handleCreateNeighborhood}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      name="street_id"
                      label="Logradouro"
                      options={streets.map((street) => ({
                        key: street.id,
                        label: street.name,
                        value: street.id,
                      }))}
                      textFieldProps={{ required: true }}
                      creatable
                      handleCreate={handleCreateStreet}
                      loading={loading}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField name="latitude" label="Latitude" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name="longitude" label="Longitude" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can permissions={['destroy-addresses']}>
                {status === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.id}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} cep`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
