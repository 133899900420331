import { FC, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import Form from 'src/components/Form/Form';
import { FormOnSave } from 'src/components/Form/Form/interfaces';
import { SaveIdentityPropertyMediaParams } from 'src/modules/property/@types/params';
import InputPropertyMedias, {
  InputPropertyMediasField,
} from 'src/modules/property/components/Form/InputPropertyMedias';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import { PropertyVideosStoreSchema } from 'src/modules/property/validators/PropertyMedia/save.schema';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';

const Store: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState<InputPropertyMediasField[]>([]);
  const params = useParams<SaveIdentityPropertyMediaParams>();
  const history = useHistory();

  async function goBack() {
    history.goBack();
  }

  const handleSave: FormOnSave = async ({ formData }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      if (!videos.length) {
        toast.warn('Insira ao menos um vídeo.');
        return;
      }

      const { success, data: validData, errors } = await yupValidate(
        PropertyVideosStoreSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const videosFormData = videos.map((photo, index) =>
        objectToFormData({
          file: photo.file,
          ...validData.videos?.[index],
        }),
      );

      for (const videoFormData of videosFormData) {
        await propertyApi.admin.properties.videos.storePropertyVideo(
          params.propertyId,
          videoFormData,
        );

        setVideos((data) => {
          const newData = [...data];
          newData.shift();
          return newData;
        });
      }

      toast.success('Vídeos salvos com sucesso!');

      goBack();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        PropertyVideosStoreSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      ref={formRef}
      permissions={{
        update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
      }}
      onSave={handleSave}
      loading={loading}
      onCancel={goBack}
    >
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <InputPropertyMedias
              name="videos"
              type="property_video"
              fields={videos}
              setFields={setVideos}
              multiple
            />
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default Store;
