import React, { useCallback, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Divider, Grid, Typography } from '@mui/material';
import { Form } from '@unform/web';
import _ from 'lodash';
import Button from 'src/components/Button';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import IconButton from 'src/components/IconButton';
import { AttendanceFunnelViewTypes } from 'src/modules/attendance/@types';
import { Funnel } from 'src/modules/attendance/@types/models';
import AutocompleteFunnels from 'src/modules/attendance/components/Form/_common/AutocompleteFunnels';
import StoreOpportunityDialog from 'src/modules/attendance/components/StoreOpportunityDialog';
import { OPPORTUNITY_STATUS_LABEL } from 'src/modules/attendance/constants';
import { AttendanceFunnelActions } from 'src/modules/attendance/store/ducks/funnel';
import OpportunitiesFilterSchema from 'src/modules/attendance/validators/Opportunity/filter.schema';
import { formatIntToMoney, queryToObject } from 'src/utils/helpers';

import { OpportunitiesContext } from './context';
import OpportunityFilters from './Filters';

const Header: React.FC = () => {
  const { selectedFunnel, view } = useSelector(
    (state) => state.attendance.funnel,
  );
  const dispatch = useDispatch();
  const [storeOpportunityOpen, setStoreOpportunityOpen] = useState(false);
  const filterRef = useRef<FilterDrawerRef>(null);
  const { needsReload, setNeedsReload, opportunitiesTotals } = useContext(
    OpportunitiesContext,
  );
  const location = useLocation();

  const query = queryToObject(location.search);
  const searchStatus = query.status
    ? OPPORTUNITY_STATUS_LABEL[query.status.toString()]
    : '';

  const handleSelectFunnel = useCallback(
    (value: AutocompleteValue<Funnel>) => {
      if (!value) {
        dispatch(AttendanceFunnelActions.attendanceFunnelSelectFunnel(null));
      } else if (value && !_.isArray(value) && value.data) {
        dispatch(
          AttendanceFunnelActions.attendanceFunnelSelectFunnel(value.data),
        );
      }
    },
    [dispatch],
  );

  const handleChangeView = useCallback(
    (selectedView: AttendanceFunnelViewTypes) => {
      dispatch(
        AttendanceFunnelActions.attendanceFunnelChangeView(selectedView),
      );
    },
    [dispatch],
  );

  function reloadOpportunities() {
    setNeedsReload(true);
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={10} sm={4} md={3}>
        <Form
          onSubmit={() => null}
          initialData={{ funnel: selectedFunnel?.id }}
        >
          <AutocompleteFunnels
            name="funnel"
            label="Funil a ser visualizado"
            onChange={handleSelectFunnel}
            requestConfig={{
              params: { user_has_access: true },
            }}
          />
        </Form>
      </Grid>

      {selectedFunnel && (
        <>
          <Grid item xs={12} md>
            <Grid container gap={0.5} alignItems="center">
              <IconButton
                tooltip="Visão em Colunas"
                disabled={view === 'column'}
                onClick={() => handleChangeView('column')}
              >
                <ViewColumnIcon />
              </IconButton>

              <IconButton
                tooltip="Visão em Lista"
                disabled={view === 'list'}
                onClick={() => handleChangeView('list')}
              >
                <ViewListIcon />
              </IconButton>

              <Divider orientation="vertical" flexItem variant="middle" />

              <IconButton
                tooltip="Recarregar Oportunidades"
                color="primary"
                disabled={needsReload}
                onClick={reloadOpportunities}
              >
                <RefreshIcon />
              </IconButton>

              <Typography>
                Total de Oportunidades ({searchStatus}):{' '}
                <strong>
                  {opportunitiesTotals.qty} -{' '}
                  {formatIntToMoney(opportunitiesTotals.sum)}
                </strong>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={'auto'}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  startIcon={<AddIcon />}
                  color="success"
                  variant="contained"
                  onClick={() => setStoreOpportunityOpen(true)}
                >
                  Oportunidade
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<FilterAltIcon />}
                  color="primary"
                  variant="contained"
                  onClick={() => filterRef.current?.open()}
                >
                  Filtros
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <StoreOpportunityDialog
            open={storeOpportunityOpen}
            selectedFunnelId={selectedFunnel.id}
            onClose={() => setStoreOpportunityOpen(false)}
          />

          <FilterDrawer
            ref={filterRef}
            yupSchema={OpportunitiesFilterSchema}
            disablePagination={view === 'column'}
          >
            <OpportunityFilters funnelId={selectedFunnel.id} />
          </FilterDrawer>
        </>
      )}
    </Grid>
  );
};

export default Header;
