import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { Condominium } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getCondominiums(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<Condominium>>(
    '/property/admin/condominiums',
    config,
  );

  return response;
}

export function useCondominiumsQuery(data?: IApiUseQueryData<Condominium>) {
  return useQuery<IApiUseQueryFnData<Condominium>>(
    [PROPERTY_ADMIN_QUERY_KEYS.CONDOMINIUMS, data?.requestConfig?.params],
    () => getCondominiums(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateCondominiumsQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.CONDOMINIUMS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.CONDOMINIUMS]);
}

export async function storeCondominium(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<Condominium>(
    '/property/admin/condominiums',
    data,
    config,
  );

  invalidateCondominiumsQueries();

  return response;
}

export async function getCondominium(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<Condominium>(
    `/property/admin/condominiums/${id}`,
    config,
  );

  return response;
}

export async function updateCondominium(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<Condominium>(
    `/property/admin/condominiums/${id}`,
    data,
    config,
  );

  invalidateCondominiumsQueries();

  return response;
}

export async function destroyCondominium(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/condominiums/${id}`,
    config,
  );

  invalidateCondominiumsQueries();

  return response;
}

export async function restoreCondominium(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/condominiums/${id}/restore`,
    config,
  );

  invalidateCondominiumsQueries();

  return response;
}

export { details } from './details';
export { peculiarities } from './peculiarities';
