import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import Button from 'src/components/Button';
import IconButton from 'src/components/IconButton';
import propertyApi from 'src/modules/property/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';

import { StoreIdentityContext } from './context';
import PropertyDialog, { PropertyDialogFormData } from './PropertyDialog';

type PropertyStepProps = {
  show?: boolean;
};

const PropertyStep: React.FC<PropertyStepProps> = ({ show }) => {
  const { formRef, handleBack } = useContext(StoreIdentityContext);
  const [openPropertyDialog, setOpenPropertyDialog] = useState(false);
  const [properties, setProperties] = useState<PropertyDialogFormData[]>([]);
  const [
    selectedProperty,
    setSelectedProperty,
  ] = useState<PropertyDialogFormData>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const identityType = formRef?.current?.getFieldValue('type');

  async function handleSubmit() {
    try {
      setLoading(true);

      const formData = { ...formRef?.current?.getData() };

      const { data } = await propertyApi.admin.identities.storeIdentity({
        ...formData,
        properties,
      });

      history.replace(`/imovel/identidades/${data.id}`);
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Ocorreu um erro ao salvar o imóvel.',
      );
    } finally {
      setLoading(false);
    }

    return;
  }

  const handlePropertyDialogSucces = (propertyData: PropertyDialogFormData) => {
    setProperties((state) => {
      const index = state.findIndex((s) => s?.id == propertyData.id);
      if (index !== -1) {
        state.splice(index, 1, propertyData);
        return state;
      }

      return [...state, propertyData];
    });
  };

  function handleDeleteProperty(propertyData: PropertyDialogFormData) {
    setProperties((state) => state.filter((s) => s.id !== propertyData.id));
  }

  return (
    <Grid container spacing={1} display={!show ? 'none' : undefined}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  color="success"
                  onClick={() => setOpenPropertyDialog(true)}
                >
                  Imóvel
                </Button>
              </Grid>

              {properties.map((property) => (
                <Grid key={property.id} item xs>
                  <Card>
                    <CardActionArea
                      onClick={() => {
                        setOpenPropertyDialog(true);
                        setSelectedProperty(property);
                      }}
                    >
                      <CardHeader
                        title={property.type?.name}
                        subheader={property.title}
                      />
                    </CardActionArea>

                    <CardActions>
                      <IconButton
                        size="small"
                        color="error"
                        tooltip="Remover"
                        onClick={() => handleDeleteProperty(property)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="end">
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
              disabled={loading}
            >
              Voltar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="success"
              endIcon={<CheckIcon />}
              onClick={handleSubmit}
              loading={loading}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PropertyDialog
        open={openPropertyDialog}
        onClose={() => {
          setSelectedProperty(undefined);
          setOpenPropertyDialog(false);
        }}
        identityType={identityType}
        onSuccess={handlePropertyDialogSucces}
        currentProperty={selectedProperty}
      />
    </Grid>
  );
};

export default PropertyStep;
