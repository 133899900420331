import yup from 'src/libs/yup';

export const BankAccountDocumentStoreSchema = yup.object().shape({
  path: yup.mixed().isFile().required().label('Documento'),
  document_type_id: yup.string().required().label('Tipo'),
  valid_until: yup.string().dateFormat('dd/MM/yyyy').label('Validade'),
  description: yup.string().label('Descrição'),
});

export const BankAccountDocumentUpdateSchema = yup.object().shape({
  path: yup.mixed().isFile().label('Documento'),
  document_type_id: yup.string().required().label('Tipo'),
  valid_until: yup.string().dateFormat('dd/MM/yyyy').label('Validade'),
  description: yup.string().label('Descrição'),
});
