import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { DetailCondominium } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

export async function getDetailsCondominiums(config?: AxiosRequestConfig) {
  const response = await apiAxios.get<IResponseAPIFilter<DetailCondominium>>(
    '/property/admin/details-condominiums',
    config,
  );

  return response;
}

export function useDetailsCondominiumsQuery(
  data?: IApiUseQueryData<DetailCondominium>,
) {
  return useQuery<IApiUseQueryFnData<DetailCondominium>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.DETAILS_CONDOMINIUMS,
      data?.requestConfig?.params,
    ],
    () => getDetailsCondominiums(data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidateDetailsCondominiumsQueries() {
  queryClient.invalidateQueries([
    PROPERTY_COMMON_QUERY_KEYS.DETAILS_CONDOMINIUMS,
  ]);
  queryClient.invalidateQueries([
    PROPERTY_ADMIN_QUERY_KEYS.DETAILS_CONDOMINIUMS,
  ]);
}

export async function storeDetailCondominium(
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<DetailCondominium>(
    '/property/admin/details-condominiums',
    data,
    config,
  );

  invalidateDetailsCondominiumsQueries();

  return response;
}

export async function getDetailCondominium(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<DetailCondominium>(
    `/property/admin/details-condominiums/${id}`,
    config,
  );

  return response;
}

export async function updateDetailCondominium(
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<DetailCondominium>(
    `/property/admin/details-condominiums/${id}`,
    data,
    config,
  );

  invalidateDetailsCondominiumsQueries();

  return response;
}

export async function destroyDetailCondominium(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/details-condominiums/${id}`,
    config,
  );

  invalidateDetailsCondominiumsQueries();

  return response;
}

export async function restoreDetailCondominium(
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/details-condominiums/${id}/restore`,
    config,
  );

  invalidateDetailsCondominiumsQueries();

  return response;
}
