import React, { useContext, useRef } from 'react';

import DeleteIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
import AutocompleteFunnelSteps from 'src/modules/attendance/components/Form/_common/AutocompleteFunnelSteps';
import {
  AttendanceData,
  StoreOpportunityAttendanceDialogContext,
} from 'src/modules/attendance/components/StoreOpportunityAttendanceDialog/context';
import { InterestAttendanceFormSchema } from 'src/modules/attendance/validators/Opportunity/save.schema';
import { showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const AttendanceStep: React.FC = () => {
  const {
    data,
    setData,
    setActiveStep,
    funnelId,
    loading,
    submitAttendance,
  } = useContext(StoreOpportunityAttendanceDialogContext);
  const attendanceFormRef = useRef<FormHandles>(null);

  function backToInterestStep() {
    const currentFormData = attendanceFormRef.current?.getData() as any;

    setData((state) => ({
      ...state,
      attendance: currentFormData,
    }));
    setActiveStep(0);
  }

  async function handleSubmit(formData: AttendanceData) {
    const { success, data, errors } = await yupValidate(
      InterestAttendanceFormSchema,
      formData,
    );

    if (!success) {
      return showFormErrors(errors, attendanceFormRef);
    }

    submitAttendance(data);
  }

  return (
    <Form
      ref={attendanceFormRef}
      onSubmit={handleSubmit}
      initialData={data.attendance}
      noValidate
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField name="client_name" label="Cliente" disabled />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              name="amount"
              label="Valor"
              mask="currency"
              returnUnmasked
              required
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <AutocompleteFunnelSteps
              name="funnel_step_id"
              label="Etapa"
              funnelId={funnelId}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="description"
              label="Detalhes sobre o atendimento"
              required
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Button
              startIcon={<DeleteIcon />}
              onClick={backToInterestStep}
              disabled={loading}
            >
              Voltar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              startIcon={<CheckIcon />}
              color="success"
              variant="contained"
              loading={loading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Form>
  );
};

export default AttendanceStep;
