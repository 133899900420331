import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
import { Opportunity } from 'src/modules/attendance/@types/models';
import attendanceApi from 'src/modules/attendance/services/api';
import { OpportunityUpdateSchema } from 'src/modules/attendance/validators/Opportunity/save.schema';
import { handleApiResponseErrors } from 'src/utils/errors';

export type UpdateOpportunityDialogProps = {
  funnelId: number;
  opportunity: Opportunity;
  onClose: () => void;
  onSuccess?: () => void;
} & DialogProps;

const UpdateOpportunityDialog: React.FC<UpdateOpportunityDialogProps> = ({
  funnelId,
  opportunity,
  onSuccess,
  onClose,
  ...rest
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(formData) {
    try {
      setLoading(true);

      await attendanceApi.admin.funnels.opportunities.updateFunnelOpportunity(
        funnelId,
        opportunity.id,
        formData,
      );

      toast.success('Título Alterado!');

      if (onSuccess) onSuccess();

      onClose();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao alterar título.',
        OpportunityUpdateSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" {...rest}>
      <DialogTitle align="center">Alterar Título da Oportunidade</DialogTitle>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ name: opportunity.name }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField name="name" label="Título da Oportunidade" />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                startIcon={<CloseIcon />}
                color="error"
                onClick={onClose}
                disabled={loading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                startIcon={<CheckIcon />}
                color="success"
                variant="contained"
                loading={loading}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default UpdateOpportunityDialog;
