import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles, Scope, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import AutocompletePropertyTypes from 'src/components/Form/_Common/AutocompletePropertyTypes';
import AddressFields from 'src/components/Form/AddressFields';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import { IEditClientAddressParams } from 'src/interfaces/IEditParams';
import {
  IClientAddress,
  IClientAddressComplement,
} from 'src/interfaces/models/IClient';
import IPropertyType from 'src/interfaces/models/IPropertyType';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  ClientAddressStoreSchema,
  ClientAddressUpdateSchema,
} from 'src/validators/ClientAddress/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [clientAddress, setClientAddress] = useState<IClientAddress | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [clientAddressComplements, setClientAddressComplements] = useState<
    IClientAddressComplement[]
  >([]);
  const history = useHistory();
  const params = useParams<IEditClientAddressParams>();

  const yupSchema = params.addressId
    ? ClientAddressUpdateSchema
    : ClientAddressStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.addressId) {
        const response = await api.get<IClientAddress>(
          `admin/clients/${params.id}/addresses/${params.addressId}`,
        );

        formRef.current?.setData(response.data);
        setClientAddress(response.data);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, params.addressId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.addressId) {
        await api.put(
          `/admin/clients/${params.id}/addresses/${params.addressId}`,
          data,
        );
      } else {
        await api.post(`/admin/clients/${params.id}/addresses`, data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (clientAddress?.status === 'active') {
        await api.delete(
          `/admin/clients/${params.id}/addresses/${params.addressId}`,
        );
        toast.success('Endereço desativado!');
      } else {
        await api.post(
          `/admin/clients/${params.id}/addresses/${params.addressId}/restore`,
        );
        toast.success('Endereço restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar endereço.');
    } finally {
      setLoading(false);
    }
  };

  const onTypeChange = useCallback(
    (value: AutocompleteValue<IPropertyType>) => {
      if (!Array.isArray(value)) {
        const selectedType = value?.data;

        const complements = selectedType?.complements?.map((complement) => {
          const exists = clientAddress?.complements?.find(
            (clientComplement) =>
              clientComplement.address_complement_id === complement.id,
          );
          return {
            id: exists?.id,
            address_complement_id: complement.id,
            complement_value: exists?.complement_value || '',
            addressComplement: complement,
          } as IClientAddressComplement;
        });

        setClientAddressComplements(complements || []);
        complements?.map((complement, index) => {
          setTimeout(() => {
            formRef.current?.setFieldValue(
              `complements[${index}].complement_value`,
              complement.complement_value,
            );
          });
        });
      }
    },
    [clientAddress],
  );

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h6">
                {params.addressId ? 'Editar' : 'Novo'} Endereço
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <AddressFields
                    formRef={formRef}
                    currentAddress={clientAddress?.address}
                    noContainer
                  />

                  <Grid item xs={12}>
                    <CheckBox name="main" label="Principal" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutocompletePropertyTypes
                      name="property_type_id"
                      label="Tipo"
                      requestConfig={{ params: { preload: ['complements'] } }}
                      textFieldProps={{ required: true }}
                      onChange={onTypeChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField name="description" label="Descrição" />
                  </Grid>

                  {clientAddressComplements.map((clientComplement, index) => (
                    <Scope
                      key={clientComplement.id}
                      path={`complements[${index}]`}
                    >
                      <TextField
                        name="id"
                        value={clientComplement.id}
                        disabled
                        hidden
                      />
                      <TextField
                        name="address_complement_id"
                        value={clientComplement.address_complement_id}
                        disabled
                        hidden
                      />
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="complement_value"
                          label={clientComplement.addressComplement?.name}
                          required={
                            clientComplement.addressComplement?.is_required
                          }
                        />
                      </Grid>
                    </Scope>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can permissions={['destroy-client-contacts']}>
                {clientAddress?.status === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.addressId}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.addressId}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      {/* <SearchAddressDialog
        ref={searchAddressDialogRef}
        onSelectAddress={handleSelectAddress}
      /> */}

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${
          clientAddress?.status === 'active' ? 'Desativar' : 'Restaurar'
        } endereço`}
        description="Confirma esta ação?"
        confirmColor={clientAddress?.status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
