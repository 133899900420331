import * as common from './common';
import * as condominiums from './condominiums';
import * as detailsCondominiums from './detailsCondominiums';
import * as detailsProperties from './detailsProperties';
import * as economicIndicators from './economicIndicators';
import * as identities from './identities';
import * as offerBroughtVias from './offerBroughtVias';
import * as offers from './offers';
import * as properties from './properties';
import * as publicityTypes from './publicityTypes';
import * as realtors from './realtors';

export default {
  /** common - Functions that don't need permissions */
  common,
  condominiums,
  detailsCondominiums,
  publicityTypes,
  detailsProperties,
  identities,
  properties,
  realtors,
  economicIndicators,
  offerBroughtVias,
  offers,
};
