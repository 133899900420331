import yup from 'src/libs/yup';

export const AddressComplementStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  input_type: yup.string().required().label('Tipo'),
  input_name: yup.string().label('Nome do Campo'),
  is_required: yup.string().label('Obrigatório'),
});

export const AddressComplementUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  input_type: yup.string().required().label('Tipo'),
  input_name: yup.string().label('Nome do Campo'),
  is_required: yup.string().label('Obrigatório'),
});
