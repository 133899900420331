import yup from 'src/libs/yup';

const mediaObject = yup.object().shape({
  file: yup.mixed().isFile().label('Arquivo'),
  name: yup.string().required().label('Nome'),
  value: yup.string().label('Valor'),
  show_in_website: yup.boolean().required().label('Mostrar no Site'),
});

export const PropertyPhotosStoreSchema = yup.object().shape({
  photos: yup.array().of(mediaObject),
});

export const PropertyVideosStoreSchema = yup.object().shape({
  videos: yup.array().of(mediaObject),
});

export const PropertyFilesStoreSchema = yup.object().shape({
  files: yup.array().of(mediaObject),
});

export const PropertyMediaUpdateSchema = mediaObject;
