import styled, { css } from 'styled-components';

import { Avatar } from '@mui/material';
import MuiPaper from '@mui/material/Paper';

export const Container = styled.div`
  padding: 0;
  display: flex;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const Side = styled(MuiPaper)<{ isChatOpen?: boolean }>`
  width: 100%;
  display: grid;
  align-content: start;

  ${({ theme, isChatOpen }) => css`
    ${theme.breakpoints.down('lg')} {
      ${isChatOpen &&
      css`
        display: none;
      `}
    }
    ${theme.breakpoints.up('sm')} {
      max-width: 300px;
    }
  `}

  .chat-list {
    overflow-y: auto;
  }

  .new-message {
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 20px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`;

export const FreeChatLabel = styled.div`
  &::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.success.dark};
  }
`;

export const AttendantAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;

  position: absolute;
  bottom: 5px;
  left: 5px;

  /* &::after {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.palette.success.dark};
  } */
`;
