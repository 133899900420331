import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import Form from 'src/components/Form/Form';
import { FormOnSave } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { SaveIdentityPropertyParams } from 'src/modules/property/@types/params';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import { PropertyFinancingUpdateSchema } from 'src/modules/property/validators/PropertyFinancing/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const FinancingTab: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [hasFinancing, setHasFinancing] = useState(false);
  const params = useParams<SaveIdentityPropertyParams>();

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      const {
        data: financing,
      } = await propertyApi.admin.properties.financing.getPropertyFinancing(
        params.propertyId,
      );
      setTimeout(() => {
        setHasFinancing(!!financing);
        formRef.current?.setData({ has_financing: !!financing, ...financing });
      });
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.propertyId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave = async ({ formData }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        PropertyFinancingUpdateSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await propertyApi.admin.properties.financing.updatePropertyFinancing(
        params.propertyId,
        data,
      );

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        PropertyFinancingUpdateSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasFinancing) {
      formRef.current?.reset();
    }
  }, [hasFinancing]);

  return (
    <Form
      ref={formRef}
      permissions={{
        update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
      }}
      onSave={handleSave}
      disableSaveAndBack
      isUpdating
      loading={loading || layoutLoading}
    >
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CheckBox
                name="has_financing"
                label="Possui Financiamento?"
                onChange={(_, checked) => setHasFinancing(checked)}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <TextField
                name="total_installments"
                label="Total de Parcelas"
                type="number"
                disabled={!hasFinancing}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <TextField
                name="paid_installments"
                label="Parcelas Pagas"
                type="number"
                disabled={!hasFinancing}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="installment_amount"
                label="Valor da Parcela"
                mask="currency"
                returnUnmasked
                disabled={!hasFinancing}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                name="balance_due_amount"
                label="Saldo Devedor"
                mask="currency"
                returnUnmasked
                disabled={!hasFinancing}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default FinancingTab;
