import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import BackButton from 'src/components/BackButton';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePicker from 'src/components/Form/DatePicker';
import InputFile from 'src/components/Form/InputFile';
import TextField from 'src/components/Form/TextField';
import { IEditClientDocumentParams } from 'src/interfaces/IEditParams';
import { IClientDocument } from 'src/interfaces/models/IClient';
import IDocumentType from 'src/interfaces/models/IDocumentType';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  ClientDocumentStoreSchema,
  ClientDocumentUpdateSchema,
} from 'src/validators/ClientDocument/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [types, setTypes] = useState<IDocumentType[]>([]);
  const history = useHistory();
  const params = useParams<IEditClientDocumentParams>();

  const yupSchema = params.documentId
    ? ClientDocumentUpdateSchema
    : ClientDocumentStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      const typesResp = await apiAdminPublic.getDocumentTypes();
      setTypes(typesResp.data);

      if (params.documentId) {
        const response = await api.get<IClientDocument>(
          `admin/clients/${params.id}/documents/${params.documentId}`,
        );
        const clientDocument = response.data;

        setStatus(clientDocument.status);

        formRef.current?.setData(clientDocument);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, params.documentId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      const formData = objectToFormData(data);

      if (params.documentId) {
        await api.put(
          `/admin/clients/${params.id}/documents/${params.documentId}`,
          formData,
        );
      } else {
        await api.post(`/admin/clients/${params.id}/documents`, formData);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(
          `/admin/clients/${params.id}/documents/${params.documentId}`,
        );
        toast.success('Documento desativado!');
      } else {
        await api.post(
          `/admin/clients/${params.id}/documents/${params.documentId}/restore`,
        );
        toast.success('Documento restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar documento.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h6">
                {params.documentId ? 'Editar' : 'Novo'} Documento
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <InputFile
                      name="path"
                      label="Documento"
                      description="Até 1mb (jpg, jpeg, png, pdf)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          name="document_type_id"
                          label="Tipo"
                          options={types.map((type) => ({
                            key: type.id,
                            label: type.name,
                            value: type.id,
                          }))}
                          textFieldProps={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePicker name="valid_until" label="Validade" />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField name="description" label="Descrição" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Can permissions={['destroy-client-documents']}>
                {status === 'active' ? (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<CloseIcon />}
                    variant="contained"
                    color="error"
                    disabled={!params.documentId}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Desativar
                  </Button>
                ) : (
                  <Button
                    type="button"
                    loading={loading}
                    startIcon={<RestoreIcon />}
                    color="primary"
                    disabled={!params.documentId}
                    onClick={() => confirmDialogRef.current?.show()}
                  >
                    Restaurar
                  </Button>
                )}
              </Can>

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} documento`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Container>
  );
};

export default Save;
