import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Tooltip, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import BackButton from 'src/components/BackButton';
import Autocomplete from 'src/components/Form/Autocomplete';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { INPUT_TYPE_OPTIONS, STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { DetailProperty } from 'src/modules/property/@types/models';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  DetailPropertyStoreSchema,
  DetailPropertyUpdateSchema,
} from 'src/modules/property/validators/DetailProperty/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const detailPropertySchema = params.id
    ? DetailPropertyUpdateSchema
    : DetailPropertyStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.id) {
        const {
          data: detail,
        } = await propertyApi.admin.detailsProperties.getDetailProperty(
          params.id,
        );
        setStatus(detail.status);

        setTimeout(() => {
          formRef.current?.setData(detail);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave: FormOnSave<DetailProperty> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        detailPropertySchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newDetail: DetailProperty;
      if (params.id) {
        const response = await propertyApi.admin.detailsProperties.updateDetailProperty(
          params.id,
          data,
        );
        newDetail = response.data;
      } else {
        const response = await propertyApi.admin.detailsProperties.storeDetailProperty(
          data,
        );
        newDetail = response.data;
      }

      toast.success('Detalhe salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.id) {
        history.replace(`/imovel/detalhes-imoveis/${newDetail.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        detailPropertySchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.detailsProperties.destroyDetailProperty(
        params.id,
      );

      toast.success('Detalhe desativado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Detalhe');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.detailsProperties.restoreDetailProperty(
        params.id,
      );

      toast.success('Detalhe restaurado!');

      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar Detalhe.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <Container maxWidth="xl">
      <Form
        ref={formRef}
        permissions={{
          store: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.STORE],
          update: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.UPDATE],
          destroy: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.DESTROY],
          restore: [PROPERTY_PERMISSIONS.DETAILS_PROPERTIES.RESTORE],
        }}
        resourceName="Detalhe de Imóvel"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={status === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <BackButton />

              <Typography variant="h5">
                {params.id ? 'Editar' : 'Novo'} Detalhe de Imóvel
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={8} md={9}>
                    <TextField name="name" label="Nome" required />
                  </Grid>

                  <Tooltip
                    placement="top"
                    title="Como irá aparecer na url quando for utilizado para buscas no site (Gerado a partir do nome)"
                  >
                    <Grid item xs={12} sm={4} md={3}>
                      <TextField name="slug" label="Slug" disabled />
                    </Grid>
                  </Tooltip>

                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      name="input_type"
                      label="Tipo do Valor"
                      options={INPUT_TYPE_OPTIONS}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </Container>
  );
};

export default Save;
