import React, { RefObject, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardContent, Grid } from '@mui/material';
import { FormHandles, Scope } from '@unform/core';
import SearchClientDialog from 'src/components/_functions/SearchClientDialog';
import { SearchClientDialogRef } from 'src/components/_functions/SearchClientDialog/interfaces';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
import IconButton from 'src/components/IconButton';
import { IClient } from 'src/interfaces/models/IClient';
import { IdentityOwner } from 'src/modules/property/@types/models';

type OwnerField = {
  owner_id: string | number;
  percentage: string;
  owner_name: string;
};

export type CurrentOwnersFieldsData = IdentityOwner[];

type PropertyOwnersProps = {
  formRef?: RefObject<FormHandles>;
  currentValues?: CurrentOwnersFieldsData;
};

const PropertyOwners: React.FC<PropertyOwnersProps> = ({
  formRef,
  currentValues,
}) => {
  const [ownersFields, setOwnersFields] = useState<OwnerField[]>([]);
  const searchClientRef = useRef<SearchClientDialogRef>(null);

  function handleSelectOwner(client: IClient) {
    if (ownersFields.find((ownerField) => ownerField.owner_id == client.id)) {
      return toast.warn('Cliente já definido como proprietário');
    }

    setOwnersFields((state) => [
      ...state,
      { owner_id: client.id, percentage: '', owner_name: client.username },
    ]);

    toast.success('Proprietário adicionado.');
  }

  function handleDeleteOwner(owner: OwnerField) {
    setOwnersFields((state) =>
      state.filter((s) => s.owner_id !== owner.owner_id),
    );
  }

  useEffect(() => {
    if (!currentValues) return setOwnersFields([]);

    const fields: OwnerField[] = currentValues.map((identityOwner) => ({
      owner_id: identityOwner.owner_id,
      percentage: identityOwner.percentage.toString(),
      owner_name: identityOwner.owner?.username || '',
    }));

    setOwnersFields(fields);
    setTimeout(() => {
      formRef?.current?.setData({
        owners: fields,
      });
    });
  }, [formRef, currentValues]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              startIcon={<SearchIcon />}
              variant="outlined"
              color="success"
              onClick={() => searchClientRef.current?.show()}
            >
              Proprietário
            </Button>
          </Grid>

          {ownersFields.map((ownerField, index) => (
            <Grid item xs={12} key={ownerField.owner_id}>
              <Grid container spacing={1} alignItems="center">
                <Scope path={`owners[${index}]`}>
                  <TextField
                    hidden
                    name="owner_id"
                    value={ownerField.owner_id}
                  />
                  <TextField
                    hidden
                    name="owner_name"
                    value={ownerField.owner_name}
                  />

                  <Grid item xs={12} sm={4} md={2}>
                    <TextField
                      name="percentage"
                      label="Participação (%)"
                      mask="percent"
                      returnUnmasked
                    />
                  </Grid>
                </Scope>

                <Grid item xs={12} sm>
                  <IconButton
                    onClick={() => handleDeleteOwner(ownerField)}
                    tooltip="Remover"
                    color="error"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                  {ownerField.owner_name}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <SearchClientDialog
          ref={searchClientRef}
          onSelectClient={handleSelectOwner}
          defaultQueryParams={{ types: ['F', 'J', 'L'] }}
        />
      </CardContent>
    </Card>
  );
};

export default PropertyOwners;
