import React from 'react';

import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteProps } from 'src/components/Form/Autocomplete/interfaces';
import { REALTOR_TYPES_OPTIONS } from 'src/modules/property/constants';

type AutocompleteRealtorTypesProps = Omit<AutocompleteProps<string>, 'options'>;

const AutocompleteRealtorTypes: React.FC<AutocompleteRealtorTypesProps> = (
  props,
) => {
  return <Autocomplete {...props} options={REALTOR_TYPES_OPTIONS} />;
};

export default AutocompleteRealtorTypes;
