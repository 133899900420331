import React from 'react';

import { Grid } from '@mui/material';
import { Scope } from '@unform/core';
import CheckBox from 'src/components/Form/Checkbox';
import TextField from 'src/components/Form/TextField';
import AutocompleteEconomicIndicators from 'src/modules/property/components/Form/_common/AutocompleteEconomicIndicators';
import AutocompleteRealtors from 'src/modules/property/components/Form/_common/AutocompleteRealtors';

const RentFields: React.FC = () => {
  return (
    <Grid container spacing={1}>
      <Scope path="rent">
        <Grid item xs={12} sm={4}>
          <TextField
            name="iptu_percent_owner"
            label="% IPTU do Dono"
            mask="percent"
            returnUnmasked
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            name="iptu_percent_renter"
            label="% IPTU do Inquilino"
            mask="percent"
            returnUnmasked
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            name="contract_months"
            label="Tempo de Contrato (meses)"
            mask="onlyNumbers"
            returnUnmasked
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            name="readjustment_months"
            label="Tempo para Reajuste (meses)"
            mask="onlyNumbers"
            returnUnmasked
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <AutocompleteEconomicIndicators
            name="readjustment_indicator_id"
            label="Indicador de Reajuste"
          />
        </Grid>

        <Grid item xs={12} sm={2} alignSelf="center">
          <CheckBox name="warranty" label="Seguro?" />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            name="initial_fee"
            label="Taxa Inicial"
            mask="percent"
            returnUnmasked
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            name="fee"
            label="Taxa Principal"
            mask="percent"
            returnUnmasked
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            name="fee_secondary"
            label="Taxa Secundária"
            mask="percent"
            returnUnmasked
          />
        </Grid>

        <Grid item xs={12}>
          <AutocompleteRealtors
            name="last_rent_id"
            label="Último aluguel via"
          />
        </Grid>
      </Scope>
    </Grid>
  );
};

export default RentFields;
