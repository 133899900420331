import { FC } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import AutocompleteDetailsCondominiums from 'src/modules/property/components/Form/_common/AutocompleteDetailsCondominiums';

const statusItems = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const CondominiumDetailFilters: FC = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <AutocompleteDetailsCondominiums
          name="detail_condominium_id"
          label="Detalhe"
          size="small"
          multiple
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="value" label="Valor" size="small" />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="status"
          label="Status"
          options={statusItems}
          size="small"
        />
      </Grid>
    </>
  );
};

export default CondominiumDetailFilters;
