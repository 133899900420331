import { FC, useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Scope } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import DatePicker from 'src/components/Form/DatePicker';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import IDocumentType from 'src/interfaces/models/IDocumentType';
import api from 'src/services/api';
import { operators } from 'src/utils/helpers';

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
];

const Filters: FC = () => {
  const [types, setTypes] = useState<IDocumentType[]>([]);
  const [validBetween, setValidBetween] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const typesResp = await api.get<IDocumentType[]>(
          '/admin/document-types',
          {
            params: {
              status: 'active',
              'order_by[0][column]': 'name',
              'order_by[0][direction]': 'asc',
            },
          },
        );
        setTypes(typesResp.data);
      } catch (error) {
        setTypes([]);
      }
    };

    loadData();
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          name="document_type_id"
          label="Tipo"
          size="small"
          options={types.map((type) => ({
            key: type.id,
            label: type.name,
            value: type.id,
          }))}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="description" label="Descrição" size="small" />
      </Grid>

      <Scope path="valid_until">
        <Grid item xs={12}>
          <Typography variant="body1">Data de Validade</Typography>
        </Grid>
        <Grid item xs={4}>
          <Select
            name="operator"
            label="Operador"
            items={operators}
            onChange={(value: any) => setValidBetween(value === 'between')}
            variant="outlined"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={validBetween ? 4 : 8}>
          <DatePicker
            label={validBetween ? 'Até' : 'Data'}
            name="value"
            inputProps={{ size: 'small' }}
          />
        </Grid>

        {validBetween && (
          <Grid item xs={4}>
            <DatePicker
              label="Até"
              name="value_end"
              inputProps={{ size: 'small' }}
            />
          </Grid>
        )}
      </Scope>

      <Grid item xs={12} sm={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
