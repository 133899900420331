import styled from 'styled-components';

import MuiPaper from '@mui/material/Paper';

export const Container = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(1, 1)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const FileItem = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey['400']};
  border-radius: 4px;
`;
