import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { PropertyMedia } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getPropertyVideos(
  propertyId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<PropertyMedia>>(
    `/property/admin/properties/${propertyId}/videos`,
    config,
  );

  return response;
}

function usePropertyVideosQuery(
  propertyId: string | number,
  data?: IApiUseQueryData<PropertyMedia>,
) {
  return useQuery<IApiUseQueryFnData<PropertyMedia>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_VIDEOS,
      propertyId,
      data?.requestConfig?.params,
    ],
    () => getPropertyVideos(propertyId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePropertyVideosQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PROPERTY_VIDEOS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_VIDEOS]);
}

async function storePropertyVideo(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<PropertyMedia>(
    `/property/admin/properties/${propertyId}/videos`,
    data,
    config,
  );

  invalidatePropertyVideosQueries();

  return response;
}

async function getPropertyVideo(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PropertyMedia>(
    `/property/admin/properties/${propertyId}/videos/${id}`,
    config,
  );

  return response;
}

async function updatePropertyVideo(
  propertyId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PropertyMedia>(
    `/property/admin/properties/${propertyId}/videos/${id}`,
    data,
    config,
  );

  invalidatePropertyVideosQueries();

  return response;
}

async function destroyPropertyVideo(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${propertyId}/videos/${id}`,
    config,
  );

  invalidatePropertyVideosQueries();

  return response;
}

async function restorePropertyVideo(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/properties/${propertyId}/videos/${id}/restore`,
    config,
  );

  invalidatePropertyVideosQueries();

  return response;
}

export const videos = {
  getPropertyVideos,
  usePropertyVideosQuery,
  storePropertyVideo,
  getPropertyVideo,
  updatePropertyVideo,
  destroyPropertyVideo,
  restorePropertyVideo,
};
