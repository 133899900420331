import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { queryClient } from 'src/config/query';
import { PropertyKey } from 'src/modules/property/@types/models';
import {
  PROPERTY_ADMIN_QUERY_KEYS,
  PROPERTY_COMMON_QUERY_KEYS,
} from 'src/modules/property/constants/query';
import apiAxios from 'src/services/api';
import {
  IApiUseQueryData,
  IApiUseQueryFnData,
  IResponseAPIFilter,
} from 'src/services/api/interfaces';

async function getPropertyKeys(
  propertyId: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<IResponseAPIFilter<PropertyKey>>(
    `/property/admin/properties/${propertyId}/keys`,
    config,
  );

  return response;
}

function usePropertyKeysQuery(
  propertyId: string | number,
  data?: IApiUseQueryData<PropertyKey>,
) {
  return useQuery<IApiUseQueryFnData<PropertyKey>>(
    [
      PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_KEYS,
      propertyId,
      data?.requestConfig?.params,
    ],
    () => getPropertyKeys(propertyId, data?.requestConfig),
    data?.queryOptions,
  );
}

function invalidatePropertyKeysQueries() {
  queryClient.invalidateQueries([PROPERTY_COMMON_QUERY_KEYS.PROPERTY_KEYS]);
  queryClient.invalidateQueries([PROPERTY_ADMIN_QUERY_KEYS.PROPERTY_KEYS]);
}

async function storePropertyKey(
  propertyId: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<PropertyKey>(
    `/property/admin/properties/${propertyId}/keys`,
    data,
    config,
  );

  invalidatePropertyKeysQueries();

  return response;
}

async function getPropertyKey(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.get<PropertyKey>(
    `/property/admin/properties/${propertyId}/keys/${id}`,
    config,
  );

  return response;
}

async function updatePropertyKey(
  propertyId: string | number,
  id: string | number,
  data: object | FormData,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.put<PropertyKey>(
    `/property/admin/properties/${propertyId}/keys/${id}`,
    data,
    config,
  );

  invalidatePropertyKeysQueries();

  return response;
}

async function destroyPropertyKey(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.delete<boolean>(
    `/property/admin/properties/${propertyId}/keys/${id}`,
    config,
  );

  invalidatePropertyKeysQueries();

  return response;
}

async function restorePropertyKey(
  propertyId: string | number,
  id: string | number,
  config?: AxiosRequestConfig,
) {
  const response = await apiAxios.post<boolean>(
    `/property/admin/properties/${propertyId}/keys/${id}/restore`,
    config,
  );

  invalidatePropertyKeysQueries();

  return response;
}

export const keys = {
  getPropertyKeys,
  usePropertyKeysQuery,
  storePropertyKey,
  getPropertyKey,
  updatePropertyKey,
  destroyPropertyKey,
  restorePropertyKey,
};
