import * as Funnels from './Funnels';
import Opportunities from './Opportunities';
import OpportunityDetails from './OpportunityDetails';
import * as Reasons from './Reasons';

export default {
  Reasons,
  Funnels,
  Opportunities,
  OpportunityDetails,
};
