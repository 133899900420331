import { createContext, Dispatch, SetStateAction } from 'react';

import IDepartment from 'src/interfaces/models/IDepartment';
import {
  Chat,
  ChatMessage,
  ChatMessageTypes,
} from 'src/services/websocket/omnichannel/interfaces';

export type UploadFile = {
  previewURL: string;
  file: File;
  message?: string;
};

export type Upload = {
  type: ChatMessageTypes;
  accept: string;
  uploadFiles: UploadFile[];
};

interface IOmnichannelContext {
  department?: IDepartment;
  setDepartment: Dispatch<SetStateAction<IDepartment | undefined>>;
  chats: Chat[];
  selectedChat?: Chat;
  setSelectedChat: Dispatch<SetStateAction<Chat | undefined>>;
  selectedChatMessages: ChatMessage[];
  setSelectedChatMessages: Dispatch<SetStateAction<ChatMessage[]>>;
  answerMessage?: ChatMessage;
  setAnswerMessage: Dispatch<SetStateAction<ChatMessage | undefined>>;
  upload: Upload | null;
  setUpload: Dispatch<SetStateAction<Upload | null>>;
  adminView: boolean;
  setAdminView: Dispatch<SetStateAction<boolean>>;
}

const OmnichannelContext = createContext<IOmnichannelContext>({
  setDepartment: () => null,
  chats: [],
  setSelectedChat: () => null,
  selectedChatMessages: [],
  setSelectedChatMessages: () => null,
  setAnswerMessage: () => null,
  upload: null,
  setUpload: () => null,
  adminView: false,
  setAdminView: () => null,
});

export default OmnichannelContext;
