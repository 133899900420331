import { ReactNode } from 'react';

import { Delete, Edit } from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import Button from 'src/components/Button';
import { Property } from 'src/modules/property/@types/models';

type PropertyCardProps = {
  property: Property;
  onDelete?: () => void;
  startAction?: ReactNode;
  endAction?: ReactNode;
};

const PropertyCard: React.FC<PropertyCardProps> = ({
  property,
  onDelete,
  startAction,
  endAction,
}) => {
  function openProperty() {
    window.open(
      `/imovel/identidades/${property.identity_id}/imoveis/${property.id}`,
      '_blank',
    );
  }

  return (
    <Card>
      {property.cover?.url && (
        <CardMedia
          sx={{ height: 140 }}
          image={property.cover.url}
          title={property.cover.name}
        />
      )}

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {property.propertyType?.name} - {property.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {property.full_address}
        </Typography>
      </CardContent>

      <CardActions sx={{ justifyContent: 'space-around' }}>
        {startAction}

        <Button
          size="small"
          startIcon={<Edit fontSize="inherit" />}
          onClick={openProperty}
        >
          Cadastro
        </Button>

        {onDelete && (
          <Button
            size="small"
            startIcon={<Delete fontSize="inherit" />}
            color="error"
            onClick={() => onDelete()}
          >
            Remover
          </Button>
        )}

        {endAction}
      </CardActions>
    </Card>
  );
};

export default PropertyCard;
