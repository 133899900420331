import React from 'react';
import { MessageBox } from 'react-chat-elements';

import { formatDistanceToNow } from 'date-fns';
import { MessageProps } from 'src/pages/Private/Omnichannel/Chat/ChatContent/Message';
import OminichannelUtils from 'src/utils/omnichannel';

const Video: React.FC<MessageProps> = ({ message, onReply, reply }) => {
  if (!message.whatsapp) return null;

  return (
    <MessageBox
      id={message.whatsapp.messageId}
      notch
      removeButton={false}
      reply={reply}
      replyButton={!!onReply}
      onReplyClick={onReply}
      retracted={false}
      status={OminichannelUtils.getChatMessageStatus(message)}
      position={message.direction === 'outgoing' ? 'right' : 'left'}
      type="video"
      data={{
        videoURL: message.whatsapp.video?.videoUrl,
        status: {
          click: true,
          loading: 0,
          download: true,
        },
      }}
      controlsList=""
      title={''}
      titleColor=""
      text={''}
      date={new Date(message.sended_at)}
      dateString={formatDistanceToNow(new Date(message.sended_at), {
        addSuffix: true,
        includeSeconds: true,
      })}
      focus={false}
      forwarded={message.whatsapp.forwarded}
    />
  );
};

export default Video;
