import { FC, useContext } from 'react';

import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDistanceToNow, isBefore, parseISO } from 'date-fns';
import { AttendantAvatar } from 'src/pages/Private/Omnichannel/Chat/styles';
import OmnichannelContext from 'src/pages/Private/Omnichannel/context';
import apiAdminPublic from 'src/services/api/adminPublic';
import { Chat } from 'src/services/websocket/omnichannel/interfaces';
import OminichannelUtils from 'src/utils/omnichannel';

interface ChatItemProps {
  chat: Chat;
}

const ChatItem: FC<ChatItemProps> = ({ chat }) => {
  const { selectedChat, setSelectedChat, adminView } = useContext(
    OmnichannelContext,
  );
  const { data: response } = apiAdminPublic.useUserQuery(
    chat.attendant_id || 0,
    {
      queryOptions: { enabled: !!chat.attendant_id },
    },
  );

  function renderChatLastMessage() {
    if (!chat.last_message) return null;

    const Icon = OminichannelUtils.getChatMessageIcon(chat.last_message);

    return (
      <>
        {formatDistanceToNow(new Date(chat.last_message.sended_at))}:{' '}
        {Icon && <Icon fontSize="inherit" />}{' '}
        {OminichannelUtils.getChatMessageText(chat.last_message)}
      </>
    );
  }

  function getUnreadClass() {
    if (!adminView && chat._id === selectedChat?._id) return '';

    let unread = false;
    if (!chat.view_at) {
      unread = true;
    } else if (
      chat.view_at &&
      chat.last_message?.direction === 'incoming' &&
      chat.last_message?.sended_at &&
      isBefore(parseISO(chat.view_at), parseISO(chat.last_message.sended_at))
    ) {
      unread = true;
    }

    return unread ? 'new-message' : '';
  }

  function renderAvatar() {
    let letters;

    if (chat.client?.username) {
      const exploded = chat.client.username.split(' ');
      if (exploded.length === 1) {
        letters = exploded.pop()?.substr(0, 2);
      } else {
        letters = `${exploded.shift()?.charAt(0) || ''}${
          exploded.pop()?.charAt(0) || ''
        }`;
      }
    }

    return (
      <Avatar alt={letters} src={chat.image}>
        {letters}
      </Avatar>
    );
  }

  function openChat() {
    if (selectedChat?._id !== chat._id) {
      setSelectedChat(chat);
    }
  }

  return (
    <>
      <ListItem
        button
        selected={chat._id === selectedChat?._id}
        className={`${getUnreadClass()}`}
        onClick={openChat}
      >
        <ListItemAvatar>{renderAvatar()}</ListItemAvatar>

        <ListItemText
          primary={
            <Typography variant="body1" noWrap>
              {chat.client?.username}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2" noWrap color="GrayText">
                {renderChatLastMessage()}
              </Typography>
            </>
          }
        />

        {response?.data && (
          <Tooltip title={response.data.name}>
            <AttendantAvatar src={response.data.photo} alt={response.data.name}>
              {response.data.name}
            </AttendantAvatar>
          </Tooltip>
        )}
      </ListItem>

      <Divider variant="middle" />
    </>
  );
};

export default ChatItem;
