import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import TextField from 'src/components/Form/TextField';
import yup from 'src/libs/yup';
import AutocompleteFunnels from 'src/modules/attendance/components/Form/_common/AutocompleteFunnels';
import WSOmnichannel from 'src/services/websocket/omnichannel';
import { Chat } from 'src/services/websocket/omnichannel/interfaces';
import { handleApiResponseErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

type NewFormProps = {
  chat: Chat;
  onBack: () => void;
  onSuccess: () => void;
};

const validSchema = yup.object().shape({
  name: yup.string().emptyAsNull().label('Título'),
  funnel_id: yup.number().required().label('Funil'),
});

const NewForm: React.FC<NewFormProps> = ({ chat, onBack, onSuccess }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(formData) {
    try {
      setLoading(true);

      const { success, data, errors } = await yupValidate(
        validSchema,
        formData,
      );

      if (!success) {
        return formRef.current?.setErrors(errors);
      }

      WSOmnichannel.socket?.emit(
        'bind_opportunity',
        {
          chat_id: chat._id,
          funnel_id: data.funnel_id,
          name: data.name,
        },
        (result) => {
          if (result.error) {
            toast.error(result.error.message);
          } else {
            toast.success('Chat vinculado a nova oportunidade.');
            onBack();
            onSuccess();
          }
        },
      );
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao cadastrar oportunidade.',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <DialogTitle align="center">
        Abrir Oportunidade para o cliente
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} marginTop={0}>
          <Grid item xs={12}>
            <TextField name="name" label="Título (Opcional)" />
          </Grid>

          <Grid item xs={12}>
            <AutocompleteFunnels
              name="funnel_id"
              label="Funil"
              textFieldProps={{ required: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<ArrowLeftIcon />}
          color="error"
          onClick={onBack}
          disabled={loading}
        >
          Voltar
        </Button>
        <Button
          type="submit"
          startIcon={<CheckIcon />}
          color="success"
          variant="contained"
          loading={loading}
        >
          Salvar
        </Button>
      </DialogActions>
    </Form>
  );
};

export default NewForm;
