import { FC, useState } from 'react';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Scope } from '@unform/core';
import DatePicker from 'src/components/Form/DatePicker';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { operators } from 'src/utils/helpers';

const typesItems: Item[] = [
  { key: 1, label: 'Contato', value: 'L' },
  { key: 2, label: 'Pessoa Física', value: 'F' },
  { key: 3, label: 'Pessoa Jurídica', value: 'J' },
];

const statusItems: Item[] = [
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
  { key: 3, label: 'Vinculado', value: 'bonded' },
];

const Filters: FC = () => {
  const [type, setType] = useState('');
  const [birthBetween, setBirthBetween] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField name="username" label="Nome de Usuário" size="small" />
      </Grid>
      <Grid item xs={12}>
        <TextField name="email" label="E-mail" type="email" size="small" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="phone"
          label="Telefone"
          mask="phoneOrCellphone"
          returnUnmasked
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>

      <Grid item xs={12}>
        <Select
          name="type"
          label="Tipo"
          items={typesItems}
          size="small"
          onChange={setType}
        />
      </Grid>

      {type === 'F' && (
        <>
          <Grid item xs={12}>
            <TextField name="person_name" label="Nome" size="small" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="person_document"
              label="CPF"
              mask="cpf"
              returnUnmasked
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="person_register_code" label="RG" size="small" />
          </Grid>

          <Scope path="birth_date">
            <Grid item xs={12}>
              <Typography variant="body1">Data de Nascimento</Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                name="operator"
                label="Operador"
                items={operators}
                onChange={(value: any) => setBirthBetween(value === 'between')}
                variant="outlined"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={birthBetween ? 4 : 8}>
              <DatePicker
                label={birthBetween ? 'Até' : 'Data'}
                name="value"
                inputProps={{ size: 'small' }}
              />
            </Grid>

            {birthBetween && (
              <Grid item xs={4}>
                <DatePicker
                  label="Até"
                  name="value_end"
                  inputProps={{ size: 'small' }}
                />
              </Grid>
            )}
          </Scope>
        </>
      )}

      {type === 'J' && (
        <>
          <Grid item xs={12}>
            <TextField
              name="enterprise_name"
              label="Razão Social / Nome fantasia"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="enterprise_document"
              label="CNPJ"
              mask="cnpj"
              returnUnmasked
              size="small"
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default Filters;
