import yup from 'src/libs/yup';

export const DetailPropertyStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  input_type: yup.string().label('Tipo do Valor'),
});

export const DetailPropertyUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  input_type: yup.string().label('Tipo do Valor'),
});
