import { FC } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';

type LinkProps = RouterLinkProps & MuiLinkProps;

const Link: FC<LinkProps> = ({ children, ...rest }) => {
  return (
    <MuiLink {...rest} component={RouterLink}>
      {children}
    </MuiLink>
  );
};

export default Link;
