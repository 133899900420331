import yup from 'src/libs/yup';

const DetailPropertyFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  slug: yup.string().label('Slug'),
  input_type: yup.string().nullable().label('Tipo do Valor'),
  status: yup.string().nullable().label('Status'),
});

export default DetailPropertyFilterSchema;
