import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import BackButton from 'src/components/BackButton';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import CheckBox from 'src/components/Form/Checkbox';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import {
  CondominiumDetail,
  DetailCondominium,
} from 'src/modules/property/@types/models';
import { SaveCondominiumDetailsParams } from 'src/modules/property/@types/params';
import AutocompleteDetailsCondominiums from 'src/modules/property/components/Form/_common/AutocompleteDetailsCondominiums';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import {
  CondominiumDetailStoreSchema,
  CondominiumDetailUpdateSchema,
} from 'src/modules/property/validators/CondominiumDetail/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('');
  const [
    condominiumDetail,
    setCondominiumDetail,
  ] = useState<CondominiumDetail>();
  const history = useHistory();
  const params = useParams<SaveCondominiumDetailsParams>();
  const { url } = useRouteMatch();
  const [selectedDetail, setSelectedDetail] = useState<DetailCondominium>();

  const detailSchema = params.detailId
    ? CondominiumDetailUpdateSchema
    : CondominiumDetailStoreSchema;

  const loadData = useCallback(async () => {
    try {
      startLayoutLoading();

      if (params.detailId) {
        const {
          data: detail,
        } = await propertyApi.admin.condominiums.details.getCondominiumDetail(
          params.id,
          params.detailId,
        );
        setCondominiumDetail(detail);
        setStatus(detail.status);

        setTimeout(() => {
          formRef.current?.setData(detail);
        });
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
    } finally {
      stopLayoutLoading();
    }
  }, [params.id, params.detailId, startLayoutLoading, stopLayoutLoading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (
      selectedDetail &&
      selectedDetail.id === condominiumDetail?.detail_condominium_id
    ) {
      formRef.current?.setFieldValue('value', condominiumDetail.value);
    }
  }, [selectedDetail, condominiumDetail]);

  const handleSave: FormOnSave<CondominiumDetail> = async ({
    formData,
    shouldGoBack,
  }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        detailSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      let newDetail: CondominiumDetail;
      if (params.detailId) {
        const response = await propertyApi.admin.condominiums.details.updateCondominiumDetail(
          params.id,
          params.detailId,
          data,
        );
        newDetail = response.data;
      } else {
        const response = await propertyApi.admin.condominiums.details.storeCondominiumDetail(
          params.id,
          data,
        );
        newDetail = response.data;
      }

      toast.success('Detalhe salvo com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      } else if (!params.detailId) {
        history.replace(`${url}/${newDetail.id}`);
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', detailSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async (isDeleting) => {
    try {
      setLoading(true);

      if (params.detailId) {
        let deleteConfig: AxiosRequestConfig = {};
        if (isDeleting) {
          deleteConfig = { data: { delete: true } };
        }

        await propertyApi.admin.condominiums.details.destroyCondominiumDetail(
          params.id,
          params.detailId,
          deleteConfig,
        );

        toast.success('Detalhe desativado!');

        if (isDeleting) return history.goBack();

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao desativar Detalhe');
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      if (params.detailId) {
        await propertyApi.admin.condominiums.details.restoreCondominiumDetail(
          params.id,
          params.detailId,
        );

        toast.success('Detalhe restaurado!');

        loadData();
      }
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao restaurar Detalhe.');
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  function onChangeDetail(value: AutocompleteValue<DetailCondominium>) {
    if (!value || Array.isArray(value)) return setSelectedDetail(undefined);

    setSelectedDetail(value.data);
  }

  return (
    <Form
      ref={formRef}
      permissions={{
        store: [PROPERTY_PERMISSIONS.CONDOMINIUMS.STORE],
        update: [PROPERTY_PERMISSIONS.CONDOMINIUMS.UPDATE],
        destroy: [PROPERTY_PERMISSIONS.CONDOMINIUMS.DESTROY],
        restore: [PROPERTY_PERMISSIONS.CONDOMINIUMS.RESTORE],
      }}
      resourceName="Detalhe"
      onSave={handleSave}
      onDestroy={handleDestroy}
      canDelete
      onRestore={handleRestore}
      onCancel={handleCancel}
      loading={loading || layoutLoading}
      isUpdating={!!params.detailId}
      isActive={status === STATUSES.ACTIVE}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.detailId ? 'Editar' : 'Novo'} Detalhe
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteDetailsCondominiums
                    name="detail_condominium_id"
                    label="Detalhe"
                    onChange={onChangeDetail}
                  />
                </Grid>

                {!!selectedDetail && (
                  <Grid item xs={12} sm={6}>
                    {selectedDetail.input_type === 'boolean' ? (
                      <CheckBox name="value" label="Sim" />
                    ) : (
                      <TextField
                        name="value"
                        label="Valor"
                        required={selectedDetail.is_required}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Save;
