import React, { useState } from 'react';

import { Grid, TextFieldProps, Typography } from '@mui/material';
import { Scope } from '@unform/core';
import Autocomplete from 'src/components/Form/Autocomplete';
import { AutocompleteValue } from 'src/components/Form/Autocomplete/interfaces';
import TextField from 'src/components/Form/TextField';
import { operators } from 'src/utils/helpers';

type NumberOperatorProps = {
  name: string;
  label?: string;
  size?: TextFieldProps['size'];
};

const NumberOperator: React.FC<NumberOperatorProps> = ({
  name,
  label,
  size,
}) => {
  const [between, setBetween] = useState(false);

  function onOperatorChange(option: AutocompleteValue) {
    let isBetween = false;
    if (!Array.isArray(option)) {
      isBetween = option?.value === 'between';
    }
    setBetween(isBetween);
  }

  return (
    <Grid container spacing={1}>
      <Scope path={name}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="body1">{label}</Typography>
          </Grid>
        )}
        <Grid item xs={4}>
          <Autocomplete
            name="operator"
            label="Operador"
            options={operators}
            onChange={onOperatorChange}
            fullWidth
            size={size}
          />
        </Grid>

        <Grid item xs={between ? 4 : 8}>
          <TextField
            type="number"
            name="value"
            label={between ? 'De' : 'Valor'}
            size={size}
          />
        </Grid>

        {between && (
          <Grid item xs={4}>
            <TextField type="number" name="value_end" label="Até" size={size} />
          </Grid>
        )}
      </Scope>
    </Grid>
  );
};

export default NumberOperator;
