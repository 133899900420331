import yup from 'src/libs/yup';

export const ClientContactStoreSchema = yup.object().shape({
  contact_type_id: yup.string().required().label('Tipo'),
  contact_value: yup.string().required().label('Valor'),
  description: yup.string().label('Nome'),
});

export const ClientContactUpdateSchema = yup.object().shape({
  contact_type_id: yup.string().required().label('Tipo'),
  contact_value: yup.string().required().label('Valor'),
  description: yup.string().label('Nome'),
});
