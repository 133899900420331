import yup from 'src/libs/yup';

const ownerObject = yup.object().shape({
  owner_id: yup.number().required().label('Proprietário'),
  percentage: yup.string().required().label('Participação'),
});

const infoObject = yup.object().shape({
  iptu_code: yup.string().emptyAsNull().label('Código do IPTU'),
  iptu_parcel_amount: yup.string().emptyAsNull().label('Valor do IPTU'),
  iptu_managed: yup.boolean().label('IPTU Gerenciado'),
  registration_code: yup.string().emptyAsNull().label('Nº da Matrícula'),
  energy_code: yup.string().emptyAsNull().label('Código de Energia'),
  water_code: yup.string().emptyAsNull().label('Código de Água'),
  has_deed: yup.boolean().label('Possui Escritura'),
  occupation_license_at: yup.date().nullable().label('Data do Habite-se'),
  condominium_amount: yup.string().emptyAsNull().label('Valor do Condomínio'),
});

export const PropertyStoreSchema = yup.object().shape({
  title: yup.string().required().label('Título'),
  description: yup.string().label('Descrição'),
  property_type_id: yup.number().nullable().required().label('Tipo'),
  complements: yup.array().of(
    yup.object().shape({
      is_required: yup.boolean(),
      address_complement_id: yup.number().required().label('Complement'),
      value: yup
        .string()
        .when('is_required', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label('Valor'),
    }),
  ),
  details: yup.array().of(
    yup.object().shape({
      is_required: yup.boolean(),
      detail_property_id: yup.number().required().label('Detalhe'),
      value: yup
        .string()
        .when('is_required', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label('Valor'),
    }),
  ),
  owners: yup.array().of(ownerObject),
  info: infoObject,
  publicities: yup.array().of(yup.number()).label('Tipos de Publicidades'),
});

export const PropertyUpdateSchema = yup.object().shape({
  title: yup.string().required().label('Título'),
  description: yup.string().label('Descrição'),
  property_type_id: yup.number().nullable().required().label('Tipo'),
  complements: yup.array().of(
    yup.object().shape({
      is_required: yup.boolean(),
      address_complement_id: yup.number().required().label('Complement'),
      value: yup
        .string()
        .when('is_required', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label('Valor'),
    }),
  ),
  details: yup.array().of(
    yup.object().shape({
      is_required: yup.boolean(),
      detail_property_id: yup.number().required().label('Detalhe'),
      value: yup
        .string()
        .when('is_required', {
          is: true,
          then: (schema) => schema.required(),
        })
        .label('Valor'),
    }),
  ),
  owners: yup.array().of(ownerObject),
  info: infoObject,
  publicities: yup.array().of(yup.number()).label('Tipos de Publicidades'),
});
