import React from 'react';
import { useSelector } from 'react-redux';

import ViewColumn from './ViewColumn';
import ViewList from './ViewList';

const Content: React.FC = () => {
  const { selectedFunnel, view } = useSelector(
    (state) => state.attendance.funnel,
  );

  if (!selectedFunnel) return null;

  switch (view) {
    case 'column':
      return <ViewColumn funnel={selectedFunnel} />;
    case 'list':
      return <ViewList funnel={selectedFunnel} />;
    default:
      return <ViewColumn funnel={selectedFunnel} />;
  }
};

export default Content;
