import { MessageBoxType } from 'react-chat-elements';

import { SvgIconComponent } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Chat,
  ChatMessage,
} from 'src/services/websocket/omnichannel/interfaces';
import { store } from 'src/store';

function getChatMessageIcon({
  channel,
}: ChatMessage): SvgIconComponent | undefined {
  if (channel === 'whatsapp') return WhatsAppIcon;
}

function getChatMessageText(message: ChatMessage): string {
  if (message?.channel === 'whatsapp') {
    const { whatsapp } = message;

    if (message.type === 'text') return whatsapp?.text?.message || '';
    if (message.type === 'image') return 'Imagem';
    if (message.type === 'document') return 'Documento';
    if (message.type === 'audio') return 'Áudio';
    if (message.type === 'video') return 'Vídeo';
    if (message.type === 'externalAdReply')
      return whatsapp?.text?.message || '';
  }

  if (message.channel === 'system') return message.system?.text || '';

  return '';
}

function getChatMessageStatus(message: ChatMessage): MessageBoxType['status'] {
  if (message.whatsapp) {
    switch (message.whatsapp.status) {
      case 'PENDING':
        return 'waiting';
      case 'PLAYED':
      case 'READ':
      case 'READ-SELF':
        return 'read';
      case 'RECEIVED':
        return 'received';
      default:
        return 'sent';
    }
  }

  return 'waiting';
}

function getChatMessageBoxType(message: ChatMessage): MessageBoxType['type'] {
  switch (message.type) {
    case 'text':
      return 'text';
    case 'document':
      return 'file';
    case 'audio':
      return 'audio';
    case 'image':
      return 'photo';
    default:
      return 'text';
  }
}

function scrollToChatMessage(id?: string) {
  if (!id) return;
  const messageElement = document.getElementById(`chat-message-${id}`);
  messageElement?.scrollIntoView({ behavior: 'smooth' });
  messageElement?.classList.add('selected');
  setTimeout(() => {
    messageElement?.classList.remove('selected');
  }, 1000);
}

function hasAccessToChat(chat: Chat) {
  const { user } = store.getState().auth;

  if (!user) return false;

  if (
    user.is_admin ||
    chat.attendant_id === user.id ||
    chat.attendants_ids?.includes(user.id)
  )
    return true;

  return false;
}

const OminichannelUtils = {
  getChatMessageText,
  getChatMessageIcon,
  getChatMessageBoxType,
  getChatMessageStatus,
  scrollToChatMessage,
  hasAccessToChat,
};

export default OminichannelUtils;
