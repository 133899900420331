import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import AddressFields from 'src/components/Form/AddressFields';
import Form from 'src/components/Form/Form';
import { FormOnSave, FormProps } from 'src/components/Form/Form/interfaces';
import RadioGroup from 'src/components/Form/RadioGroup';
import TextField from 'src/components/Form/TextField';
import { STATUSES } from 'src/constants';
import { IEditParams } from 'src/interfaces/IEditParams';
import { Reason } from 'src/modules/attendance/@types/models';
import AutocompleteCondominiums from 'src/modules/property/components/Form/_common/AutocompleteCondominiums';
import { IDENTITY_TYPE_OPTIONS } from 'src/modules/property/constants';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import { IdentityUpdateSchema } from 'src/modules/property/validators/Identity/save.schema';
import PrivateContext from 'src/routes/Private/PrivateContext';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';

import { IdentityUpdateContext } from '../context';
import IdentityInfo from './Infos';

const IdentityTab: FC = () => {
  const { layoutLoading } = useContext(PrivateContext);
  const { identity } = useContext(IdentityUpdateContext);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams<IEditParams>();

  useEffect(() => {
    formRef.current?.setData({ ...identity });
  }, [identity]);

  const handleSave: FormOnSave<Reason> = async ({ formData, shouldGoBack }) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        IdentityUpdateSchema,
        formData,
      );

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      await propertyApi.admin.identities.updateIdentity(params.id, data);

      toast.success('Identidade salva com sucesso!');

      if (shouldGoBack) {
        history.goBack();
      }
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro',
        IdentityUpdateSchema,
        formRef,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDestroy: FormProps['onDestroy'] = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.identities.destroyIdentity(params.id);

      toast.success('Identidade do Imóvel desativado!');

      history.goBack();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao desativar Identidade do Imóvel',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      setLoading(true);

      await propertyApi.admin.identities.restoreIdentity(params.id);

      toast.success('Identidade do Imóvel restaurado!');

      history.goBack();
    } catch (error) {
      handleApiResponseErrors(
        error.response,
        'Erro ao atualizar Identidade do Imóvel.',
      );
    } finally {
      setLoading(false);
    }
  };

  async function handleCancel() {
    history.goBack();
  }

  return (
    <>
      <Form
        ref={formRef}
        permissions={{
          store: [PROPERTY_PERMISSIONS.PROPERTIES.STORE],
          update: [PROPERTY_PERMISSIONS.PROPERTIES.UPDATE],
          destroy: [PROPERTY_PERMISSIONS.PROPERTIES.DESTROY],
          restore: [PROPERTY_PERMISSIONS.PROPERTIES.RESTORE],
        }}
        resourceName="Identidade"
        onSave={handleSave}
        onDestroy={handleDestroy}
        onRestore={handleRestore}
        onCancel={handleCancel}
        loading={loading || layoutLoading}
        isUpdating={!!params.id}
        isActive={identity?.status === STATUSES.ACTIVE}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      options={IDENTITY_TYPE_OPTIONS}
                      name="type"
                      label="Tipo da Identidade"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AutocompleteCondominiums
                      name="condominium_id"
                      label="Condomínio"
                      // onChange={handleCondominiumChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="name" label="Título" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Descrição"
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <AddressFields
                  formRef={formRef}
                  currentAddress={identity?.address}
                  withComplement
                  withLatLng
                />
              </CardContent>
            </Card>
          </Grid>

          {identity?.type === 'unique' && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <IdentityInfo />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Form>
    </>
  );
};

export default IdentityTab;
