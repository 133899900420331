import { FC } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import MuiTooltip from '@mui/material/Tooltip';
import { StyledIconButton } from 'src/components/IconButton/styles';

import { IconButtonProps } from './interfaces';

const IconButton: FC<IconButtonProps> = ({
  children,
  loading,
  tooltip,
  tooltipProps,
  variant = 'text',
  ...rest
}) => {
  const progressColor =
    !!rest.color && rest.color !== 'default' ? rest.color : 'primary';

  if (tooltip) {
    return (
      <MuiTooltip {...tooltipProps} title={tooltip}>
        <span>
          <StyledIconButton variant={variant} disabled={loading} {...rest}>
            {loading ? (
              <CircularProgress color={progressColor} size={24} />
            ) : (
              children
            )}
          </StyledIconButton>
        </span>
      </MuiTooltip>
    );
  }

  return (
    <StyledIconButton variant={variant} disabled={loading} {...rest}>
      {loading ? (
        <CircularProgress color={progressColor} size={24} />
      ) : (
        children
      )}
    </StyledIconButton>
  );
};

export default IconButton;
