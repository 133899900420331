import React, { forwardRef } from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterIcon from '@mui/icons-material/FilterAlt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Avatar,
  CardActionArea,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { isSameDay, isAfter } from 'date-fns';
import { Opportunity } from 'src/modules/attendance/@types/models';
import OpportunityHasProducts from 'src/modules/attendance/components/OpportunityHasProducts';
import OpportunityUnread from 'src/modules/attendance/components/OpportunityUnread';
import { formatDate, formatIntToMoney } from 'src/utils/helpers';

import { Container, LoadingContainer } from './styles';

type OpportunityCardProps = {
  opportunity: Opportunity;
  messageUnread?: boolean;
  onClick?: (opportunity: Opportunity) => void;
  dragProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  loading?: boolean;
  selected?: boolean;
  disableClick?: boolean;
};

const OpportunityCard: React.ForwardRefRenderFunction<
  HTMLDivElement,
  OpportunityCardProps
> = (
  {
    opportunity,
    messageUnread,
    onClick,
    dragProps,
    dragHandleProps,
    loading,
    selected,
    disableClick,
  },
  ref,
) => {
  function handleClick(opportunity: Opportunity) {
    if (onClick) onClick(opportunity);
  }

  function renderOpportunityWaitingIcon() {
    if (!opportunity.is_waiting || !opportunity.wait_until) return null;

    const currentDate = new Date();
    const waitUntilDate = new Date(opportunity.wait_until);
    const waitIsFinished =
      isSameDay(currentDate, waitUntilDate) ||
      isAfter(currentDate, waitUntilDate);

    return (
      <Grid item>
        <Tooltip title={`Em espera até ${formatDate(opportunity.wait_until)}`}>
          {waitIsFinished ? (
            <PriorityHighIcon fontSize="inherit" color="error" />
          ) : (
            <AccessTimeIcon fontSize="inherit" />
          )}
        </Tooltip>
      </Grid>
    );
  }

  function renderContent() {
    return (
      <Container
        sx={{ p: 1 }}
        opportunity={opportunity}
        selected={selected}
        {...dragProps}
        {...dragHandleProps}
        ref={ref}
      >
        {loading && (
          <LoadingContainer>
            <CircularProgress size={24} />
          </LoadingContainer>
        )}
        <Typography>{opportunity.name}</Typography>
        <Typography fontWeight="bold" variant="subtitle2">
          {opportunity.client?.username}
        </Typography>

        <Grid container columnSpacing={1} marginTop={0.5} alignItems="center">
          {opportunity.attendant && (
            <Grid item>
              <Tooltip title={opportunity.attendant.name}>
                <Avatar
                  alt={opportunity.attendant.name}
                  src={opportunity.attendant.photo}
                  sx={{ width: 24, height: 24 }}
                />
              </Tooltip>
            </Grid>
          )}

          <Grid item xs>
            <Typography
              marginLeft={opportunity.attendant ? 1 : 0}
              variant="subtitle2"
            >
              {formatIntToMoney(opportunity.amount)}
            </Typography>
          </Grid>

          {opportunity.step?.funnel && (
            <Tooltip
              title={`${opportunity.step.funnel.name} - ${opportunity.step.name}`}
            >
              <FilterIcon color="action" fontSize="small" sx={{ m: 0 }} />
            </Tooltip>
          )}

          <OpportunityHasProducts opportunity={opportunity} />

          <OpportunityUnread
            opportunity={opportunity}
            messageUnread={messageUnread}
          />

          {renderOpportunityWaitingIcon()}
        </Grid>
      </Container>
    );
  }

  if (disableClick) {
    return renderContent();
  }

  return (
    <CardActionArea onClick={() => handleClick(opportunity)}>
      {renderContent()}
    </CardActionArea>
  );
};

export default forwardRef(OpportunityCard);
