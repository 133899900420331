import React from 'react';
import { MessageBox } from 'react-chat-elements';

import { useTheme } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { MessageProps } from 'src/pages/Private/Omnichannel/Chat/ChatContent/Message';
import OminichannelUtils from 'src/utils/omnichannel';

const ExternalAdReply: React.FC<MessageProps> = ({ message, onReply }) => {
  const theme = useTheme();

  if (!message.whatsapp?.externalAdReply) return null;

  const reply = {
    title: message.whatsapp?.externalAdReply?.title || 'Anúncio',
    titleColor: message.whatsapp?.fromMe
      ? theme.palette.info.main
      : theme.palette.grey[600],
    message: message.whatsapp?.externalAdReply?.body || '',
    photoURL: message.whatsapp?.externalAdReply?.thumbnailUrl,
    onClick: () => {
      if (message.whatsapp?.externalAdReply?.sourceUrl) {
        window.open(message.whatsapp.externalAdReply.sourceUrl, '_blank');
      }
    },
  };

  return (
    <MessageBox
      id={message.whatsapp?.messageId || Math.random()}
      notch
      removeButton={false}
      reply={reply}
      replyButton={!!onReply}
      onReplyClick={onReply}
      retracted={false}
      status={OminichannelUtils.getChatMessageStatus(message)}
      position={message.direction === 'outgoing' ? 'right' : 'left'}
      type="text"
      title={''}
      titleColor=""
      text={message.whatsapp?.text?.message || ''}
      date={new Date(message.sended_at)}
      dateString={formatDistanceToNow(new Date(message.sended_at), {
        addSuffix: true,
        includeSeconds: true,
      })}
      focus={false}
      forwarded={false}
    />
  );
};

export default ExternalAdReply;
