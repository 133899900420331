import { Opportunity } from 'src/modules/attendance/@types/models';
import _MuiTheme from 'src/styles/themes/default/_MuiTheme';

export function opportunityStatusTextColor(opportunity: Opportunity) {
  if (opportunity.is_waiting) return _MuiTheme.palette.warning.main;

  switch (opportunity.status) {
    case 'open':
      return _MuiTheme.palette.text.primary;
    case 'gain':
      return _MuiTheme.palette.success.main;
    case 'lost':
      return _MuiTheme.palette.error.main;
    case 'transferred':
      return _MuiTheme.palette.text.disabled;
    default:
      return _MuiTheme.palette.text.primary;
  }
}

export function opportunityStatusThemeColor(status: string) {
  switch (status) {
    case 'open':
      return 'default';
    case 'gain':
      return 'success';
    case 'lost':
      return 'error';
    default:
      return 'primary';
  }
}
