import yup from 'src/libs/yup';

export const ClientStoreSchema = yup.object().shape({
  lead_id: yup.string().label('Lead'),
  username: yup.string().label('Usuário'),
  email: yup.string().email().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  type: yup.string().oneOf(['L', 'F', 'J']).required().label('Tipo'),
  person: yup.object().when('type', {
    is: 'F',
    then: yup
      .object()
      .shape({
        name: yup.string().required().label('Nome'),
        register_code: yup.string().required().label('RG'),
        document: yup.string().cpf().required().label('CPF'),
        birth_date: yup
          .string()
          .dateFormat('dd/MM/yyyy')
          .required()
          .label('Nascimento'),
        civil_status_type_id: yup.string().required().label('Estado Civil'),
        employer_id: yup.string().label('Empresa'),
        profession: yup.string().label('Profissão'),
        nacionality: yup.string().label('Nacionalidade'),
      })
      .required(),
    otherwise: yup.object().shape({}).optional(),
  }),
  enterprise: yup.object().when('type', {
    is: 'J',
    then: yup
      .object()
      .shape({
        company_name: yup.string().required().label('Razão'),
        fantasy_name: yup.string().label('Fantasia'),
        document: yup.string().cnpj().required().label('CNPJ'),
        state_registry: yup.string().label('Registro Estadual'),
        municipal_registry: yup.string().label('Registro Municipal'),
        contact: yup.string().label('Contato'),
      })
      .required(),
    otherwise: yup.object().shape({}).optional(),
  }),
});

export const ClientUpdateSchema = yup.object().shape({
  lead_id: yup.string().label('Lead'),
  username: yup.string().label('Usuário'),
  email: yup.string().email().label('E-mail'),
  phone: yup.string().required().label('Telefone'),
  type: yup.string().oneOf(['L', 'F', 'J']).required().label('Tipo'),
  person: yup.object().when('type', {
    is: 'F',
    then: yup
      .object()
      .shape({
        name: yup.string().required().label('Nome'),
        register_code: yup.string().required().label('RG'),
        document: yup.string().cpf().required().label('CPF'),
        birth_date: yup
          .string()
          .dateFormat('dd/MM/yyyy')
          .required()
          .label('Nascimento'),
        civil_status_type_id: yup.string().required().label('Estado Civil'),
        employer_id: yup.string().label('Empresa'),
        profession: yup.string().label('Profissão'),
        nacionality: yup.string().label('Nacionalidade'),
      })
      .required(),
    otherwise: yup.object().shape({}).optional(),
  }),
  enterprise: yup.object().when('type', {
    is: 'J',
    then: yup
      .object()
      .shape({
        company_name: yup.string().required().label('Razão'),
        fantasy_name: yup.string().label('Fantasia'),
        document: yup.string().cnpj().required().label('CNPJ'),
        state_registry: yup.string().label('Registro Estadual'),
        municipal_registry: yup.string().label('Registro Municipal'),
        contact: yup.string().label('Contato'),
      })
      .required(),
    otherwise: yup.object().shape({}).optional(),
  }),
});
