import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import Can from 'src/components/Can';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import IAddressComplement from 'src/interfaces/models/IAddressComplement';
import IPropertyType from 'src/interfaces/models/IPropertyType';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  ContactTypeStoreSchema,
  ContactTypeUpdateSchema,
} from 'src/validators/PropertyType/save.schema';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [complements, setComplements] = useState<IAddressComplement[]>([]);
  const [status, setStatus] = useState<string>('');
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? ContactTypeUpdateSchema
    : ContactTypeStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const complementsResp = await api.get<IAddressComplement[]>(
          'admin/address-complements',
          {
            params: {
              status: 'active',
            },
          },
        );
        setComplements(complementsResp.data);

        if (params.id) {
          const response = await api.get<IPropertyType>(
            `admin/property-types/${params.id}`,
          );
          const { complements, ...type } = response.data;

          setStatus(type.status);

          formRef.current?.setData({
            ...type,
            complements: complements?.map((complement) => complement.id),
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/property-types/${params.id}`, data);
      } else {
        await api.post('/admin/property-types', data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        await api.delete(`/admin/property-types/${params.id}`);
        toast.success('Tipo desativado!');
      } else {
        await api.post(`/admin/property-types/${params.id}/restore`);
        toast.success('Tipo restaurado!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar tipo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField name="name" label="Nome" required />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="description"
                    label="Descrição"
                    multiline
                    rows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    name="complements"
                    label="Complementos"
                    multiple
                    options={complements.map((complement) => ({
                      key: complement.id,
                      label: complement.name,
                      value: complement.id,
                    }))}
                    disableCloseOnSelect
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Can permissions={['destroy-property-types']}>
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="error"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}
            </Can>

            <Button
              type="submit"
              loading={loading}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} tipo`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'error' : 'primary'}
        onConfirm={handleConfirm}
      />
    </Form>
  );
};

export default Save;
