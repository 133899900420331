import yup from 'src/libs/yup';

const IdentityFilterSchema = yup.object().shape({
  name: yup.string().label('Nome'),
  type: yup.string().nullable().label('Tipo'),
  search_address: yup.string().label('Endereço'),
  condominiums: yup.array().of(yup.number()).label('Condomínios'),
  status: yup.string().nullable().label('Status'),
});

export default IdentityFilterSchema;
