import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconArrowForward from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Card, CardContent, Grid } from '@mui/material';
import { Scope } from '@unform/core';
import SearchClientDialog from 'src/components/_functions/SearchClientDialog';
import { SearchClientDialogRef } from 'src/components/_functions/SearchClientDialog/interfaces';
import Button from 'src/components/Button';
import CheckBox from 'src/components/Form/Checkbox';
import DatePicker from 'src/components/Form/DatePicker';
import TextField from 'src/components/Form/TextField';
import IconButton from 'src/components/IconButton';
import { IClient } from 'src/interfaces/models/IClient';
import { IdentityStoreStepInfoSchema } from 'src/modules/property/validators/Identity/save.schema';
import yupValidate from 'src/utils/yupValidate';

import { StoreIdentityContext } from './context';

type IdentityInfoStepProps = {
  show?: boolean;
};

const IdentityInfoStep: React.FC<IdentityInfoStepProps> = ({ show }) => {
  const { formRef, handleBack, handleNext, selectedCondominium } = useContext(
    StoreIdentityContext,
  );
  const [owners, setOwners] = useState<IClient[]>([]);
  const searchClientRef = useRef<SearchClientDialogRef>(null);

  function handleSelectOwner(client: IClient) {
    if (owners.find((owner) => owner.id === client.id)) {
      return toast.warn('Cliente já definido como proprietário');
    }

    setOwners((state) => [...state, client]);
    toast.success('Proprietário adicionado.');
  }

  function handleDeleteOwner(client: IClient) {
    setOwners((state) => state.filter((s) => s.id !== client.id));
  }

  async function handleSubmit() {
    const formData = formRef?.current?.getData();
    const { success, data, errors } = await yupValidate(
      IdentityStoreStepInfoSchema,
      formData,
    );

    if (!success) {
      formRef?.current?.setErrors(errors);
      return toast.error('Verifique os dados informados.');
    }

    if (!data.owners?.length) {
      return toast.error('Informe ao menos 1 proprietário.');
    }

    const ownersPercent = data.owners.reduce(
      (sum, owner) => sum + Number(owner.percentage),
      0,
    );

    if (ownersPercent !== 10000) {
      return toast.error(
        'As participações dos proprietários precisam somar 100%.',
      );
    }

    handleNext();
  }

  useEffect(() => {
    if (!show) return;
    if (!selectedCondominium) {
      formRef?.current?.setFieldValue('info.condominium_amount', '');
      return;
    }

    const condominiumAmount = formRef?.current?.getFieldValue(
      'info.condominium_amount',
    );
    if (condominiumAmount) return;

    setTimeout(() => {
      formRef?.current?.setFieldValue(
        'info.condominium_amount',
        selectedCondominium.amount,
      );
    });
  }, [show, formRef, selectedCondominium]);

  return (
    <Grid container spacing={1} display={!show ? 'none' : undefined}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  startIcon={<SearchIcon />}
                  variant="outlined"
                  color="success"
                  onClick={searchClientRef.current?.show}
                >
                  Proprietário
                </Button>
              </Grid>

              {owners.map((owner, index) => (
                <Grid item xs={12} key={owner.id}>
                  <Grid container spacing={1} alignItems="center">
                    <Scope path={`owners[${index}]`}>
                      <TextField hidden name="owner_id" value={owner.id} />

                      <Grid item xs={12} sm={4} md={2}>
                        <TextField
                          name="percentage"
                          label="Participação (%)"
                          mask="percent"
                          returnUnmasked
                        />
                      </Grid>
                    </Scope>

                    <Grid item xs={12} sm>
                      <IconButton
                        onClick={() => handleDeleteOwner(owner)}
                        tooltip="Remover"
                        color="error"
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                      {owner.username}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Scope path="info">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField name="iptu_code" label="Código do IPTU" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="iptu_parcel_amount"
                    label="Valor do IPTU"
                    mask="currency"
                    returnUnmasked
                  />
                </Grid>

                <Grid item alignSelf="center" xs={12} md={4}>
                  <CheckBox
                    name="iptu_managed"
                    label="IPTU Gerenciado pela Rossi"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField name="registration_code" label="Nº da Matrícula" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField name="energy_code" label="Código de Energia" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField name="water_code" label="Código de Água" />
                </Grid>

                <Grid item alignSelf="center" xs={12} md={4}>
                  <CheckBox name="has_deed" label="Possui Escritura" />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <DatePicker
                    name="occupation_license_at"
                    label="Data do Habite-se"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    name="condominium_amount"
                    label="Valor do Condomínio"
                    mask="currency"
                    returnUnmasked
                    disabled={!selectedCondominium}
                  />
                </Grid>
              </Grid>
            </Scope>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="end">
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              startIcon={<ArrowBackIcon />}
              onClick={handleBack}
            >
              Voltar
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="info"
              endIcon={<IconArrowForward />}
              onClick={handleSubmit}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <SearchClientDialog
        ref={searchClientRef}
        onSelectClient={handleSelectOwner}
        defaultQueryParams={{ types: ['F', 'J', 'L'] }}
      />
    </Grid>
  );
};

export default IdentityInfoStep;
