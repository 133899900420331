import * as Condominiums from './Condominiums';
import * as DetailsCondominiums from './DetailsCondominiums';
import * as DetailsProperties from './DetailsProperties';
import * as EconomicIndicators from './EconomicIndicators';
import * as Identities from './Identities';
import * as OfferBroughtVias from './OfferBroughtVias';
import * as Offers from './Offers';
import * as PublicitiesTypes from './PublicitiesTypes';
import * as Realtors from './Realtors';

export default {
  Condominiums,
  DetailsCondominiums,
  DetailsProperties,
  PublicitiesTypes,
  Identities,
  Realtors,
  OfferBroughtVias,
  EconomicIndicators,
  Offers,
};
