import yup from 'src/libs/yup';

export const ContactTypeStoreSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  complements: yup.array().of(yup.number()).label('Complementos'),
});

export const ContactTypeUpdateSchema = yup.object().shape({
  name: yup.string().required().label('Nome'),
  description: yup.string().label('Descrição'),
  complements: yup.array().of(yup.number()).label('Complementos'),
});
