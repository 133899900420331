import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { FilterOptionsState } from '@mui/base';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import {
  AutocompleteProps,
  IOption,
} from 'src/components/Form/Autocomplete/interfaces';
import { queryClient } from 'src/config/query';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import { INeighborhood } from 'src/interfaces/models/IAddress';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors } from 'src/utils/errors';
import { searchString } from 'src/utils/helpers';

type AutocompleteNeighborhoodsProps = {
  requestConfig?: AxiosRequestConfig;
  afterCreate?: (neighborhood: INeighborhood) => void;
  formRef?: React.RefObject<FormHandles>;
} & Omit<AutocompleteProps<INeighborhood>, 'options'>;

const AutocompleteNeighborhoods: React.FC<AutocompleteNeighborhoodsProps> = ({
  requestConfig,
  afterCreate,
  formRef,
  ...rest
}) => {
  const [creating, isCreating] = useState(false);
  const {
    isLoading,
    data: response,
    refetch,
  } = apiAdminPublic.useNeighborhoodsQuery({
    requestConfig,
  });

  const handleFilter = (
    options: IOption<INeighborhood>[],
    state: FilterOptionsState<IOption<INeighborhood>>,
  ) => {
    if (!state.inputValue) return options;

    return options.filter((option) => {
      if (searchString(state.inputValue, option.label)) return true;
      if (
        option.data?.bonds?.some((bond) =>
          searchString(state.inputValue, bond.name),
        )
      )
        return true;

      return false;
    });
  };

  const handleCreateNeighborhood = useCallback(
    async (value: string) => {
      try {
        isCreating(true);

        const { data } = await api.post<INeighborhood>(`/admin/neighborhoods`, {
          name: value,
        });

        await queryClient.invalidateQueries([COMMON_QUERY_KEYS.NEIGHBORHOODS]);

        if (afterCreate) afterCreate(data);

        await refetch();

        if (formRef) {
          setTimeout(() => {
            formRef.current?.setFieldValue(rest.name, data.id);
          });
        }

        toast.success('Bairro Cadastrado!');
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao cadastrar bairro.');
      } finally {
        isCreating(false);
      }
    },
    [afterCreate, refetch, formRef, rest.name],
  );

  const options =
    response?.data?.map((neighborhood) => ({
      key: neighborhood.id,
      label: neighborhood.name,
      value: neighborhood.id,
      data: neighborhood,
    })) || [];

  return (
    <Autocomplete
      creatable
      {...rest}
      loading={isLoading || creating}
      options={options}
      handleCreate={handleCreateNeighborhood}
      filterOptions={handleFilter}
    />
  );
};

export default AutocompleteNeighborhoods;
