import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { FilterOptionsState } from '@mui/base';
import { FormHandles } from '@unform/core';
import { AxiosRequestConfig } from 'axios';
import Autocomplete from 'src/components/Form/Autocomplete';
import {
  AutocompleteProps,
  IOption,
} from 'src/components/Form/Autocomplete/interfaces';
import { queryClient } from 'src/config/query';
import { COMMON_QUERY_KEYS } from 'src/constants/query';
import { IStreet } from 'src/interfaces/models/IAddress';
import api from 'src/services/api';
import apiAdminPublic from 'src/services/api/adminPublic';
import { handleApiResponseErrors } from 'src/utils/errors';
import { searchString } from 'src/utils/helpers';

type AutocompleteStreetsProps = {
  requestConfig?: AxiosRequestConfig;
  afterCreate?: (street: IStreet) => void;
  formRef?: React.RefObject<FormHandles>;
} & Omit<AutocompleteProps<IStreet>, 'options'>;

const AutocompleteStreets: React.FC<AutocompleteStreetsProps> = ({
  requestConfig,
  afterCreate,
  formRef,
  ...rest
}) => {
  const [creating, isCreating] = useState(false);
  const { isLoading, data: response, refetch } = apiAdminPublic.useStreetsQuery(
    {
      requestConfig,
    },
  );

  const handleFilter = (
    options: IOption<IStreet>[],
    state: FilterOptionsState<IOption<IStreet>>,
  ) => {
    if (!state.inputValue) return options;

    return options.filter((option) => {
      if (searchString(state.inputValue, option.label)) return true;
      if (
        option.data?.bonds?.some((bond) =>
          searchString(state.inputValue, bond.name),
        )
      )
        return true;

      return false;
    });
  };

  const handleCreateStreet = useCallback(
    async (value: string) => {
      try {
        isCreating(true);

        const { data } = await api.post<IStreet>(`/admin/streets`, {
          name: value,
        });

        await queryClient.invalidateQueries([COMMON_QUERY_KEYS.STREETS]);

        if (afterCreate) afterCreate(data);

        await refetch();

        if (formRef) {
          setTimeout(() => {
            formRef.current?.setFieldValue(rest.name, data.id);
          });
        }

        toast.success('Rua Cadastrada!');
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao cadastrar rua.');
      } finally {
        isCreating(false);
      }
    },
    [afterCreate, refetch, formRef, rest.name],
  );

  const options =
    response?.data?.map((street) => ({
      key: street.id,
      label: street.name,
      value: street.id,
      data: street,
    })) || [];

  return (
    <Autocomplete
      creatable
      {...rest}
      loading={isLoading || creating}
      options={options}
      filterOptions={handleFilter}
      handleCreate={handleCreateStreet}
    />
  );
};

export default AutocompleteStreets;
