import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { GridColDef } from '@mui/x-data-grid';
import Can from 'src/components/Can';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import useApiQueryPagination from 'src/hooks/useApiQueryPagination';
import { IEditParams } from 'src/interfaces/IEditParams';
import { CondominiumDetail } from 'src/modules/property/@types/models';
import { PROPERTY_PERMISSIONS } from 'src/modules/property/constants/permissions';
import propertyApi from 'src/modules/property/services/api';
import CondominiumDetailFilterSchema from 'src/modules/property/validators/CondominiumDetail/filter.schema';

import CondominiumDetailFilters from './CondominiumDetailFilters';

const List: FC = () => {
  const { url } = useRouteMatch();
  const params = useParams<IEditParams>();
  const {
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    data: response,
    refetch,
  } = useApiQueryPagination<CondominiumDetail>(
    (queryConfig) =>
      propertyApi.admin.condominiums.details.useCondominiumDetailsQuery(
        params.id,
        queryConfig,
      ),
    {
      preload: ['detailCondominium'],
    },
  );

  const columns: GridColDef<CondominiumDetail>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'detailCondominium.name',
      headerName: 'Nome',
      flex: 1,
      valueGetter({ row }) {
        return row.detailCondominium?.name;
      },
    },
    {
      field: 'value',
      headerName: 'Valor',
      flex: 1,
      valueGetter({ value, row }) {
        if (row.detailCondominium?.input_type === 'boolean') {
          return value;
        } else {
          return value;
        }
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      renderCell({ row }) {
        return (
          <TableActions
            resourceName="Detalhe"
            resourceId={row.id}
            resourceStatus={row.status}
            editLink={`${url}/${row.id}`}
            editPermissions={[PROPERTY_PERMISSIONS.CONDOMINIUMS.UPDATE]}
            canDelete
            destroyApiEndpoint={(resourceId, config) =>
              propertyApi.admin.condominiums.details.destroyCondominiumDetail(
                row.condominium_id,
                resourceId,
                config,
              )
            }
            destroyPermissions={[PROPERTY_PERMISSIONS.CONDOMINIUMS.DESTROY]}
            restoreApiEndpoint={(resourceId, config) =>
              propertyApi.admin.condominiums.details.restoreCondominiumDetail(
                row.condominium_id,
                resourceId,
                config,
              )
            }
            restorePermissions={[PROPERTY_PERMISSIONS.CONDOMINIUMS.RESTORE]}
            descriptionPrefix={`O Detalhe ${row.detailCondominium?.name}`}
            onFinish={refetch}
          />
        );
      },
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="end" alignItems="center">
          <Can
            behavior="disable"
            permissions={[PROPERTY_PERMISSIONS.CONDOMINIUMS.STORE]}
          >
            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Novo
            </LinkButton>
          </Can>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Table
              autoHeight
              rows={response?.data.data || []}
              pageSize={response?.data.meta.per_page}
              rowCount={response?.data.meta.total}
              columns={columns}
              loading={isLoading}
              pagination
              paginationMode="server"
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              canSearch
              showFilters
              filterProps={{
                yupSchema: CondominiumDetailFilterSchema,
                children: <CondominiumDetailFilters />,
              }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default List;
